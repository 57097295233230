import React from "react";
import authRoles from "app/auth/auth_roles";

const otherConfig = {
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/(dieu-khoan-su-dung|terms-of-use)",
            exact: true,
            component: React.lazy(() => import("./Terms")),
        },
        {
            path: "/(chinh-sach-bao-mat|privacy-policy)",
            exact: true,
            component: React.lazy(() => import("./Policy")),
        },
    ],
};

export default otherConfig;