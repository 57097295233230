const locale = {
    // Navbar
    Weather: "Thời tiết & khí hậu",
    Products: "Sản phẩm",
    Solutions: "Giải pháp",
    "Solutions of WeatherPlus": "Giải pháp WeatherPlus",
    "Solutions for businesses": "Giải pháp thời tiết cho doanh nghiệp",
    "Agricultural solutions": "Giải pháp nông nghiệp",
    "Hydropower solutions": "Giải pháp thủy điện",
    "About us": "Giới thiệu về WeatherPlus",
    "About us2": "Giới thiệu doanh nghiệp",
    Experts: "Đội ngũ chuyên gia",
    Partners: "Đối tác và khách hàng",
    "Vision and mission": "Tầm nhìn và sứ mệnh",
    "Core values": "Giá trị cốt lõi",
    "Development stages": "Giai đoạn phát triển",
    Leadership: "Ban lãnh đạo",
    "Weather WP": "Tin tức WeatherPlus",
    "Weather news": "Tin thời tiết",
    "Company news": "Tin doanh nghiệp",
    "Industry news": "Tin tức ngành",
    "WeatherPlus's life": "Nhịp sống WeatherPlus",
    "WP's life": "Nhịp sống WP",
    "Agriculture news": "Tin nông nghiệp",
    "Hydropower news": "Tin thuỷ điện",
    "Featured News": "Tin tức nổi bật",
    "View All News": "Xem tất cả bài viết",
    "Error 404": "Trang không tồn tại",
    "Page not found": "Đường dẫn bạn truy cập không tồn tại hoặc đã bị xóa.",
    "Company name": "Công ty Cổ phần giải pháp thời tiết WeatherPlus",
    "Business registration": "Mã số doanh nghiệp: 0106597121 do Sở Kế hoạch và Đầu tư ",
    "Confirmed by": "Thành phố Hà Nội cấp ngày 09/07/2024",
    "Address company1": "Tầng 18, Tháp A, Tòa nhà Sông Đà, Phạm Hùng,",
    "Address company2": "Nam Từ Liêm, Hà Nội",
    "Address company3": "Tầng 18, Tháp A, Tòa nhà Sông Đà, Phạm",
    "Address company4": "Hùng, Nam Từ Liêm, Hà Nội",
    "Address company5": "",
    "Products for businesses": "Sản phẩm thời tiết cho doanh nghiệp",
    "Products for agriculture": "Sản phẩm Nông nghiệp",
    "Products for hydropower": "Sản phẩm Thủy điện",
    "Terms of Use": "Điều khoản sử dụng",
    "Privacy Policy": "Chính sách bảo mật",
    "Privacy Policy content": "WeatherPlus thực hiện các chính sách bảo mật sau đây nhằm chỉ ra các vấn đề liên quan đến bảo mật thông tin của khách hàng đối với trang web này. Theo quy định chung, không có thông tin cá nhân nào của khách hàng được tự động thu thập từ trang web. Tuy nhiên một số thông tin phi cá nhân của người truy cập liên quan đến lưu lượng truy cập sẽ được ghi lại bởi các máy chủ đạt tiêu chuẩn vận hành của WeatherPlus. Những thông tin này được sử dụng cho mục đích nâng cấp, cải thiện trải nghiệm người dùng trang web của chúng tôi. WeatherPlus có thể chia sẻ thông tin cá nhân của bạn với bên thứ ba chỉ để phục vụ cung cấp dịch vụ cho bạn hoặc những thông tin bổ sung về WeatherPlus mà bạn yêu cầu (ví dụ gửi e-mail, điền các thông tin cần thiết và tiến hành trả lời khảo sát). Bất kỳ trang web thứ ba nào được dẫn link trong website này đều có thể có chính sách và điều khoản bảo mật riêng. Bạn nên xem xét kỹ lưỡng chính sách bảo mật của những trang này nhằm lựa chọn thông tin cá nhân phù hợp đối với từng trang web khác nhau. ",
    "Terms of Use subtitle1": "1. Các điều kiện chung",
    "Terms of Use content1": "Chúng tôi chỉ cung cấp dịch vụ phù hợp với quy định của pháp luật, chúng tôi không cung cấp những dịch vụ bị cấm, không được pháp luật luật Việt Nam cho phép kinh doanh.#Khi quý khách hàng truy cập vào trang website của chúng tôi có nghĩa là quý khách đồng ý với các điều khoản quy định của chúng tôi.#Khi vào web của chúng tôi, khách hàng phải đảm bảo đủ 14 tuổi, hoặc truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp. Khách hàng đảm bảo có đầy đủ hành vi dân sự để thực hiện các giao dịch mua bán hàng hóa theo quy định hiện hành của pháp luật Việt Nam.#Chúng tôi cung cấp dịch vụ trên phạm vi toàn quốc. Thời gian làm việc từ thứ hai đến thứ sáu (8:30 AM – 5:30 PM), không làm việc thứ bảy, chủ nhật và các ngày lễ.",
    "Terms of Use subtitle2": "2. Bản quyền",
    "Terms of Use content2": "Công ty cổ phần Giải pháp thời tiết WeatherPlus là công ty chuyên cung cấp các dịch vụ chủ yếu về dự báo thời tiết cho doanh nghiệp phù hợp quy định của pháp luật Việt Nam.#Công ty cổ phần Giải pháp thời tiết WeatherPlus được Bộ Tài nguyên và môi trường cấp Giấy phép hoạt động dự báo, cảnh báo khí tượng thuỷ văn số 01 năm 2017, ngày cấp 03/04/2017; sửa đổi lần thứ nhất số: 14/GP-BTNMT, ngày cấp 21/01/2021.#Chúng tôi được dự báo, cảnh báo thời tiết, thuỷ văn trên toàn quốc cho các tổ chức, cá nhân và cộng đồng.",
    "Terms of Use subtitle3": "3. Chính sách về giá",
    "Terms of Use content3": "Thông tin, chính sách giá cả dịch vụ trên trang web luôn giống với thực tế tại địa chỉ của Công ty cổ phần Giải pháp thời tiết WeatherPlus: Số 15, Ngõ 202, Phố Định Công Hạ, Phường Định Công, Quận Hoàng Mai, Thành phố Hà Nội.",
    "Home header mobile": "TIÊN PHONG PHÁT TRIỂN GIẢI PHÁP TIN CẬY TRÊN NỀN TẢNG THỜI TIẾT",
    "Home header desktop1": "TIÊN PHONG PHÁT TRIỂN GIẢI PHÁP TIN CẬY",
    "Home header desktop2": "TRÊN NỀN TẢNG THỜI TIẾT",
    "Get Consultation": "Nhận tư vấn",
    "ListData Home title mobile": "LÀ DOANH NGHIỆP ĐẦU TIÊN ĐƯỢC CẤP PHÉP CUNG CẤP DỊCH VỤ DỰ BÁO THỜI TIẾT",
    "ListData Home title desktop1": "LÀ DOANH NGHIỆP ĐẦU TIÊN ĐƯỢC",
    "ListData Home title desktop2": "CẤP PHÉP CUNG CẤP DỊCH VỤ DỰ BÁO THỜI TIẾT",
    "years of experience": "năm kinh nghiệm",
    "experts, engineers in meteorology and hydropower": "chuyên gia, kỹ sư trong lĩnh vực Khí tượng thuỷ văn, thủy điện",
    "Top-rated customer service": "Số 1 dịch vụ CSKH",
    "Commitment to reliability and dedicated support": "cam kết tin cậy, tận tâm phục vụ",
    "hydropower clients": "khách hàng thủy điện",
    "partnered businesses": "doanh nghiệp hợp tác",
    "monitoring stations nationwide": "trạm quan trắc trên cả nước",
    "active accounts": "tài khoản sử dụng",
    "weather data points daily": "dữ liệu thời tiết mỗi ngày",
    "millions": "triệu",
    "weather data points daily, updated in real-time": "dữ liệu thời tiết hàng ngày, cập nhật theo thời gian thực",
    "Over 90% accuracy and reliability": "Độ chính xác tin cậy, lên tới hơn 90%",
    "Over 90%": "Trên 90%",
    "accuracy and reliability": "độ chính xác, tin cậy của dữ liệu",
    "Dedicated customer service": "Dịch vụ CSKH tận tâm",
    "Experienced experts": "Chuyên gia giàu kinh nghiệm",
    "in meteorological and hydrological forecasting": "trong dự báo khí tượng thủy văn",
    "Home helmet": "Dịch vụ dự báo thời tiết tin cậy",
    "Vision and mission of WeatherPlus": "Tầm nhìn và sứ mệnh của WeatherPlus",
    "of WeatherPlus": "của WeatherPlus",
    "Vision": "Tầm nhìn",
    "Mission": "Sứ mệnh",
    "Corporate philosophy": "Triết lý doanh nghiệp",
    "contentChoice1": "Liên tục đổi mới, chúng tôi cam kết cung cấp sản phẩm và dịch vụ tin cậy, giúp khách hàng tối ưu hóa kinh tế, thúc đẩy chuyển đổi xanh, và đóng góp vào phát triển bền vững. WeatherPlus hướng tới trở thành tổ chức thịnh vượng, nơi mọi người cùng nhau tạo ra giá trị thực, được đền đáp xứng đáng và làm việc trong môi trường hạnh phúc.",
    "contentChoice2": "Bằng sự tận tâm và đổi mới, chúng tôi cung cấp giải pháp bền vững, tối ưu hiệu quả cho khách hàng, đồng thời tạo ra môi trường làm việc tích cực, nơi mỗi thành viên phát triển và đóng góp vào thành công chung.",
    "contentChoice3": "Chúng tôi tin rằng nền tảng của thành công là con người với Đạo đức, Trí tuệ, và Nghị lực. Với ba giá trị này, chúng tôi xây dựng một tổ chức hạnh phúc, hiệu quả, và có ý nghĩa.",
    "confirmprefix": "WeatherPlus khẳng định vị trí tiên phong",
    "confirmsubfix": "trong nghiên cứu và phát triển các sản phẩm và dịch vụ tin cậy dựa trên nền tảng thời tiết.",
    "homecontext1": "Cung cấp bản tin thời tiết tin cậy cho rất nhiều lĩnh vực: Hàng không, dầu khí, du lịch, khai thác than & khoáng sản,… giúp doanh nghiệp tối ưu hoạt động sản xuất, kinh doanh và giảm thiểu những rủi ro có thể xảy ra do thời tiết và biến đổi khí hậu.",
    "homecontext2": "Xây dựng các giải pháp nhận diện sâu bệnh cây trồng, dự báo thời tiết nông vụ ứng dụng công nghệ AI và Big Data để hỗ trợ người nông dân trong quá trình canh tác, trồng trọt hiệu quả và bền vững.",
    "homecontext3": "Cung cấp các sản phẩm, dịch vụ hỗ trợ cho công tác vận hành hồ đập, nhà máy thủy điện giúp tối ưu công suất phát điện, giúp nhà quản trị nắm bắt các hình thái diễn biến thời tiết để xây dựng kịch bản ứng phó với các tình hình mưa lũ xấu.",
    "learnmore": "Tìm hiểu thêm",
    "seemore": "Xem thêm",
    "close": "Đóng",
    "reliable commitment": "Cam kết tin cậy",
    "dedicated to every service": "Tận tâm phục vụ",
    "joinus": "THAM GIA CÙNG CHÚNG TÔI",
    "joinuscontent1": "WeatherPlus luôn tự hào mang đến môi trường làm việc chuyên nghiệp, thân thiện cùng đội ngũ cộng sự chất lượng để từng thành viên có không gian và cơ hội phát triển lành mạnh.",
    "joinuscontent2": "Hãy tìm hiểu thêm về các ngành nghề và cơ hội việc làm tại WeatherPlus.",
    "learnmore2": "Xem thêm",
    "agriheader": "GIẢI PHÁP CHO NÔNG NGHIỆP",
    "weatherheader": "THỜI TIẾT CHO DOANH NGHIỆP",
    "introweathertitle1": "Phát triển các giải pháp thời tiết chuyên sâu",
    "introweathertitle2": "dành cho doanh nghiệp",
    "introweathercontent1": "Là công ty phát triển các giải pháp thời tiết chuyên sâu dành cho doanh nghiệp, WeatherPlus tập trung vào tính cụ thể của từng ngành / nghề kinh doanh, cung cấp bản tin dự báo thời tiết tin cậy trong các ngành: hàng không, dầu khí, du lịch, khai thác than và khoáng sản...giúp doanh nghiệp tối ưu hóa hoạt động sản xuất, kinh doanh và giảm thiểu rủi ro do thời tiết và biến đổi khí hậu.",
    "introweathersubcontent1": "WeatherPlus sở hữu đội ngũ chuyên gia khí tượng hàng đầu, tận tâm, giàu kinh nghiệm.",
    "introweathersubcontent2": "Ứng dụng công nghệ hiện đại trong theo dõi và phân tích diễn biến thời tiết.",
    "introweathersubcontent3": "Luôn sẵn sàng đồng hành và hỗ trợ khách hàng 24/7.",
    "solutiontitle": "Dịch vụ dự báo thời tiết cho doanh nghiệp",
    "solutioncontent": "Giúp doanh nghiệp tối ưu hoạt động sản xuất, kinh doanh và giảm thiểu những rủi ro gây ra bởi thời tiết và biến đổi khí hậu",
    "Aviation": "Hàng không",
    "Oil & gas extraction": "Khai thác dầu khí",
    "Coal and mineral mining": "Khai thác than và khoáng sản",
    "Offshore Wind Power": "Điện gió ngoài khơi",
    "APPAREL AND FASHION": "May mặc và thời trang",
    "Tourism and events": "Du lịch và sự kiện",
    "Climate forecasting": "Dự báo khí hậu",
    "Weather climate APIs": "API thời tiết và khí hậu",
    "downloadreport": "Tải báo cáo",
    "Why do clients trust": "Vì sao khách hàng tin tưởng",
    "WeatherPlus's weather": "giải pháp thời tiết",
    "solutions for businesses?": "cho doanh nghiệp của WeatherPlus?",
    "Why clients trust": "Vì sao khách hàng tin tưởng",
    "WeatherPlus'1": "giải pháp thủy điện",
    "WeatherPlus'2": "giải pháp nông nghiệp",
    "hydropower solutions": "của WeatherPlus?",
    "agriculture solutions": "của WeatherPlus?",
    "introagrititle1": "Hệ sinh thái nông nghiệp số",
    "introagrititle2": "cho nhà nông",
    "introagricontent1": "\"Chuyển đổi số nông nghiệp\"",
    "introagricontent2": "được đánh giá là phương thuốc hiệu quả cho nền nông nghiệp Việt Nam khi nông nghiệp nước ta đang phải chịu ảnh hưởng nặng nề của biến đổi khí hậu; sản xuất thiên hướng nhỏ lẻ, kém hiệu quả; lạm dụng phân bón hóa học và thuốc BVTV dẫn đến thoái hóa đất.",
    "introagricontent3": "Thấu hiểu nỗi đau của ngành nông nghiệp hiện nay, WeatherPlus đã phát triển hệ sinh thái giải pháp, sản phẩm, dịch vụ nông nghiệp ứng dụng công nghệ Trí tuệ nhân tạo (AI) và Dữ liệu lớn (Big Data) để hỗ trợ người nông dân trong canh tác và gia tăng lợi ích kinh tế từ trồng trọt.",
    "agriplatformtitle": "Giải pháp nông nghiệp số",
    "agriplatformcontent1": "Hệ sinh thái nông nghiệp của WeatherPlus đang hợp tác với 2 nhà mạng di động lớn nhất Việt Nam",
    "agriplatformcontent2": "là MobiFone và Viettel để cung cấp giải pháp nông nghiệp số là mobiAgri và VietPlant",
    "downloadapp": "Tải ứng dụng",
    "othersolution": "Các giải pháp khác hỗ trợ chuyển đổi số ngành nông nghiệp",
    "argilistdata1": "Nhận diện",
    "argilistdata2": "5.000+ cây trồng, 700+ sâu bệnh hại của 35 cây trồng phổ biến",
    "argilistdata3": "Mạng xã hội chuyên biệt",
    "argilistdata4": "kết nối chuyên gia với nông dân, nông dân với nông dân, người mua và người bán",
    "argilistdata5": "Chuyên gia nông nghiệp",
    "argilistdata6": "dày dặn kinh nghiệm sẵn sàng tư vấn người nông dân trực tuyến",
    "hydroheader": "GIẢI PHÁP HỖ TRỢ VẬN HÀNH THỦY ĐIỆN SEHO",
    "introhydrotitle": "Hệ sinh thái toàn diện và đầy đủ nhất trên thị trường",
    "introhydrocontent1": "Hệ sinh thái SEHO",
    "introhydrocontent2": "được thiết kế nhằm cung cấp thông tin dự báo thời tiết sớm cho các hồ đập, thủy điện về lượng mưa, lưu lượng và các công cụ hỗ trợ, giúp thủy điện chủ động trong việc vận hành, gia tăng lợi ích kinh tế và giảm thiểu rủi ro lũ lụt tại hạ lưu.",
    "introhydrocontent3": "Các giải pháp hỗ trợ vận hành của SEHO được tích hợp công nghệ",
    "introhydrocontent4": "Trí tuệ nhân tạo (AI) và Dữ liệu lớn (Big Data) cùng tri thức của các chuyên gia khí tượng giàu kinh nghiệm",
    "introhydrocontent5": "Nhật Bản và Việt Nam để cung cấp các mô hình/kịch bản dự báo phù hợp với từng lưu vực, từng hình thái khí hậu đặc trưng nhanh chóng, tin cậy.",
    "hydrosilutiontitle": "GIẢI PHÁP VẬN HÀNH THỦY ĐIỆN SEHO",
    "hydrosilutioncontent": "SEHO là hệ sinh thái toàn diện và đầy đủ nhất trên thị trường, cung cấp các giải pháp, dịch vụ về thời tiết cho thủy điện, hồ đập",
    "aboutWP": "Về WeatherPlus",
    "introaboutcontent1": "Trong suốt một thập kỷ không ngừng đổi mới và phát triển,",
    "introaboutcontent2": "Công ty Cổ phần Giải pháp Thời tiết WeatherPlus (WeatherPlus)",
    "introaboutcontent3": "đã khẳng định vị thế tiên phong trong việc cung cấp các sản phẩm và dịch vụ tin cậy, giúp khách hàng tối ưu hóa hiệu quả kinh tế và thúc đẩy chuyển đổi xanh.",
    "introaboutsubcontent1": "Với nền tảng vững chắc dựa trên ba gốc rễ: Đạo đức, Trí tuệ, và Nghị lực, WeatherPlus đã phát triển một hệ sinh thái các giải pháp công nghệ tiên tiến, trở thành đối tác tin cậy của nhiều tập đoàn lớn trong và ngoài nước.",
    "introaboutsubcontent2": "Chúng tôi cam kết xây dựng một tổ chức thịnh vượng, nơi các thành viên đồng lòng tạo ra giá trị đích thực, đóng góp vào sự phát triển bền vững của cộng đồng, và cùng nhau xây dựng một môi trường làm việc hạnh phúc.",
    "Work Experience": "Quá trình công tác",
    "partnersheader1": "WeatherPlus tự hào cung cấp giải pháp cho",
    "partnersheader2": "500+ khách hàng, đối tác trong nước và quốc tế.",
    "partnersheader3": "WeatherPlus luôn đáp ứng nhu cầu đa dạng của các khách hàng ở tất cả các lĩnh vực.",
    Feedback: "Nhận xét của khách hàng",
    "Contacts": "Tự tin đưa ra quyết định kinh doanh",
    "letmeknow": "Hãy cho tôi biết vấn đề thời tiết của bạn",
    "contactcontent1": "Giúp doanh nghiệp tối ưu hoạt động sản xuất, kinh doanh và giảm thiểu những rủi ro gây ra bởi thời tiết và biến đổi khí hậu.",
    "contactcontent2": "Mọi thắc mắc xin gửi về",
    "contactcontent3": "Liên hệ ngay đội ngũ Weatherplus",
    "contactcontent4": "Liên hệ ngay đội ngũ Weatherplus để nhận hỗ trợ",
    "contactcontent5": "Chúng tôi sẽ liên hệ trong thời gian sớm nhất.",
    "contactcontent6": "Trân trọng cảm ơn!",
    "contactcontent7": "Cảm ơn bạn đã gửi thông tin đến WeatherPlus.",
    "contactcontent8": "Xin hãy điền đầy đủ những thông tin dưới đây và gửi cho chúng tôi.",
    "send": "Gửi",
    "errorValidMail": "Vui lòng nhập địa chỉ email hợp lệ",
    "errorNotMail": "Vui lòng nhập địa chỉ email",
    "errorNotName": "Vui lòng nhập họ và tên",
    "errorValidPhone": "Vui lòng nhập số điện thoại hợp lệ",
    "errorNotPhone": "Vui lòng nhập số điện thoại",
    "errorNotPage": "Vui lòng chọn lĩnh vực quan tâm",
    "name": "Họ và tên",
    "email": "Email",
    "phone": "Số điện thoại",
    "content": "Vấn đề thời tiết bạn đang gặp phải",
    "content1": "Công ty",
    "content2": "Lĩnh vực quan tâm *",
    "content3": "Lĩnh vực: ",
    "sending": "Đang gửi...",
    "commitment1": "CAM KẾT TIN CẬY - TẬN TÂM PHỤC VỤ",
    "recuitmentheader": "Tuyển dụng WeatherPlus",
    "recuitmentcontent": "xây dựng môi trường làm việc cởi mở, khuyến khích trao đổi ý tưởng mọi cấp, cho phép bạn làm việc sáng tạo để phát triển năng lực bản thân. Tại đây, bạn không chỉ được nâng cao kỹ năng chuyên môn, nghiệp vụ bởi sự hướng dẫn tận tình từ đồng nghiệp và cấp quản lý, mà còn nâng cấp các kỹ năng mềm khi tham gia các CLB thú vị và chương trình đào tạo định kỳ hàng tuần.",
    "newsWP": "Tin tức WeatherPlus",
    "wpPro1": "Chuyên gia dự báo và",
    "wpPro2": "tư vấn thời tiết doanh nghiệp",
    "forecasting1": "Bản tin dự báo khí hậu",
    "forecasting2": "Hạn dự báo 30 ngày",
    "forecasting3": "Hạn dự báo 45 ngày",
    "forecasting4": "Hạn dự báo 3 tháng",
    "forecasting5": "Hạn dự báo 6 tháng",
    "forecasting6": "*Phạm vi toàn quốc",
    "forecasting7": "Nhận miễn phí",
    About: "Về WeatherPlus",
    Contact: "Liên hệ",
    Home: "Trang chủ",
    "Weather forecasts": "Dự báo khí hậu",
    Weather4Business: "Business+",
    WeatherCareer: "Giải pháp",
    "Vision - Mission": "Tầm nhìn - Sứ mệnh",
    Milestones: "Lịch sử hình thành",
    Awards: "Giải thưởng",

    Projects: "Dự án",
    Agriculture: "Nông nghiệp",
    Hydroelectric: "Thuỷ điện",

    News: "Tin tức",
    NewsWeather: "Tin tức",
    "Press releases": "Báo chí viết về chúng tôi",
    "Life at WeatherPlus": "Life@WeatherPlus",
    "Cooperation information": "Hợp tác phát triển",
    Technology: "Công nghệ và Giải pháp",

    Recruitment: "Tuyển dụng",
    "Job opportunities": "Tin tuyển dụng",
    "Employment policies": "Chính sách tuyển dụng",
    "Recruitment process": "Quy trình tuyển dụng",

    "Happy rain": "HAPPY RAIN",

    // Language
    English: "EN",
    Vietnamese: "VN",
    "Select Language": "NN",
    Cancel: "TT",

    // Location dialog
    Agree: "Đồng ý",
    "Share your location?": "Chia sẻ địa điểm?",
    "Agree to see the weather in your location":
        "Chọn Đồng Ý để xem ngay thời tiết tại vị trí của bạn",

    // Footer
    Introduction:
        "WeatherPlus là doanh nghiệp tiên phong trong lĩnh vực dự báo thời tiết tại Việt Nam. Sứ mệnh của chúng tôi là giúp các cá nhân và doanh nghiệp quản lý được các thách thức liên quan đến thời tiết bằng các thông tin dự báo, dữ liệu thời tiết đủ tin cậy để có thể hành động.",
    Hotline: "Hotline",
    "Contact Information": "Thông tin liên hệ",
    "Contact us for consultations on weather solutions":
        "Liên hệ ngay với chúng tôi để được tư vấn về các giải pháp thời tiết.",
    Address: "Tầng 18, Tháp A, Tòa nhà Sông Đà, Phạm Hùng, Nam Từ Liêm, Hà Nội",
    "View map": "Xem bản đồ",
};

export default locale;
