import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {resetUserFormInput, submitUserContactForm} from "../../app/pages/home/store/actions";
import Bg from "assets/img/weather_update/forecast/wp-icon.png";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Logo from "../../assets/logo/logo_vn.png";
import {Menu, MenuItem, useMediaQuery} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {FormHelperText} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        color: "#503B8F",
        border: "1px solid #503B8F",
        borderRadius: 56,
        background: "white",
        fontFamily: "Nunito",
        fontStyle: "normal",
        "&:hover": {
            background: "#503B8F",
            color: "white",
            border: "1px solid #ffffff",
        },
        [theme.breakpoints.down("sm")]: {
            background: "#503B8F",
            color: "#ffffff",
        },
    },
    title: {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 32,
        lineHeight: 1.2,
        textAlign: "center",
        color: "#503B8F",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
        },
    },
    containerTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    subContent: {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.2,
        textAlign: "center",
        color: "#363541",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    inputRegister: {
        width: "100%",
        border: "none",
        borderRadius: 4,
        "& .MuiInput-underline:before": {
            borderBottomColor: "#503B8F",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#503B8F",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#503B8F",
        },
        "& .MuiInputBase-input": {
            padding: "10px",
            height: 30,
        },
        "& .MuiInputLabel-formControl": {
            zIndex: 1000,
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.2,
            color: "#9391A5",
            paddingLeft: 10,
            [theme.breakpoints.down("sm")]: {
                paddingTop: 1,
            },
        },
        "& .MuiFormLabel-root.Mui-error": {
            color: "#503B8F !important",
        },
        "& label + .MuiInput-formControl": {
            background: "#FFFFFF",
            border: "1px solid #9391A5",
            borderRadius: 4,
        },
        "& .MuiInput-root": {
            border: "1px solid #9391A5",
            borderRadius: 4,
        },
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center",
    },
    btnRegister: {
        background: "#503B8F",
        color: "white",
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        padding: "10px 20px",
        marginBottom: 20,
        marginTop: 40,
        border: "1px solid #503B8F",
        textTransform: "none",
        "&:hover": {
            background: "#503B8F",
            color: "white",
            [theme.breakpoints.down("sm")]: {
                backgroundColor: "white",
                color: "#503B8F",
                border: "1px solid #503B8F",
            },
        },
        "&:focus": {
            color: "white",
            [theme.breakpoints.down("sm")]: {
                backgroundColor: "white",
                color: "#503B8F",
                border: "1px solid #503B8F",
            },
        },
        "&:disabled": {
            background: "#503B8F",
            color: "white",
            [theme.breakpoints.down("sm")]: {
                backgroundColor: "white",
                color: "#503B8F",
                border: "1px solid #503B8F",
            },
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            padding: "8px 20px",
            marginTop: 0,
        },
    },
    container: {
        backgroundImage: `url(${Bg})`,
        backgroundSize: "50%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
        padding: 60,
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            backgroundSize: "100%",
        },
    },
    inputRegister1: {
        width: "60%",
        borderRadius: 4,
        padding: "10px",
        height: 50,
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.2,
        color: "#9391A5",
        background: "#FFFFFF",
        border: "1px solid #9391A5",
        borderBottom: "2px solid #503B8F",
        marginTop: 30,
        textTransform: "none",
        [theme.breakpoints.down("sm")]:{
            width: "100%",
            justifyContent: "flex-start",
        },
    },
    mid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

export default function DialogButton() {
    const {t} = useTranslation("common");
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [values, setValues] = useState({
        name: "",
        numberPhone: "",
        email: "",
        content: "",
        page: "",
    });
    const pathName = window.location.pathname.split("/").at(-1);
    const [errorName, setErrorName] = useState(false);
    const [messageName, setMessageName] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [messageEmail, setMessageEmail] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);
    const [messagePhone, setMessagePhone] = useState("");
    const [errorPage, setErrorPage] = useState(false);
    const [messagePage, setMessagePage] = useState("");
    const [buttonText, setButtonText] = useState(t("content2"));

    const language = useSelector(({settings}) => settings.language);

    const userContactForm = useSelector(({home}) => home.search.userContactForm);

    useEffect(() => {
        setButtonText(t("content2"));
    }, [language, t]);

    useEffect(() => {
        if (userContactForm?.statusCode === 500) {
            dispatch(resetUserFormInput());
            return;
        }
        if (userContactForm?.statusCode === 200) {
            setSubmitted(true);
            dispatch(resetUserFormInput());
            return;
        }
        if (userContactForm?.data?.validMsgList?.name) {
            userContactForm.data.validMsgList = [];
            return;
        }
        if (userContactForm?.data?.validMsgList?.email) {
            userContactForm.data.validMsgList = [];
            return;
        }
    }, [dispatch, userContactForm]);

    const verifyNumberPhone = (value) => {
        const phoneRex = /((09|03|07|08|05)\d{8}|0\d{2,3}\d{7,8})\b/g;
        return phoneRex.test(value);
    };

    const verifyEmail = (value) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
        return emailRex.test(value);
    };

    const handleChangeName = (name) => (event) => {
        setValues({...values, [name]: event.target.value});
        setErrorName(event.target.value === "");
        if (event.target.value === "") {
            setErrorName(true);
            setMessageName(t("errorNotName"));
        }
    };

    const handleChangeEmail = (email) => (event) => {
        setValues({...values, [email]: event.target.value, page: pathName});
        if (event.target.value !== "") {
            if (verifyEmail(event.target.value)) {
                setErrorEmail(false);
            } else {
                setErrorEmail(true);
                setMessageEmail(t("errorValidMail"));
            }
        } else {
            setErrorEmail(true);
            setMessageEmail(t("errorNotMail"));
        }
    };

    const handleChangeContent = (content) => (event) => {
        setValues({...values, [content]: event.target.value});
    };

    const handleChangePhone = (numberPhone) => (event) => {
        setValues({...values, [numberPhone]: event.target.value});
        if (event.target.value !== "") {
            if (verifyNumberPhone(event.target.value)) {
                setErrorPhone(false);
            } else {
                setErrorPhone(true);
                setMessagePhone(t("errorValidPhone"));
            }
        } else {
            setErrorPhone(true);
            setMessagePhone(t("errorNotPhone"));
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSubmitted(false);
        setErrorPage(false);
        setErrorPhone(false);
        setErrorName(false);
        setErrorEmail(false);
        setButtonText(t("content2"));
        setValues({
            name: "",
            numberPhone: "",
            email: "",
            content: "",
            page: "",
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            page: value,
        }));
        setButtonText(t("content3") + value);
        setErrorPage(false);
        handleClose1();
    };

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        if (!values.email) {
            setErrorEmail(true);
            setMessageEmail(t("errorNotMail"));
            setLoading(false);
        }

        if (!values.numberPhone) {
            setErrorPhone(true);
            setMessagePhone(t("errorNotPhone"));
            setLoading(false);
        }

        if (!values.name) {
            setErrorName(true);
            setMessageName(t("errorNotName"));
            setLoading(false);
        }

        if (!values.page) {
            setErrorPage(true);
            setMessagePage(t("errorNotPage"));
            setLoading(false);
        }

        if (values.email && values.numberPhone && values.name && values.page) {
            dispatch(submitUserContactForm(values))
                .catch((err) => console.log(JSON.stringify(err)))
                .finally(() => setLoading(false));

            setValues({
                name: "",
                numberPhone: "",
                email: "",
                content: "",
                page: "",
            });
            setButtonText(t("content2"));
        }
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.buttonStyle}>
                {t("Get Consultation")}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"md"}>
                <div className={classes.container}>
                    <DialogTitle id="form-dialog-title" className={classes.containerTitle}>
                        {submitted && (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: isMobile ? 20 : 40 }}>
                                <img src={Logo} alt={"card"} width={isMobile ? 160 : 200} />
                            </div>
                        )}
                        <div className={classes.title}>
                            {submitted ? t("contactcontent7") : t("commitment1")}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div className={classes.subContent}>
                                {submitted ? t("contactcontent5") : t("contactcontent8")}
                                <br/>
                                {submitted ? t("contactcontent6") : t("contactcontent5")}
                            </div>
                        </DialogContentText>
                        {!submitted && (
                            <GridContainer>
                                <GridItem xs={isMobile ? 12 : 6}>
                                    <TextField
                                        id="name"
                                        name={"name"}
                                        label={t("name")}
                                        placeholder={t("name")}
                                        value={values.name}
                                        onChange={handleChangeName("name")}
                                        helperText={errorName ? messageName : ""}
                                        required={true}
                                        margin="dense"
                                        error={errorName}
                                        className={classes.inputRegister}
                                    />
                                </GridItem>
                                <GridItem xs={isMobile ? 12 : 6}>
                                    <TextField
                                        id="numberPhone"
                                        name={"numberPhone"}
                                        label={t("phone")}
                                        placeholder={t("phone")}
                                        value={values.numberPhone}
                                        onChange={handleChangePhone("numberPhone")}
                                        margin="dense"
                                        helperText={errorPhone ? messagePhone : ""}
                                        error={errorPhone}
                                        required={true}
                                        className={classes.inputRegister}
                                    />
                                </GridItem>
                                <GridItem xs={isMobile ? 12 : 6}>
                                    <TextField
                                        id="email"
                                        name={"email"}
                                        label={t("email")}
                                        placeholder={t("email")}
                                        value={values.email}
                                        onChange={handleChangeEmail("email")}
                                        margin="dense"
                                        helperText={errorEmail ? messageEmail : ""}
                                        error={errorEmail}
                                        required={true}
                                        className={classes.inputRegister}
                                    />
                                </GridItem>
                                <GridItem xs={isMobile ? 12 : 6}>
                                    <TextField
                                        id="content"
                                        label={t("content1")}
                                        name={"content"}
                                        placeholder={t("content1")}
                                        value={values.content}
                                        margin="dense"
                                        onChange={handleChangeContent("content")}
                                        className={classes.inputRegister}
                                    />
                                </GridItem>
                                <GridItem xs={12} className={classes.mid}>
                                    <Button
                                        onClick={handleClick}
                                        color="primary"
                                        className={classes.inputRegister1}
                                    >
                                        {buttonText}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose1}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        PaperProps={{
                                            style: {
                                                width: (!isMobile && anchorEl) ? anchorEl.clientWidth : undefined,
                                            },
                                        }}
                                    >
                                        <MenuItem onClick={() => handleMenuItemClick(t("Solutions for businesses"))}>
                                            {t("Solutions for businesses")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick(t("Agricultural solutions"))}>
                                            {t("Agricultural solutions")}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick(t("Hydropower solutions"))}>
                                            {t("Hydropower solutions")}
                                        </MenuItem>
                                    </Menu>
                                    {errorPage && (
                                        <FormHelperText style={{color: "#f44336", fontFamily: "Nunito"}} error>{messagePage}</FormHelperText>
                                    )}
                                </GridItem>
                            </GridContainer>
                        )}
                    </DialogContent>
                    {!submitted && (
                        <div className={classes.containerBtn}>
                            <DialogActions>
                                <Button type={"submit"} className={classes.btnRegister} onClick={onSubmit} style={loading ? {color: "#503B8F", background: "transparent", border: "1px solid #503B8F"} : {}}
                                        disabled={errorName || errorPhone || loading}>
                                    {loading ? t("sending") : t("send")}
                                </Button>
                            </DialogActions>
                        </div>
                    )}
                </div>
            </Dialog>
        </div>
    );
}