import {List, ListItem, useMediaQuery} from "@material-ui/core";
import Button from "../CustomButtons/Button";
import {Link} from "react-router-dom";
import dropdownActive from "../../assets/img/weather_update/solution/icon/dropdownActive.svg";
import dropdown from "../../assets/img/weather_update/solution/icon/dropdown.svg";
import MenuMaterial from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ListMaterial from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/weatherplus/layouts/authStyle";
import LogoWeatherplus from "../../assets/logo/WP_logo_no-slogan.png";
import Header from "./Header";
import DialogButton from "../Dialog/DialogButton";
import RightArrow from "assets/img/rightarrow.svg";
import NormalArrow from "assets/img/normalarrow.svg";
import LanguageDialog from "../../app/layout/components/LanguageDialog";
import {setLanguage} from "../../app/store/actions/settings.action";
import {Languages} from "../../app/values/settings";

const useStyles = makeStyles(styles);

const HeaderAppBar = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    const language = useSelector(({settings}) => settings.language);
    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    const handleLanguageChanged = (language) => {
        dispatch(setLanguage(language));
    };
    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language.toLowerCase());
        }
    }, [language]);

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [mobileAboutOpen, setMobileAboutOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [forecastAnchorEl, setForecastAnchorEl] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openMenuWeather, setOpenMenuWeather] = useState(false);
    const [aboutAnchorEl, setAboutAnchorEl] = useState(null);
    const [anchorNews, setAnchorNews] = useState(null);
    const [checkMenuNews, setCheckMenuNews] = useState(null);
    const handleShowMenuNewsMobile = () => {
        setCheckMenuNews(!checkMenuNews);
        setOpenMenuWeather(false);
        setMobileAboutOpen(false);
    };
    const handleShowMenuNewsDesktop = (event) => {
        setAnchorNews(event.currentTarget);
    };
    const handleCloseMenuNewsDesktop = () => {
        setAnchorNews(null);
    };
    const handleClickForecastMenu = (event) => {
        setForecastAnchorEl(event.currentTarget);
    };
    const handleCloseForecastMenu = () => {
        setForecastAnchorEl(null);
    };
    const handleClickAbout = (event) => {
        setAboutAnchorEl(event.currentTarget);
    };
    const handleCloseAbout = () => {
        setAboutAnchorEl(null);
    };
    const handleOpenSubMenu = () => {
        setOpenSubMenu(true);
    };
    const handleCloseSubMenu = () => {
        setOpenSubMenu(false);
    };
    const handleClickButton = () => {
        setMobileOpen(false);
    };

    const handleClickMenuWeatherMobi = () => {
        setOpenMenuWeather(!openMenuWeather);
        setMobileAboutOpen(false);
        setCheckMenuNews(false);
    };

    const handleClickMenuMobile = () => {
        setOpenMenu(!openMenu);
    };
    const handleClickMenuAboutMobile = () => {
        setMobileAboutOpen(!mobileAboutOpen);
        setCheckMenuNews(false);
        setOpenMenuWeather(false);
    };
    const HandleCloseMenuMobileAbout = () => {
        setMobileAboutOpen(false);
    };

    const pathName = window.location.pathname;

    let currentlyHovering = false;
    const handleHover = () => {
        currentlyHovering = true;
    };
    const handleCloseForecastHover = () => {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleCloseForecastMenu();
            }
        }, 50);
    };

    const handleCloseMenuNewsHover = () => {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleCloseMenuNewsDesktop();
            }
        }, 50);
    };

    const handleCloseAboutHover = () => {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleCloseAbout();
            }
        }, 50);
    };
    return (
        <Header
            fixed
            mobileOpen={mobileOpen}
            setMobileOpen={setMobileOpen}
            brand={<img className={classes.brandImage} src={LogoWeatherplus} alt="Weatherplus"/>}
            links={<div className={classes.collapse} style={{height: "100%"}}>
                <List className={classes.list} style={{height: "100%", width: isMobile ? "auto" : "auto"}}>
                    <ListItem className={classes.listItem}>
                        {!isMobile ?
                            <>
                                <Button
                                    disableRipple
                                    component={Link}
                                    className={classes.navLink +
                                        (pathName.search("/(giai-phap|solutions)") !== -1 ? " " + classes.navLinkActive : "")}
                                    color="transparent"
                                    onClick={handleClickForecastMenu}
                                    onMouseOver={handleClickForecastMenu}
                                    onMouseLeave={handleCloseForecastHover}
                                >
                                    {t("Solutions")}<img
                                    src={pathName.search("/(giai-phap|solutions)") !== -1 ? dropdownActive : dropdown}
                                    alt="dropdown" className={classes.dropdownIcon}/>
                                </Button>
                                <MenuMaterial
                                    id="forecast-menu"
                                    keepMounted
                                    open={Boolean(forecastAnchorEl)}
                                    className={classes.forecastMenu}
                                    TransitionComponent={Fade}
                                    onClose={handleCloseForecastMenu}
                                    MenuListProps={{
                                        onMouseEnter: handleHover,
                                        onMouseLeave: handleCloseForecastHover,
                                        style: {pointerEvents: "auto"},
                                    }}
                                    anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                                    PopoverClasses={{
                                        root: classes.popOverRoot,
                                    }}
                                >
                                    <div className={classes.containerMenu}>
                                        <GridContainer className={classes.contentCommonMenu}>
                                            <GridItem xs={4} className={classes.titleMainMenu}>
                                                {t("Solutions of WeatherPlus")}
                                            </GridItem>
                                            <GridItem xs={4} className={classes.subTitleMainMenu}>
                                                <MenuItem onClick={handleCloseForecastMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/giai-phap/giai-phap-cho-doanh-nghiep" : "/solutions/solutions-for-businesses"}
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-cho-doanh-nghiep|solutions-for-businesses)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Solutions for businesses")}
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseForecastMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/giai-phap/giai-phap-nong-nghiep" : "/solutions/solutions-for-agriculture"}
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-nong-nghiep|solutions-for-agriculture)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Agricultural solutions")}
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseForecastMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/giai-phap/giai-phap-thuy-dien" : "/solutions/solutions-for-hydropower"}
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-thuy-dien|solutions-for-hydropower)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Hydropower solutions")}
                                                    </Button>
                                                </MenuItem>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </MenuMaterial>
                            </>
                            :
                            <GridContainer>
                                <GridItem>
                                    <Button
                                        disableRipple
                                        component={Link}
                                        className={classes.navLink +
                                            (pathName.search("/(giai-phap|solutions)") !== -1 ? " " + classes.navLinkActive : "")}
                                        color="transparent"
                                        onClick={handleClickMenuWeatherMobi}
                                    >
                                        {t("Solutions")}
                                        <ListItemButton
                                            onClick={handleClickMenuWeatherMobi}
                                            className={classes.iconMenuMobile}
                                        >
                                            <img
                                                src={pathName.search("/(giai-phap|solutions)") !== -1 ? dropdownActive : dropdown}
                                                alt="dropdown" className={classes.dropdownIcon}/>
                                        </ListItemButton>
                                    </Button>
                                    <Collapse
                                        in={openMenuWeather}
                                        timeout={"auto"}
                                        unmountOnExit
                                        className={classes.detailMenuMobile}
                                    >
                                        <ListMaterial component={"div"} disablePadding>
                                            <Link
                                                to={language === Languages.vi ? "/giai-phap/giai-phap-cho-doanh-nghiep" : "/solutions/solutions-for-businesses"}
                                                onClick={handleClickMenuWeatherMobi}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-cho-doanh-nghiep|solutions-for-businesses)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Solutions for businesses")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/giai-phap/giai-phap-nong-nghiep" : "/solutions/solutions-for-agriculture"}
                                                onClick={handleClickMenuWeatherMobi}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-nong-nghiep|solutions-for-agriculture)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Agricultural solutions")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/giai-phap/giai-phap-thuy-dien" : "/solutions/solutions-for-hydropower"}
                                                onClick={handleClickMenuWeatherMobi}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(giai-phap|solutions)/(giai-phap-thuy-dien|solutions-for-hydropower)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Hydropower solutions")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                        </ListMaterial>
                                    </Collapse>
                                </GridItem>
                            </GridContainer>}
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        {!isMobile ? (
                                <>
                                    <Button
                                        disableRipple
                                        component={Link}
                                        className={
                                            classes.navLink +
                                            (pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life|cuoc-song-weatherplus|life-weatherplus|bao-chi-viet-ve-chung-toi|newspapers-write-about-us|tin-tuc|news|tin-tuc-nganh|industry-news|tin-nong-nghiep|agriculture-news|tin-thuy-dien|hydropower-new)") !== -1 ? " " + classes.navLinkActive : "")
                                        }
                                        color={"transparent"}
                                        onClick={handleShowMenuNewsDesktop}
                                        onMouseOver={handleShowMenuNewsDesktop}
                                        onMouseLeave={handleCloseMenuNewsHover}
                                    >
                                        {t("News")}<img
                                        src={(pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life|cuoc-song-weatherplus|life-weatherplus|bao-chi-viet-ve-chung-toi|newspapers-write-about-us|tin-tuc|news|tin-tuc-nganh|industry-news|tin-nong-nghiep|agriculture-news|tin-thuy-dien|hydropower-new)")) !== -1 ? dropdownActive : dropdown}
                                        alt="dropdown"
                                        className={classes.dropdownIcon}/>
                                    </Button>
                                    <MenuMaterial
                                        keepMounted
                                        id="news-menu"
                                        open={Boolean(anchorNews)}
                                        className={classes.forecastMenu}
                                        TransitionComponent={Fade}
                                        onClose={handleCloseMenuNewsDesktop}
                                        MenuListProps={{
                                            onMouseEnter: handleHover,
                                            onMouseLeave: handleCloseMenuNewsHover,
                                            style: {pointerEvents: "auto"},
                                        }}
                                        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                                        PopoverClasses={{
                                            root: classes.popOverRoot,
                                        }}
                                    >
                                        <div className={classes.containerMenu}
                                             onMouseLeave={handleCloseMenuNewsDesktop}>
                                            <GridContainer className={classes.contentCommonMenu}>
                                                <GridItem xs={4} className={classes.titleMainMenu}>
                                                    {t("Weather WP")}
                                                </GridItem>
                                                <GridItem xs={4} className={classes.subTitleMainMenu}>
                                                    <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={language === Languages.vi ? "/tin-thoi-tiet" : "/weather-news"}
                                                            className={classes.subMegaMenu + (pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Weather news")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={language === Languages.vi ? "/bao-chi-viet-ve-chung-toi" : "/newspapers-write-about-us"}
                                                            className={classes.subMegaMenu + (pathName.search("/(bao-chi-viet-ve-chung-toi|newspapers-write-about-us)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Company news")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={language === Languages.vi ? "/tin-tuc-nganh" : "/industry-news"}
                                                            className={classes.subMegaMenu + (pathName.search("/(tin-tuc-nganh|industry-news|tin-nong-nghiep|agriculture-news|tin-thuy-dien|hydropower-new)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Industry news")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseMenuNewsDesktop}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={language === Languages.vi ? "/cuoc-song-weatherplus" : "/life-weatherplus"}
                                                            className={classes.subMegaMenu + (pathName.search("/(cuoc-song-weatherplus|life-weatherplus)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("WeatherPlus's life")}
                                                        </Button>
                                                    </MenuItem>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </MenuMaterial>
                                </>
                            ) :
                            <GridContainer>
                                <GridItem>
                                    <Button
                                        disableRipple
                                        component={Link}
                                        className={
                                            classes.navLink +
                                            (pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life|cuoc-song-weatherplus|life-weatherplus|tin-tuc-nganh|industry-news|bao-chi-viet-ve-chung-toi|newspapers-write-about-us|tin-tuc|news)") !== -1 ? " " + classes.navLinkActive : "")
                                        }
                                        color="transparent"
                                        onClick={handleShowMenuNewsMobile}
                                    >
                                        {t("News")}<ListItemButton
                                        onClick={handleShowMenuNewsMobile}
                                        className={classes.iconMenuMobile}
                                    >
                                        <img
                                            src={(pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life|cuoc-song-weatherplus|life-weatherplus|bao-chi-viet-ve-chung-toi|newspapers-write-about-us|tin-tuc|news|tin-tuc-nganh|industry-news|tin-nong-nghiep|agriculture-news|tin-thuy-dien|hydropower-new)") !== 1) ? dropdownActive : dropdown}
                                            alt="dropdown"
                                            className={classes.dropdownIcon}/>
                                    </ListItemButton>
                                    </Button>
                                    <Collapse
                                        in={checkMenuNews}
                                        timeout={"auto"}
                                        unmountOnExit
                                        className={classes.detailMenuMobile}
                                    >
                                        <ListMaterial component={"div"} disablePadding>
                                            <Link
                                                to={language === Languages.vi ? "/tin-thoi-tiet" : "/weather-news"}
                                                onClick={handleShowMenuNewsMobile}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(tin-bao|hurricane-news|tin-thoi-tiet|weather-news|khi-hau-viet-nam|vietnam's-climate|thoi-tiet-cho-doanh-nghiep|weather-for-business|thoi-tiet-va-doi-song|weather-and-life)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Weather news")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/bao-chi-viet-ve-chung-toi" : "/newspapers-write-about-us"}
                                                onClick={handleShowMenuNewsMobile}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("(bao-chi-viet-ve-chung-toi|newspapers-write-about-us)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Company news")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/tin-tuc-nganh" : "/industry-news"}
                                                onClick={handleShowMenuNewsMobile}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(tin-tuc-nganh|industry-news|tin-nong-nghiep|agriculture-news|tin-thuy-dien|hydropower-new)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Industry news")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/cuoc-song-weatherplus" : "/life-weatherplus"}
                                                onClick={handleShowMenuNewsMobile}
                                            >
                                                <ListItemButton>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(cuoc-song-weatherplus|life-weatherplus)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("WeatherPlus's life")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                        </ListMaterial>
                                    </Collapse>
                                </GridItem>
                            </GridContainer>
                        }
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        {!isMobile ? (
                            <>
                                <Button
                                    disableRipple
                                    component={Link}
                                    className={classes.navLink + (pathName.search("/ve-chung-toi") !== -1 ? " " + classes.navLinkActive : "")}
                                    color="transparent"
                                    onClick={handleClickAbout}
                                    onMouseOver={handleClickAbout}
                                    onMouseLeave={handleCloseAboutHover}
                                >
                                    {t("About")}<img
                                    src={pathName.search("/(ve-chung-toi|about-us)") !== -1 ? dropdownActive : dropdown}
                                    alt="dropdown" className={classes.dropdownIcon}/>
                                </Button>
                                <MenuMaterial
                                    id="about-menu"
                                    keepMounted
                                    open={Boolean(aboutAnchorEl)}
                                    className={classes.forecastMenu}
                                    TransitionComponent={Fade}
                                    onClose={handleCloseAbout}
                                    MenuListProps={{
                                        onMouseEnter: handleHover,
                                        onMouseLeave: handleCloseAboutHover,
                                        style: {pointerEvents: "auto"},
                                    }}
                                    anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                                    PopoverClasses={{
                                        root: classes.popOverRoot,
                                    }}
                                >
                                    <div className={classes.containerMenu} onMouseLeave={handleCloseAbout}>
                                        <GridContainer className={classes.contentCommonMenu}>
                                            <GridItem xs={4} className={classes.titleMainMenu}>
                                                {t("About us")}
                                            </GridItem>
                                            <GridItem xs={4} className={classes.subTitleMainMenu}>
                                                <MenuItem onClick={handleCloseAbout}
                                                >
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus"}
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                        onMouseOver={handleOpenSubMenu}
                                                        style={openSubMenu ? {color: "#87B23F"} : {}}
                                                    >
                                                        {t("About us2")}<span style={{paddingLeft: 10}}><img
                                                        src={openSubMenu ? RightArrow : NormalArrow}
                                                        alt={"right"}/></span>
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseAbout}
                                                          onMouseEnter={handleCloseSubMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/ve-chung-toi/doi-ngu-chuyen-gia" : "/about-us/experts"}
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(doi-ngu-chuyen-gia|experts)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Experts")}
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseAbout}
                                                          onMouseEnter={handleCloseSubMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/ve-chung-toi/doi-tac" : "/about-us/partners"}
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(doi-tac|partners)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Partners")}
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseAbout}
                                                          onMouseEnter={handleCloseSubMenu}>
                                                    <Button
                                                        disableRipple
                                                        component={Link}
                                                        to={language === Languages.vi ? "/ve-chung-toi/lien-he" : "/about-us/contact"}
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(lien-he|contact)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        color="transparent"
                                                    >
                                                        {t("Contact")}
                                                    </Button>
                                                </MenuItem>
                                            </GridItem>
                                            {openSubMenu && (
                                                <GridItem xs={4} className={classes.subTitleMainMenu}>
                                                    <MenuItem onClick={handleCloseAbout}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={(language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus") + "#a"}
                                                            className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)#a") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Vision and mission")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseAbout}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={(language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus") + "#b"}
                                                            className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)#b") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Core values")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseAbout}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={(language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus") + "#c"}
                                                            className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)#c") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Development stages")}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={handleCloseAbout}>
                                                        <Button
                                                            disableRipple
                                                            component={Link}
                                                            to={(language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus") + "#d"}
                                                            className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)#d") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                            color="transparent"
                                                        >
                                                            {t("Leadership")}
                                                        </Button>
                                                    </MenuItem>
                                                </GridItem>
                                            )}
                                        </GridContainer>
                                    </div>
                                </MenuMaterial>
                            </>
                        ) : (
                            <GridContainer>
                                <GridItem>
                                    <Button
                                        disableRipple
                                        component={Link}
                                        className={classes.navLink + (pathName.search("/(ve-chung-toi|about-us)") !== -1 ? " " + classes.navLinkActive : "")}
                                        color="transparent"
                                        onClick={handleClickMenuAboutMobile}
                                    >
                                        {t("About")}
                                        <ListItemButton
                                            onClick={handleClickMenuAboutMobile}
                                            className={classes.iconMenuMobile}>
                                            <img
                                                src={pathName.search("/(ve-chung-toi|about-us)") !== -1 ? dropdownActive : dropdown}
                                                alt="dropdown" className={classes.dropdownIcon}/>
                                        </ListItemButton>
                                    </Button>
                                    <Collapse
                                        in={mobileAboutOpen}
                                        timeout={"auto"}
                                        unmountOnExit
                                        className={classes.detailMenuMobile}
                                    >
                                        <ListMaterial component={"div"} disablePadding>
                                            <Link
                                                to={language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus"}
                                                onClick={handleClickMenuMobile}
                                            >
                                                <ListItemButton
                                                    onClick={handleClickMenuAboutMobile}>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("About us2")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/ve-chung-toi/doi-ngu-chuyen-gia" : "/about-us/experts"}
                                                onClick={HandleCloseMenuMobileAbout}
                                            >
                                                <ListItemButton
                                                    onClick={handleClickMenuAboutMobile}>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(doi-ngu-chuyen-gia|experts)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Experts")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/ve-chung-toi/doi-tac" : "/about-us/partners"}
                                            >
                                                <ListItemButton
                                                    onClick={handleClickMenuAboutMobile}>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(doi-tac|partners)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Partners")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            <Link
                                                to={language === Languages.vi ? "/ve-chung-toi/lien-he" : "/about-us/contact"}
                                                onClick={HandleCloseMenuMobileAbout}
                                            >
                                                <ListItemButton
                                                    onClick={handleClickMenuAboutMobile}>
                                                    <ListItemText
                                                        className={classes.subMegaMenu + (pathName.search("/(ve-chung-toi|about-us)/(lien-he|contact)") !== -1 ? " " + classes.subMegaMenuImportant : "")}
                                                        primary={t("Contact")}
                                                        onClick={handleClickButton}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                        </ListMaterial>
                                    </Collapse>
                                </GridItem>
                            </GridContainer>
                        )}
                    </ListItem>
                    <ListItem className={`${classes.listItem} ${isMobile ? classes.listItemMobileOverride : ""}`}>
                        {!isMobile ?
                            <Button
                                disableRipple
                                component={Link}
                                className={classes.navLink + (pathName.search("/(tuyen-dung|recruitment)") !== -1 ? " " + classes.navLinkActive : "")}
                                to={language === Languages.vi ? "/tuyen-dung" : "/recruitment"}
                                color="transparent"
                            >
                                {t("Recruitment")}
                            </Button>
                            : <div>
                                <Button
                                    disableRipple
                                    component={Link}
                                    className={classes.navLink +
                                        (pathName.search("/(tuyen-dung|recruitment)") !== -1 ? " " + classes.navLinkActive : "")}
                                    color="transparent"
                                    to={language === Languages.vi ? "/tuyen-dung" : "/recruitment"}
                                    onClick={handleClickButton}
                                >
                                    <span style={{paddingTop: 15}}>{t("Recruitment")}</span>
                                </Button>
                                <div style={{padding: "20px 10px"}}>
                                    <DialogButton/>
                                </div>
                                <LanguageDialog
                                    onChange={handleLanguageChanged}
                                />
                            </div>}
                    </ListItem>

                    <ListItem className={classes.listItem} style={!isMobile ? {marginLeft: "13vw"} : {}}>
                        {!isMobile ?
                            <LanguageDialog
                                onChange={handleLanguageChanged}
                            />
                            : <></>}
                    </ListItem>

                </List>
            </div>}/>
    );
};

export default HeaderAppBar;