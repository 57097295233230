const locale = {
  Weather: "Thời tiết",
  Now: "hiện tại",
  Hourly: "Theo giờ",
  Daily: "Theo ngày",
  "90 days": "90 ngày",
  Humidity: "Độ ẩm",
  Wind: "Sức gió",
  AQI: "Chất lượng không khí AQI",
  UV: "UV",
  RealFeel: "Cảm nhận",
  "Dew point": "Nhiệt độ điểm sương",
  Visibility: "Tầm nhìn xa",
  "Cloud cover": "Độ bao phủ mây",
  Morning: "Sáng",
  Afternoon: "Chiều",
  Evening: "Tối",
  Night: "Đêm",
  Rain: "Lượng mưa",
  "Rain probability": "Khả năng mưa",
  "Today's weather forecast": "Dự báo thời tiết hôm nay",
  WeatherSearch: "Tra cứu thời tiết",
  "The weather in your place":
    "Nhập vị trí (VD: tên Phố, Phường, Quận, Tỉnh/ Thành phố)",
  "Search": "Nhập địa điểm",
  "Your location": "Tra cứu địa điểm của bạn ...",
  "Current weather": "Thời tiết hiện tại",
  "Next 24 hours weather": "Thời tiết 24 giờ tới",
  "Tomorrow's weather": "Thời tiết ngày mai",
  "Next 3 days weather": "Thời tiết 3 ngày tới",
  "Next 5 days weather": "Thời tiết 5 ngày tới",
  "Next 7 days weather": "Thời tiết 7 ngày tới",
  "Next 10 days weather": "Thời tiết 10 ngày tới",
  "Next 15 days weather": "Thời tiết 15 ngày tới",
  "Next 90 days weather": "Thời tiết 90 ngày",
  "See more": "Xem thêm",
  Monday: "Thứ 2",
  Tuesday: "Thứ 3",
  Wednesday: "Thứ 4",
  Thursday: "Thứ 5",
  Friday: "Thứ 6",
  Saturday: "Thứ 7",
  Sunday: "Chủ nhật",
  Mon: "T2",
  Tue: "T3",
  Wed: "T4",
  Thu: "T5",
  Fri: "T6",
  Sat: "T7",
  Sun: "CN",
  "Day time": "Ngày",
  "Night time": "Đêm",
  Hazardous: "Nguy hại",
  "Very unhealthy": "Rất xấu",
  Unhealthy: "Xấu",
  Poor: "Kém",
  Moderate: "Trung bình",
  Good: "Tốt",
  Extreme: "Gây hại rất cao",
  "Very high": "Gây hại cao",
  High: "Gây hại trung bình",
  Low: "Thấp",
  CurrentWeather:
    "Thời tiết <1>{{displayNameMain}}</1><2>{{displayName}}</2> hiện tại",
  TodayWeather:
    "Dự báo thời tiết <1>{{displayNameMain}}</1><2>{{displayName}}</2> hôm nay",
  NextHoursWeather:
    "Thời tiết <1>{{displayNameMain}}</1><2>{{displayName}}</2> 24 giờ tới",
  TomorrowWeather:
    "Thời tiết <1>{{displayNameMain}}</1><2>{{displayName}}</2> ngày mai",
  Next90DaysWeather:
    "Thời tiết <1>{{displayNameMain}}</1><2>{{displayName}}</2> 90 ngày",
  NextDaysWeather:
    "Thời tiết <4>{{displayNameMain}}</4><5>{{displayName}}</5> {{ndays}} ngày tới",
  CurrentWeatherTitle: "Dự báo thời tiết {0} hôm nay | WeatherPlus",
  NextHoursWeatherTitle:
    "Dự báo thời tiết {0} theo giờ hàng ngày | WeatherPlus",
  TomorrowWeatherTitle: "Dự báo thời tiết {0} ngày mai | WeatherPlus",
  Next90DaysWeatherTitle: "Dự báo thời tiết {0} 90 ngày | WeatherPlus",
  NextDaysWeatherTitle: "Dự báo thời tiết {0} trong {1} ngày tới | WeatherPlus",
  CurrentWeatherDescription:
    "Cập nhật tin dự báo thời tiết khu vực {0} hôm nay về tình hình nhiệt độ, khả năng mưa và nắng chính xác từ WeatherPlus.",
  NextHoursWeatherDescription:
    "Theo dõi thời tiết khu vực {0} 24h tới trong hôm nay. Cập nhật nhanh và chính xác về nhiệt độ, khả năng mưa theo tin dự báo.",
  TomorrowWeatherDescription:
    "Cập nhật tin dự báo thời tiết ngày mai tại {0} chính xác nhất. Theo dõi nhiệt độ và khả năng mưa mới nhất từ WeatherPlus.",
  Next90DaysWeatherDescription:
    "Theo dõi tin dự báo thời tiết 90 ngày tại {0} với khả năng mưa, nhiệt độ được cập nhật chính xác.",
  NextDaysWeatherDescription:
    "Cập nhật tin dự báo thời tiết {1} ngày tới tại {0} chính xác. Theo dõi nhiệt độ các ngày, khả năng mưa mới nhất tại WeatherPlus.",
  "Weather in your place": "Thời tiết ở vị trí hiện tại",
  "From day": "Từ ngày",
  "to day": "đến ngày",
  weatherURL: "thoi-tiet",
  nowURL: "hien-tai",
  "Day state 1": "Trời nắng",
  "Day state 2": "Nắng nóng",
  "Day state 3": "Nắng nóng gay gắt",
  "Day state 4": "Nắng nóng đặc biệt gay gắt",
  "Day state 5": "Trời nắng",
  "Day state 6": "Nắng nhẹ",
  "Day state 7": "Âm u",
  "Day state 8": "Trời rét",
  "Day state 9": "Rét đậm",
  "Day state 10": "Rét hại",
  "Day state 11": "Băng giá",
  "Day state 12": "Mưa gián đoạn",
  "Day state 13": "Mưa nhỏ",
  "Day state 14": "Mưa",
  "Day state 15": "Mưa to",
  "Day state 16": "Mưa rất to",
  "Day state 17": "Có mưa, trời rét",
  "Day state 18": "Có mưa, trời rét đậm",
  "Day state 19": "Có mưa, trời rét hại",
  "Day state 20": "Có mưa, đề phòng băng giá",
  "Day state 21": "Mưa to, trời rét",
  "Day state 22": "Mưa to, trời rét đậm",
  "Day state 23": "Mưa to, trời rét hại",
  "Day state 24": "Mưa tuyết",
  "Day state 25": "Mưa rất to, trời rét",
  "Day state 26": "Mưa rất to, trời rét đậm",
  "Day state 27": "Mưa rất to, trời rét hại",
  "Day state 28": "Mưa tuyết",
  "Night state 1": "Quang mây",
  "Night state 2": "Nóng bức",
  "Night state 3": "Rất nóng bức",
  "Night state 4": "Đặc biệt nóng bức",
  "Night state 5": "Ít mây",
  "Night state 6": "Mây thay đổi",
  "Night state 7": "Nhiều mây",
  "Night state 8": "Trời rét",
  "Night state 9": "Rét đậm",
  "Night state 10": "Rét hại",
  "Night state 11": "Băng giá",
  "Night state 12": "Mưa gián đoạn",
  "Night state 13": "Mưa nhỏ",
  "Night state 14": "Mưa",
  "Night state 15": "Mưa to",
  "Night state 16": "Mưa rất to",
  "Night state 17": "Có mưa, trời rét",
  "Night state 18": "Có mưa, trời rét đậm",
  "Night state 19": "Có mưa, trời rét hại",
  "Night state 20": "Có mưa, đề phòng băng giá",
  "Night state 21": "Mưa to, trời rét",
  "Night state 22": "Mưa to, trời rét đậm",
  "Night state 23": "Mưa to, trời rét hại",
  "Night state 24": "Mưa tuyết",
  "Night state 25": "Mưa rất to, trời rét",
  "Night state 26": "Mưa rất to, trời rét đậm",
  "Night state 27": "Mưa rất to, trời rét hại",
  "Night state 28": "Mưa tuyết",
  updatedAt: "Cập nhật lúc: {0}",
  "Enter location": "Vị trí {0}",

  //smart gen
  "Today's weather summary": "Tóm tắt thời tiết hôm nay",
  "Read more": "Xem thêm",
  "Show less": "Thu gọn",
  "Rain and Lightning map":
    "Bản đồ dự báo mưa 3 giờ tới tại <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
};

export default locale;
