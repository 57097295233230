const locale = {
    "Latest news": "Tin tức mới nhất",
    "Most view news": "Được xem nhiều nhất",
    "Storm news": "Tin bão",
    "See more": "Xem tất cả bài viết",
    "See more news home": "Xem thêm",
    "Post date": "Đăng ngày",
    "Featured news": "Tin tức nổi bật",
    "mobile news1": "Thông tin thời tiết",
    "mobile news2": "Thông tin nổi bật",
    Categories: "Chuyên mục",
    "Weather news": "Tin thời tiết",
    "Company news": "Tin doanh nghiệp",
    "Industry news": "Tin tức ngành",
    "WeatherPlus's life": "Nhịp sống WeatherPlus",
    "WP's life": "Nhịp sống WP",
    "Agriculture news": "Tin nông nghiệp",
    "Hydropower news": "Tin thuỷ điện",
    "Vietnam climate": "Khí hậu Việt Nam",
    "Weather for life": "Thời tiết và đời sống",
    News: "Tin tức",
    Tags: "Tags",
    "Weather for businesses": "Thời tiết doanh nghiệp",
    "Weather WP": "Tin tức WeatherPlus",
};

export default locale;
