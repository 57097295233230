import React from "react";
import authRoles from "app/auth/auth_roles";

const productConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(giai-phap|solutions)",
      exact: true,
      component: React.lazy(() => import("./SolutionsPage")),
    },
    {
      path: "/(giai-phap|solutions)/(giai-phap-cho-doanh-nghiep|solutions-for-businesses)",
      exact: true,
      component: React.lazy(() => import("./SolutionEnterprise")),
    },
    {
      path: "/(giai-phap|solutions)/(giai-phap-nong-nghiep|solutions-for-agriculture)",
      exact: true,
      component: React.lazy(() => import("./SolutionAgri")),
    },
    {
      path: "/(giai-phap|solutions)/(giai-phap-thuy-dien|solutions-for-hydropower)",
      exact: true,
      component: React.lazy(() => import("./SolutionHydro")),
    },
  ],
};

export default productConfig;
