import {
  container,
  containerHeaderDesktop,
  defaultFont,
  transition,
  drawerWidth,
  whiteColor,
  grayColor,
} from "assets/jss/weatherplus.js";

const headerStyle = (theme) => ({
  appBar: {
    display: "flex",
    border: "0",
    height: "68px",
    color: grayColor[15],
    width: "100%",
    backgroundColor: whiteColor,
    boxShadow:
      "10px 10px 20px 0 rgb(69 61 141 / 25%), -10px -10px 20px 0 #e9e8f58c",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    paddingRight: "0px !important",
    // [theme.breakpoints.down("sm")]: {
    //   height: "66px",
    // },
  },
  absolute: {
    position: "absolute",
    top: "auto",
  },
  fixed: {
    position: "fixed",
  },
  container: {
    ...container,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  containerHeaderDesktop: {
    ...containerHeaderDesktop,
    minHeight: "50px",
    padding:"0px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
    maxWidth: "1350px",
    width: "100%",
  },
  title: {
    letterSpacing: "unset",
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      fontWeight: 700,
      fontFamily: "Nunito,Times New Roman,serif",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent",
      },
    },
  },
  appResponsive: {
    margin: "20px 10px",
    marginTop: "40px",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    maxHeight: "100vh",
    overflow: "auto",
    ...transition,
  },
  hidden: {
    width: "100%",
    height:"100%"
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto",
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1",
  },
});

export default headerStyle;
