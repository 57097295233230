import React from "react";
import authRoles from "app/auth/auth_roles";

const aboutConfig = {
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/(ve-chung-toi|about-us)/(lien-he|contact)",
            exact: true,
            component: React.lazy(() => import("./Contact")),
        },
        {
            path: "/(ve-chung-toi|about-us)/(gioi-thieu-cong-ty|weatherplus)",
            exact: true,
            component: React.lazy(() => import("./AboutUsmore")),
        },
        {
            path: "/(ve-chung-toi|about-us)/(doi-ngu-chuyen-gia|experts)",
            exact: true,
            component: React.lazy(() => import("./TeamOfExperts")),
        },
        {
            path: "/(ve-chung-toi|about-us)/(doi-tac|partners)",
            exact: true,
            component: React.lazy(() => import("./Partner")),
        },
    ],
};

export default aboutConfig;