const locale = {
    // Navbar
    Weather: "Weather",
    Products: "Products",
    About: "About WeatherPlus",
    "Solutions of WeatherPlus": "WeatherPlus Solutions",
    "Solutions for businesses": "Weather solutions for businesses",
    "Agricultural solutions": "Agricultural solutions",
    "Hydropower solutions": "Hydropower solutions",
    "About us": "About WeatherPlus",
    "About us2": "Company overview",
    Experts: "Expert team",
    Partners: "Partners and clients",
    "Vision and mission": "Vision and mission",
    "Core values": "Core values",
    "Development stages": "Development stages",
    Leadership: "Leadership team",
    "Weather WP": "WeatherPlus News",
    "Weather news": "Weather news",
    "Company news": "Company news",
    "Industry news": "Industry news",
    "WeatherPlus's life": "WeatherPlus's life",
    "Agriculture news": "Agriculture news",
    "Hydropower news": "Hydropower news",
    "Featured News": "Featured News",
    "View All News": "View All News",
    "WP's life": "WP's life",
    "Error 404": "Page not found",
    "Page not found": "The page you are looking for does not exist or has been deleted.",
    "Company name": "Weatherplus Solution Joint Stock Company",
    "Business registration": "Business Registration Number: 0106597121, issued by the Hanoi ",
    "Confirmed by": "Department of Planning and Investment on July 9, 2024",
    "Address company1": "18F, Block A, Song Da Tower, Pham Hung Street,",
    "Address company2": "My Dinh 1 Ward, Nam Tu Liem District, Hanoi, Vietnam",
    "Address company3": "18F, Block A, Song Da Tower, Pham Hung",
    "Address company4": "Street, My Dinh 1 Ward, Nam Tu Liem",
    "Address company5": "District, Hanoi, Vietnam",
    "Products for businesses": "Weather products for businesses",
    "Products for agriculture": "Agricultural products",
    "Products for hydropower": "Hydropower products",
    "Terms of Use": "Terms of Use",
    "Privacy Policy": "Privacy Policy",
    "Privacy Policy content": "WeatherPlus implements the following privacy policies to indicate issues related to customer information security for this website. As a general rule, no personal information of customers is automatically collected from the website. However, some non-personal information of visitors related to traffic will be recorded by WeatherPlus's standard operating servers. This information is used for the purpose of upgrading and improving the user experience of our website. WeatherPlus may share your personal information with third parties only to serve the service to you or additional information about WeatherPlus that you request (for example, sending e-mails, filling in necessary information and answering surveys). Any third-party websites linked on this website may have their own privacy policies and terms. You should carefully review the privacy policies of these sites to make appropriate personal information choices for each different website.",
    "Terms of Use subtitle1": "1. General conditions",
    "Terms of Use content1": "We only provide services that comply with the law, we do not provide prohibited services, not allowed by Vietnamese law. #When you access our website, it means you agree to our terms. #When you visit our website, you must ensure that you are at least 14 years old, or access under the supervision of a parent or legal guardian. Customers must ensure that they have full civil capacity to perform transactions of buying and selling goods according to the current regulations of Vietnamese law. #We provide services nationwide. Working hours are from Monday to Friday (8:30 AM - 5:30 PM), not working on Saturday, Sunday and holidays.",
    "Terms of Use subtitle2": "2. Copyright",
    "Terms of Use content2": "WeatherPlus Solution Joint Stock Company is a company specializing in providing mainly weather forecasting services for businesses in accordance with Vietnamese law. #WeatherPlus Solution Joint Stock Company is licensed by the Ministry of Natural Resources and Environment to operate weather forecasting and hydro-meteorological warning No. 01 of 2017, issued on 03/04/2017; first amendment No. 14 / GP-BTNMT, issued on 21/01/2021. #We forecast, weather warning, hydro-meteorological nationwide for organizations, individuals and communities.",
    "Terms of Use subtitle3": "3. Price policy",
    "Terms of Use content3": "The information and service price policy on the website are always the same as the actual address of WeatherPlus Weather Solutions Joint Stock Company: No. 15, Lane 202, Dinh Cong Ha Street, Dinh Cong Ward, Hoang Mai District, Hanoi City.",
    "Home header mobile": "PIONEERING THE DEVELOPMENT OF RELIABLE SOLUTIONS ON WEATHER PLATFORM",
    "Home header desktop1": "PIONEERING THE DEVELOPMENT OF RELIABLE SOLUTIONS",
    "Home header desktop2": "ON WEATHER PLATFORM",
    "Get Consultation": "Get Consultation",
    "ListData Home title mobile": "THE FIRST PRIVATE COMPANY LICENSED TO PROVIDE WEATHER FORECASTING SERVICE",
    "ListData Home title desktop1": "THE FIRST PRIVATE COMPANY LICENSED",
    "ListData Home title desktop2": "TO PROVIDE WEATHER FORECASTING SERVICE",
    "years of experience": "years of experience",
    "hydropower clients": "hydropower clients",
    "experts, engineers in meteorology and hydropower": "experts, engineers in meteorology and hydropower",
    "Top-rated customer service": "Top-rated customer service",
    "Commitment to reliability and dedicated support": "commitment to reliability and dedicated support",
    "partnered businesses": "partnered businesses",
    "monitoring stations nationwide": "monitoring stations nationwide",
    "active accounts": "active accounts",
    "weather data points daily": "weather data points daily",
    "millions": "millions",
    "weather data points daily, updated in real-time": "weather data points daily, updated in real-time",
    "Experienced experts": "Experienced experts",
    "in meteorological and hydrological forecasting": "in meteorological and hydrological forecasting",
    "Over 90% accuracy and reliability": "Over 90% accuracy and reliability",
    "Over 90%": "Over 90%",
    "accuracy and reliability": "accuracy and reliability",
    "Dedicated customer service": "Dedicated customer service",
    "Home helmet": "Reliable weather service",
    "Vision and mission of WeatherPlus": "Vision and mission of WeatherPlus",
    "of WeatherPlus": "of WeatherPlus",
    "Vision": "Vision",
    "Mission": "Mission",
    "Corporate philosophy": "Corporate philosophy",
    "contentChoice1": "Continuously innovating, we are committed to providing reliable products and services that help our customers optimize their economies, drive green transformation and contribute to sustainable development. WeatherPlus aspires to become a prosperous organization where everyone collaborates to create real value, is rewarded fairly and works in a happy environment.",
    "contentChoice2": "With dedication and innovation, we provide sustainable solutions that optimize efficiency for our customers while fostering a positive work environment where each member grows and contributes to collective success.",
    "contentChoice3": "We believe that the foundation of success lies in people with Ethics, Wisdom, and Willpower. With these three values, we build a happy, efficient, and meaningful organization.",
    "confirmprefix": "WeatherPlus asserts its pioneering position",
    "confirmsubfix": "in researching and developing reliable products and services based on weather platform.",
    "homecontext1": "Providing reliable weather reports for various sectors: Aviation, oil & gas, tourism, coal and mineral mining,...to help businesses optimize production and operations, and mitigate risks associated with weather and climate change.",
    "homecontext2": "Developing solutions for detecting crop pests and forecasting agricultural weather using AI and Big Data to assist farmers in effective and sustainable cultivation practices.",
    "homecontext3": "Offering products and services that support the operation of dams and hydropower plants, helping optimize power generation and enabling managers to understand weather patterns for developing scenarios to handle adverse flood conditions.",
    "learnmore": "Learn More",
    "seemore": "See More",
    "close": "Close",
    "reliable commitment": "Reliable commitment",
    "dedicated to every service": "Dedicated to every service",
    "joinus": "JOIN US",
    "joinuscontent1": "WeatherPlus is proud to offer a professional, friendly work environment with a quality team, providing each member with the space and opportunities for healthy growth.",
    "joinuscontent2": "Learn more about the industries and job opportunities at WeatherPlus.",
    "learnmore2": "Learn more",
    "agriheader": "WEATHER SOLUTIONS FOR AGRICULTURE",
    "weatherheader": "WEATHER FOR BUSINESSES",
    "introweathertitle1": "Developing in-depth weather solutions",
    "introweathertitle2": "for businesses",
    "introweathercontent1": "As a company specializing in advanced weather solutions for businesses, WeatherPlus focuses on the specific needs of various industries, providing reliable weather forecasts for sectors: Aviation, oil & gas, tourism, coal and mineral mining,...to help businesses optimize production and operations, and mitigate risks associated with weather and climate change.",
    "introweathersubcontent1": "WeatherPlus has a dedicated team of top meteorology experts with extensive experience.",
    "introweathersubcontent2": "Applying modern technology in monitoring and analyzing weather patterns.",
    "introweathersubcontent3": "Always ready to accompany and support customers 24/7.",
    "solutiontitle": "Weather forecasting services for businesses",
    "solutioncontent": "Helping businesses optimize production and operations while minimizing risks caused by weather and climate change",
    "Aviation": "Aviation",
    "Oil & gas extraction": "Oil & gas extraction",
    "Coal and mineral mining": "Coal and mineral mining",
    "Offshore Wind Power": "Offshore wind power",
    "APPAREL AND FASHION": "Apparel and fashion",
    "Tourism and events": "Tourism and events",
    "Climate forecasting": "Climate forecasting",
    "Weather climate APIs": "Weather, climate APIs",
    "downloadreport": "Download Report",
    "Why do clients trust": "Why do clients trust",
    "WeatherPlus's weather": "WeatherPlus's weather",
    "solutions for businesses?": "solutions for businesses?",
    "Why clients trust": "Why clients trust",
    "WeatherPlus'1": "WeatherPlus' hydropower solutions?",
    "WeatherPlus'2": "WeatherPlus' agriculture solutions?",
    "hydropower solutions": "",
    "agriculture solutions": "",
    "introagrititle1": "The digital agriculture ecosystem",
    "introagrititle2": "for farmers",
    "introagricontent1": "\"Digital transformation in agriculture\"",
    "introagricontent2": "is considered as an effective remedy for Vietnam’s agriculture sector, which is heavily impacted by climate change, characterized by fragmented and inefficient production, and overuse of chemical fertilizers and pesticides leading to soil degradation.",
    "introagricontent3": "Understanding the challenges faced by the agriculture industry today, WeatherPlus has developed an ecosystem of solutions, products, and services leveraging Artificial Intelligence (AI) and Big Data to support farmers in cultivation and enhance economic benefits from farming.",
    "agriplatformtitle": "Digital agriculture solutions",
    "agriplatformcontent1": "WeatherPlus’s agricultural ecosystem is collaborating with Vietnam's",
    "agriplatformcontent2": "two largest mobile network operators, MobiFone and Viettel, to provide digital agriculture solutions: mobiAgri and VietPlant.",
    "downloadapp": "Download App",
    "othersolution": "Other solutions supporting the digital transformation of the agriculture sector green agricultural advisory app",
    "argilistdata1": "Identification of",
    "argilistdata2": "5.000+ crops and 700+ pests affecting 35 common crops",
    "argilistdata3": "Specialized social network",
    "argilistdata4": "connecting experts with farmers, farmers with farmers, buyers with sellers",
    "argilistdata5": "Experienced agricultural experts",
    "argilistdata6": "are ready to provide online consultation to farmers",
    "hydroheader": "SEHO HYDROPOWER OPERATIONS SOLUTIONS",
    "introhydrotitle": "The most comprehensive and complete ecosystem on the market",
    "introhydrocontent1": "SEHO",
    "introhydrocontent2": "is designed to provide early weather forecasts for reservoirs and hydropower plants regarding rainfall, flow rates and support tools, helping hydropower plants manage operations proactively, increase economic benefits, and minimize flood risks downstream.",
    "introhydrocontent3": "SEHO's operational support solutions integrate",
    "introhydrocontent4": "Artificial Intelligence (AI) and Big Data technologies, combined with the expertise of experienced meteorologists",
    "introhydrocontent5": "from Japan and Vietnam, to deliver tailored forecasting models/scenarios suitable for each basin and specific climate conditions.",
    "hydrosilutiontitle": "SEHO HYDROPOWER OPERATIONS SOLUTIONS",
    "hydrosilutioncontent": "SEHO is the most comprehensive and complete ecosystem on the market, providing weather solutions and services for hydropower plants and reservoirs.",
    "aboutWP": "About WeatherPlus",
    "introaboutcontent1": "Throughout a decade of continuous innovation and growth,",
    "introaboutcontent2": "WeatherPlus Solution Joint Stock Company (WeatherPlus)",
    "introaboutcontent3": "has established its pioneering position in providing reliable products and services that help customers optimize economic efficiency and drive green transformation.",
    "introaboutsubcontent1": "With a solid foundation built on three core values: Ethics, Wisdom, and Willpower, WeatherPlus has developed an ecosystem of advanced technological solutions, becoming a trusted partner of many major corporations both domestically and internationally.",
    "introaboutsubcontent2": "We are committed to building a prosperous organization where members unite to create genuine value, contribute to sustainable community development, and together foster a happy working environment.",
    "Work Experience": "Work Experience",
    "partnersheader1": "WeatherPlus is proud to provide solutions to",
    "partnersheader2": "500+ clients, partners both domestically and internationally.",
    "partnersheader3": "WeatherPlus consistently meets the diverse needs of clients across all sectors.",
    Feedback: "Feedback from clients",
    "Contacts": "Business Contacts",
    "letmeknow": "Please give us your weather problems!",
    "contactcontent1": "Helping businesses optimize production and operations while minimizing risks caused by weather and climate change.",
    "contactcontent2": "For any inquiries, please contact",
    "contactcontent3": "Contact WeatherPlus now",
    "contactcontent4": "Contact WeatherPlus team now for support",
    "contactcontent5": "We will contact you as soon as possible.",
    "contactcontent6": "Thank you!",
    "contactcontent7": "Thank you for sending the information to WeatherPlus.",
    "contactcontent8": "Please complete the information below and send it to us.",
    "send": "Send",
    "errorValidMail": "Please enter a valid email address",
    "errorNotMail": "Please enter your email address",
    "errorNotName": "Please enter your full name",
    "errorValidPhone": "Please enter a valid phone number",
    "errorNotPhone": "Please enter your phone number",
    "errorNotPage": "Please choice your area of interest",
    "name": "Full name",
    "email": "Email",
    "phone": "Phone number",
    "content": "The weather issue you are facing",
    "content1": "Name of business",
    "content2": "Area of interest *",
    "content3": "Selected: ",
    "sending": "Sending...",
    "commitment1": "RELIABLE COMMITMENT - DEDICATED TO EVERY SERVICE",
    "recuitmentheader": "Recruitment WeatherPlus",
    "recuitmentcontent": "fosters an open work environment that encourages idea exchange at all levels, allowing you to work creatively to develop your personal abilities. Here, you will not only enhance your professional and technical skills with the dedicated guidance from colleagues and management but also upgrade your soft skills by participating in exciting clubs and weekly training programs.",
    "newsWP": "WeatherPlus News",
    "wpPro1": "Weather Forecasting and",
    "wpPro2": "Consulting Experts for Businesses",
    "forecasting1": "Climate Forecast",
    "forecasting2": "30-day forecast",
    "forecasting3": "45-day forecast",
    "forecasting4": "3-month forecast",
    "forecasting5": "6-month forecast",
    "forecasting6": "*Nationwide coverage",
    "forecasting7": "Receive now",
    Solutions: "Solutions",
    Hiring: "Hiring",

    Contact: "Contact information",
    Home: "Home",
    "Weather forecasts": "Weather forecasts",
    Weather4Business: "Business+",
    WeatherCareer: "Solution for careeer",

    "Vision - Mission": "Vision - Mission",
    Milestones: "Milestones",
    Awards: "Awards",

    Projects: "Projects",
    Agriculture: "Agriculture",
    Hydroelectric: "Hydroelectric",
    NewsWeather: "Weather news",
    News: "News",
    "Press releases": "Press releases",
    "Life at WeatherPlus": "Life at WeatherPlus",
    "Cooperation information": "Cooperation information",
    Technology: "Technology",

    Recruitment: "Recruitment",
    "Job opportunities": "Job opportunities",
    "Employment policies": "Employment policies",
    "Recruitment process": "Recruitment process",

    "Happy rain": "HAPPY RAIN",

    // Language
    English: "EN",
    Vietnamese: "VN",
    "Select Language": "NN",
    Cancel: "TT",

    // Location dialog
    Agree: "Agree",
    "Share your location?": "Share your location?",
    "Agree to see the weather in your location":
        "Agree to see the weather in your location",

    // Footer
    Introduction:
        "WeatherPlus is the pioneer in Vietnam weather forecasting industry. Our mission is to help businesses and individuals manage weather challenges by offering reliable weather forecast and data.",
    Hotline: "Hotline",
    "Contact Information": "Contact Information",
    "Contact us for consultations on weather solutions":
        "Contact us for consultations on weather solutions",
    Address:
        "18th floor, Tower A, Song Da Building, Pham Hung, Nam Tu Liem, Hanoi",
    "View map": "View map",
};

export default locale;
