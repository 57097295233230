import React,{useEffect} from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { GlobalStyles } from "@mui/material";

import { createBrowserHistory } from "history";

import "assets/scss/weatherplus.scss?v=1.0.0";
import "@fontsource/roboto/300.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/mulish/300.css";
import "@fontsource/nunito/300.css";
import "@fontsource/quicksand/300.css";
import "app/i18n/i18n.config";

import Layout from "app/layout/layout";
import AppContext from "./app_context";
import routes from "./configs/routes.config";
import { store, persistor } from "./store";
import { Auth } from "./auth";
import Authorization from "app/auth/authorization";
import WebFont from "webfontloader";

const hist = createBrowserHistory();

const theme = createTheme({
  typography: {
    fontFamily: "\"Nunito\", Mulish, Quicksand, \"Helvetica\", \"Arial\", sans-serif, Roboto, Open Sans",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      light: "#8cc63f",
      main: "#7fb439",
      dark: "#6ca42c",
      mainAlt: "#503B8F",
      contrastText: "#fff",
    },
    secondary: {
      light: "#827bb9",
      main: "#4c439b",
      dark: "#3c3489",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.30)",
      disabled: "rgba(0, 0, 0, 0.18)",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    highlight: {
      main: "#c9c7e1",
    },
  },
});

const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito", "Mulish", "Quicksand", "Roboto", "Open Sans"],
      },
    });
  },[]);
  return (
    <AppContext.Provider value={{ routes }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
            styles={{
              "*": {
                WebkitFontSmoothing: "antialiased",
                MozOsxFontSmoothing: "grayscale",
                textRendering: "optimizeLegibility",
              },
              body: {
                fontFamily: "\"Nunito\", \"Mulish\", \"Quicksand\", \"Roboto\", \"Open Sans\", \"Helvetica\", \"Arial\", sans-serif",
              },
            }}
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Auth>
              <Router basename="/index.html" history={hist}>
                <Authorization>
                  <Layout />
                </Authorization>
              </Router>
            </Auth>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

App.displayName = "App";

export default App;
