const locale = {
  Weather: "Weather",
  Now: "now",
  Hourly: "Hourly",
  Daily: "Daily",
  "90 days": "90 days",
  Humidity: "Humidity",
  Wind: "Wind",
  AQI: "Air Quality",
  UV: "UV",
  RealFeel: "RealFeel",
  "Dew point": "Dew point",
  Visibility: "Visibility",
  "Cloud cover": "Cloud cover",
  Morning: "Morning",
  Afternoon: "Afternoon",
  Evening: "Evening",
  Night: "Night",
  Rain: "Rain",
  "Rain probability": "Rain probability",
  "Today's weather forecast": "Today's weather forecast",
  "Your location": "Your location ...",
  WeatherSearch: "Weather search",
  "Search": "Enter your location",
  "The weather in your place": "Enter location (Ex: Street, Ward, District, Province/ City)",
  "Current weather": "Current weather",
  "Next 24 hours weather": "Next 24 hours weather",
  "Tomorrow's weather": "Tomorrow's weather",
  "Next 3 days weather": "Next 3 days weather",
  "Next 5 days weather": "Next 5 days weather",
  "Next 7 days weather": "Next 7 days weather",
  "Next 10 days weather": "Next 10 days weather",
  "Next 15 days weather": "Next 15 days weather",
  "Next 90 days weather": "90 days weather",
  "See more": "See more",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  "Day time": "Day",
  "Night time": "Night",
  Hazardous: "Hazardous",
  "Very unhealthy": "Very unhealthy",
  Unhealthy: "Unhealthy",
  Poor: "Poor",
  Moderate: "Moderate",
  Good: "Good",
  Extreme: "Extreme",
  "Very high": "Very high",
  High: "High",
  Low: "Low",
  CurrentWeather: "Current weather in <1>{{displayNameMain}}</1><2>{{displayName}}</2> ",
  TodayWeather: "Today's weather forecast in <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
  NextHoursWeather: "Next 24 hours weather in <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
  TomorrowWeather: "Tomorrow's weather in <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
  Next90DaysWeather: "90 days weather in <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
  NextDaysWeather: "Next {{ndays}} days weather in <1>{{displayNameMain}}</1><2>{{displayName}}</2>",
  CurrentWeatherTitle: "{0} Weather Today | WeatherPlus",
  NextHoursWeatherTitle: "{0} Hourly Weather Forecast | WeatherPlus",
  TomorrowWeatherTitle: "{0} Tomorrow Weather Forecast | WeatherPlus",
  Next90DaysWeatherTitle: "{0} 90 Day Weather Forecast | WeatherPlus",
  NextDaysWeatherTitle: "{0} {1} Day Weather Forecast | WeatherPlus",
  CurrentWeatherDescription:
    "Follow today's {0} regional weather forecast for accurate temperature, rain, and sunshine from WeatherPlus",
  NextHoursWeatherDescription:
    "Follow the hourly local {0} weather forecast with accurate temperature updates from WeatherPlus.",
  TomorrowWeatherDescription:
    "Follow tomorrow's weather forecast at {0} with the possibility of rain, accurate temperature updates.",
  Next90DaysWeatherDescription:
    "Follow the weather forecast for the next 90 days at {0} with the possibility of rain, accurate temperature updates.",
  NextDaysWeatherDescription:
    "Follow the weather forecast for the next {1} day at {0} with the possibility of rain, accurate temperature updates.",
  "Weather in your place": "Weather in your place",
  "From day": "From",
  "to day": "to",
  weatherURL: "weather",
  nowURL: "now",
  "Day state 1": "Sunny",
  "Day state 2": "Hot",
  "Day state 3": "Very hot",
  "Day state 4": "Especially hot",
  "Day state 5": "Sunny",
  "Day state 6": "Light sun",
  "Day state 7": "Overcast",
  "Day state 8": "Cold",
  "Day state 9": "Deep freeze",
  "Day state 10": "Harmful cold",
  "Day state 11": "Ice",
  "Day state 12": "Intermittent rain",
  "Day state 13": "Light rain",
  "Day state 14": "Rain",
  "Day state 15": "Heavy rain",
  "Day state 16": "Very heavy rain",
  "Day state 17": "Rainy and cold",
  "Day state 18": "Rainy and Deep freeze",
  "Day state 19": "Rainy and Harmful cold",
  "Day state 20": "Rainy and ice",
  "Day state 21": "Heavy rain and cold",
  "Day state 22": "Heavy rain and deep freeze",
  "Day state 23": "Heavy rain and harmful cold",
  "Day state 24": "Sleet",
  "Day state 25": "Very heavy rain and cold",
  "Day state 26": "Very heavy rain and deep freeze",
  "Day state 27": "Very heavy rain and harmful cold",
  "Day state 28": "Sleet",
  "Night state 1": "Clear sky",
  "Night state 2": "Muggy",
  "Night state 3": "Very muggy",
  "Night state 4": "Especially hot",
  "Night state 5": "Partly cloudy",
  "Night state 6": "Clouds change",
  "Night state 7": "Cloudy",
  "Night state 8": "Cold",
  "Night state 9": "Deep freeze",
  "Night state 10": "Harmful cold",
  "Night state 11": "Ice",
  "Night state 12": "Intermittent rain",
  "Night state 13": "Light rain",
  "Night state 14": "Rain",
  "Night state 15": "Heavy rain",
  "Night state 16": "Very heavy rain",
  "Night state 17": "Rainy and cold",
  "Night state 18": "Rainy and Deep freeze",
  "Night state 19": "Rainy and Harmful cold",
  "Night state 20": "Rainy and ice",
  "Night state 21": "Heavy rain and cold",
  "Night state 22": "Heavy rain and deep freeze",
  "Night state 23": "Heavy rain and harmful cold",
  "Night state 24": "Sleet",
  "Night state 25": "Very heavy rain and cold",
  "Night state 26": "Very heavy rain and deep freeze",
  "Night state 27": "Very heavy rain and harmful cold",
  "Night state 28": "Sleet",
  updatedAt: "Update: {0}",
  "Enter location": "Enter location {0}",

  //smart gen
  "Today's weather summary": "Today's weather summary",
  "Read more": "Read more",
  "Show less": "Show less",
  "Rain and Lightning map": "Rain and Lightning map in <1>{{location}}</1>",
};

export default locale;
