import React, {useState, useCallback} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Languages} from "app/values/settings";
import {useTranslation} from "react-i18next";
import {Button, Menu, MenuItem, useMediaQuery} from "@material-ui/core";
import en from "assets/logo/us-flag.svg";
import en_inactive from "assets/logo/us-inactive.svg";
import vn from "assets/logo/vn-flag.svg";
import vn_inactive from "assets/logo/vn-inactive.svg";
import global from "assets/logo/global.svg";
import arrow from "assets/logo/arrowdown.svg";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage} from "../../store/actions/settings.action";

const useStyles = makeStyles({
    menu: {
        marginTop: 15,
    },
    button: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 2,
        background: "#503B8F",
        padding: "5px 10px",
        borderRadius: 4,
        color: "white",
        fontFamily: "Quicksand",
        fontSize: 16,
        fontWeight: 700,
        border: "1px solid #503B8F",
        "&:hover": {
            color: "#ffffff",
            background: "#410581",
            border: "1px solid #410581",
        },
    },
    flag: {
        marginRight: 10,
    },
    mobileButton:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px 6px",
    },
    containerMobile: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 20,
    },
});

const LanguageDialog = ({onChange}) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState(null);
    const currentLanguage = useSelector(({settings}) => settings.language);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation("common");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setLanguageVI = useCallback(() => {
        dispatch(setLanguage(Languages.vi));
        onChange(Languages.vi);
        handleClose();
    }, [dispatch, onChange]);

    const setLanguageEN = useCallback(() => {
        dispatch(setLanguage(Languages.en));
        onChange(Languages.en);
        handleClose();
    }, [dispatch, onChange]);

    const getFlag = (language) => {
        if (language === Languages.en) {
            return currentLanguage === Languages.en ? en : en_inactive;
        } else if (language === Languages.vi) {
            return currentLanguage === Languages.vi ? vn : vn_inactive;
        }
    };

    return (
        <>
            {!isMobile ? (
                <>
                    <Button
                        onClick={handleClick}
                        color="primary"
                        className={classes.button}
                    >
                        <img src={global} alt="global" style={{marginRight: 5}}/>
                        <span style={{marginRight: 5}}>{currentLanguage === Languages.vi ? "VN" : "EN"}</span>
                        <img src={arrow} alt="arrow"/>
                    </Button>
                    <Menu
                        className={classes.menu}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        getContentAnchorEl={null}
                    >
                        <MenuItem onClick={setLanguageVI}>
                            <img src={getFlag(Languages.vi)} alt="flag" className={classes.flag}/>
                            {t("Vietnamese")}
                        </MenuItem>
                        <MenuItem onClick={setLanguageEN}>
                            <img src={getFlag(Languages.en)} alt="flag" className={classes.flag}/>
                            {t("English")}
                        </MenuItem>
                    </Menu>
                </>
            ) : (<>
                <div className={classes.containerMobile}>
                    <div
                        onClick={setLanguageVI}
                        className={classes.mobileButton}
                    >
                        <img src={getFlag(Languages.vi)} alt="flag" width={32} height={32}/>
                    </div>
                    <div
                        onClick={setLanguageEN}
                        color="primary"
                        className={classes.mobileButton}
                    >
                        <img src={getFlag(Languages.en)} alt="flag" width={32} height={32}/>
                    </div>
                </div>
            </>)}
        </>
    );
};

LanguageDialog.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default LanguageDialog;