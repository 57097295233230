// import _ from "lodash";
import React from "react"
import Clouds from "../../assets/img/career/clouds.svg";
import Humidity from "../../assets/img/career/humidity.png";
import Landslide from "../../assets/img/career/landslide.svg";
import Nuclear from "../../assets/img/career/nuclear.svg";
import rainStrong from "../../assets/img/career/rainStrong.svg";
import temperatureHigh from "../../assets/img/career/temperature_high.svg";
import temperatureLow from "../../assets/img/career/temperature_low.svg";
import Thunder from "../../assets/img/career/thunder.svg";
import wavesBig from "../../assets/img/career/wavesBig.svg";
import windStrong from "../../assets/img/career/wind_strong.svg";
import badWeather from "../../assets/img/career/bad_weather.svg";
import hail from "../../assets/img/career/hail.svg";
import fog from "../../assets/img/career/fog.svg";
import tornado from "../../assets/img/career/tornado.svg";
import humidityLow from "../../assets/img/career/humidity_low.png";
import slugify from "slugify";

//anh hien thi giap phap thoi tiet
import hangKhongDetail from 'assets/img/weather_update/solution/detail-content/hangKhong.png';
import DauKhi1 from 'assets/img/weather_update/solution/detail-content/dauKhi.png';
import khaikhoangDetail from "assets/img/weather_update/solution/detail-content/khaiKhoang.png";
import dienGioDetail from "assets/img/weather_update/solution/detail-content/dienGio.png";
import thoiTrangDetail from "assets/img/weather_update/solution/detail-content/thoiTrang.png";
import duLichDetail from "assets/img/weather_update/solution/detail-content/duLich.png";
import duBaoDetail from "assets/img/weather_update/solution/detail-content/duBao.png";
import apiDetail from "assets/img/weather_update/solution/detail-content/api.png";

import ImageStep1 from "assets/img/weather_update/product/weatherpro/content-step1.jpg";
import ImageStep2 from "assets/img/weather_update/product/weatherpro/content-step2.jpg";
import ImageStep3 from "assets/img/weather_update/product/weatherpro/content-step3.jpg";
import ImageStep4 from "assets/img/weather_update/product/weatherpro/content-step4.png";
import StepSolution1 from "assets/img/weather_update/solution/thuong-mai/step-solution1.svg"
import StepSolution2 from "assets/img/weather_update/solution/thuong-mai/step-solution2.svg"
import StepSolution3 from "assets/img/weather_update/solution/thuong-mai/step-solution3.svg"
import StepSolution4 from "assets/img/weather_update/solution/thuong-mai/step-solution4.svg"
import StepSolution5 from "assets/img/weather_update/solution/thuong-mai/step-solution5.svg"
import BenefitCareerSpeaker from "assets/img/weather_update/solution/careers/loudspeaker.svg"
import BenefitCareerCalender from "assets/img/weather_update/solution/careers/calendar.svg"
import BenefitCareerNagtiveCricle from "assets/img/weather_update/solution/careers/negative-cricle.svg"
import BenefitCareerDevelop from "assets/img/weather_update/solution/careers/develop.svg"
import BenefitCareerProtect from "assets/img/weather_update/solution/careers/protect.svg"
import BenefitCareerShopping from "assets/img/weather_update/solution/careers/shopping.svg"
import BenefitCareerBigWave from "assets/img/weather_update/solution/careers/big-wave.svg"
import BenefitCareerRainSolution from "assets/img/weather_update/solution/careers/rain-solution.svg"
import BenefitCareerWind from "assets/img/weather_update/solution/careers/wind.svg"
import BenefitCareerTemperature from "assets/img/weather_update/solution/careers/temperature-solution.svg"
import BenefitCareerThunder from "assets/img/weather_update/solution/careers/thunder-solution.svg"
import DienGioBanner from "assets/img/weather_update/solution/careers/banner-dien-gio.png"
import KhaiKhoangBanner from "assets/img/weather_update/solution/careers/banner-khai-khoang.png"
import DauKhiBanner from "assets/img/weather_update/home/daukhi_banner.webp"
import DuLichBanner from "assets/img/weather_update/solution/careers/banner-du-lich.jpg"
import VanTaiBanner from "assets/img/weather_update/solution/careers/banner-van-tai.jpg"
import DienTuBanner from "assets/img/weather_update/solution/careers/banner-dien-tu.jpg"
import ThuongMaiBanner from "assets/img/weather_update/solution/careers/banner-thuong-mai.jpg"
import ThoiTrangBanner from "assets/img/weather_update/solution/careers/banner-thoi-trang.jpg"

import ContentThuongMai1 from "assets/img/weather_update/solution/careers/content-thuong-mai-1.jpg"
import ContentThuongMai2 from "assets/img/weather_update/solution/thuong-mai/content-thuong-mai-2.jpg"
import ContentThuongMai3 from "assets/img/weather_update/solution/thuong-mai/content-thuong-mai-3.jpg"
import ContentSuKien1 from "assets/img/weather_update/solution/careers/content-su-kien-1.png"
import ContentSuKien2 from "assets/img/weather_update/solution/careers/content-su-kien-2.png"
import ContentDienTu1 from "assets/img/weather_update/solution/careers/content-dien-tu-1.jpg"
import ContentVanTai1 from "assets/img/weather_update/solution/careers/content-taxi-1.jpg"
import ContentDienGio1 from "assets/img/weather_update/solution/careers/content-dien-gio-1.jpg"
import ContentKhaiKhoang1 from "assets/img/weather_update/solution/careers/content-khai-khoang-1.jpg"
import ContentThoiTrang1 from "assets/img/weather_update/solution/careers/content-thoi-trang-1.jpg"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PowerChina from "assets/img/weather_update/home/power china 1.png"
import SigMa from "assets/img/weather_update/home/sigma.png"
import TrungNam from "assets/img/weather_update/home/trungnamgroup.png"
import SermSang from "assets/img/weather_update/solution/careers/sermsang-solution.png"
import ThanHaTu from "assets/img/weather_update/home/than ha tu 1.png"
import ThanVangDanh from "assets/img/weather_update/home/than vang danh 1.png"
import {isMobile} from "react-device-detect";
import vtvFileDataVerify from "../../assets/xlsx/(PQ) Verification Jul_Aug_Sep_2023.xlsx"
import windElectricDataVerify from "../../assets/xlsx/(TV) Verification Jul_Aug_2023.xlsx"
import iconTempReport from "../../assets/img/weather_update/solution/weather-report/temperature_icon.svg";
import iconWindSpeed from "../../assets/img/weather_update/solution/weather-report/wind.svg";
import iconDirWind from "../../assets/img/weather_update/solution/weather-report/dir_wind.svg";
import iconWindGale from "../../assets/img/weather_update/solution/weather-report/windGust.svg";
import iconPresure from "../../assets/img/weather_update/solution/weather-report/pressure.svg";
import iconWaveSwell from "../../assets/img/weather_update/solution/weather-report/swellWave.svg";
import iconWaveSig from "../../assets/img/weather_update/solution/weather-report/sigWave.svg";
import iconWaveMax from "../../assets/img/weather_update/solution/weather-report/maxWave.svg";
import reportDaiHungSeptember from "assets/img/weather_update/forecast/September.2024 Dai Hung - Block 05.1a.pdf";
import reportDaiHungJuly from "assets/img/weather_update/forecast/July.2024 Dai Hung - Block 05.1a.pdf";
import reportDaiHungAugust from "assets/img/weather_update/forecast/August.2024 Dai Hung - Block 05.1a.pdf";
import reportDaiHungComprehensive from "assets/img/weather_update/forecast/Comprehensive Quality Assessment Report Jul.2024 Dai Hung - Block 05.1a.pdf";


class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === "undefined") {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      // eslint-disable-next-line func-names
      (fn) => {
        fn.apply(this, args);
      }
    );
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

export class Utils {
  static EventEmitter = EventEmitter;

  static hasPermission(authArr, userRole) {
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
      // console.info("auth is null || undefined:", authArr);
      return true;
    }
    if (authArr.length === 0) {
      /**
       * if auth array is empty means,
       * allow only user role is guest (null or empty[])
       */
      // console.info("auth is empty[]:", authArr);
      return !userRole || userRole.length === 0;
    }
    /**
     * Check if user has grants
     */
    // console.info("auth arr:", authArr);
    /*
            Check if user role is array,
            */
    if (userRole && Array.isArray(userRole)) {
      return authArr.some((r) => userRole.indexOf(r) >= 0);
    }

    /*
            Check if user role is string,
            */
    return authArr.includes(userRole);
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map((route) => {
      let auth =
        config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = { ...config.settings, ...route.settings };

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static normURL(title) {
    let normUrl = title
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/gu, "")
      .replace(/đ/gu, "d");

    normUrl = normUrl.split(/[[\]()., /\\\-_'"]+/u).join("-");

    return normUrl;
  }

  static getLocation(address) {
    let district;
    let province;
    const displayName = [];
    for (const addr of address.address_components) {
      if (addr.types.includes("locality") && !district) {
        district = addr.short_name;
        displayName.push(addr.short_name);
      }
      if (addr.types.includes("route")) {
        displayName.push(addr.short_name);
      }
      if (addr.types.includes("neighborhood")) {
        displayName.push(addr.short_name);
      }
      if (addr.types.includes("sublocality_level_1")) {
        displayName.push(addr.short_name);
      }
      if (addr.types.includes("administrative_area_level_2")) {
        district = addr.short_name;
        displayName.push(addr.short_name);
      }

      if (addr.types.includes("administrative_area_level_1")) {
        province = addr.short_name;
        displayName.push(addr.short_name);
      }
    }

    const tempName = [];
    if (district) tempName.push(district);
    if (province) tempName.push(province);

    return {
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      district: district ? this.normURL(tempName.join(", ")) : null,
      province: province ? this.normURL(province) : null,
      displayName: displayName.join(", "),
      // displayNameMain: nameDisplayMain
    };
  }

  static getMapboxLocation(data) {
    const arr = data.place_name
      .split(",")
      .map((e) => e.trim())
      .filter((e) => e !== "Việt Nam");

    if (!arr.length) return null;

    const province = arr[arr.length - 1];
    const district = arr.length > 1 ? arr[arr.length - 2] : null;
    const displayName = [];
    if (district) displayName.push(district);
    if (province) displayName.push(province);

    return {
      lat: data.center[1],
      lng: data.center[0],
      district: district ? this.normURL(displayName.join(", ")) : null,
      province: province ? this.normURL(province) : null,
      displayName: displayName.join(", "),
    };
  }

  static debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  static toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  static distance(lat1, lng1, lat2, lng2) {
    // kilometers

    //Converting to radians
    const rlat1 = this.toRadians(lat1);
    const rlat2 = this.toRadians(lat2);

    const rlng1 = this.toRadians(lng1);
    const rlng2 = this.toRadians(lng2);

    const difflat = rlat2 - rlat1;
    const difflong = rlng2 - rlng1;

    const t =
      Math.pow(Math.sin(difflat / 2), 2) +
      Math.cos(rlat1) * Math.cos(rlat2) * Math.pow(Math.sin(difflong / 2), 2);

    return 6378.137 * (2 * Math.asin(Math.sqrt(t)));
  }

  static getStationCodeAmo(lat, lng) {
    if (lat === undefined || lng === undefined) {
      return null;
    }
    let j = Math.round((lat - 8.5) / 0.03006012024048096);
    let i = Math.round((lng - 101.5) / 0.030100334448160536);
    return Number(j * 300.0 + i);
  }

  static getStepContent(step) {
    const dataStep = {
          title:null,
          content1:null,
          subTitle:null,
          listContent:[],
          note:null,
          urlImage:null
    };
    switch (step) {
      case 0:
            dataStep.title="Hợp tác, trao đổi thông tin";
            dataStep.content1="Hai bên cùng có mong muốn cải thiện hiệu quả công việc dựa trên các dự báo thời tiết tin cậy.";
            dataStep.subTitle=null;
            dataStep.listContent=["Thống nhất chủ trương và mong muốn hợp tác, cử đầu mối đại diện làm việc với Weatherplus","Thống nhất quy trình làm việc và kế hoạch làm việc"];
            dataStep.note=null;
            dataStep.urlImage=ImageStep1;
            break;
      case 1:
            dataStep.title="Xác định vấn đề cốt lõi cần giải quyết";
            dataStep.content1=null;
            dataStep.subTitle=null;
            dataStep.listContent=["Đội ngũ chuyên gia hai bên cùng làm việc để tìm ra các vấn đề cốt lõi trong công việc có thể ứng dụng thông tin thời tiết để cải tiến hiệu suất.","Danh sách các vấn đề được nêu ra đầy đủ, sau đó được sàng lọc và chọn lựa cẩn thận.","Đựa trên vấn đề được chọn lọc ưu tiên sẽ chuyển sang giai đoạn phân tích."];
            dataStep.note="*** Trong trường hợp gặp vấn đề mà Weatherplus đã có kinh nghiệm đối mặt, đội ngũ chuyên gia sẽ đưa vấn đề trước để hai bên cùng trao đổi";
            dataStep.urlImage=ImageStep2;
            break;
      case 2:
            dataStep.title="Thiết kế sản phẩm, giải pháp";
            dataStep.content1="Chuyên gia khí tượng của Weatherplus với bề dày kinh nghiệm và khả năng sáng tạo sẽ đưa ra các giải pháp để xử lý vấn đề.";
            dataStep.subTitle="Các giải pháp có thể là:";
            dataStep.listContent=["Một mẫu báo cáo được thiết kế riêng","Các mục thông tin dự báo, thông tin phân tích","Tần suất cập nhật phù hợp","Website, ứng dụng di động","Các kênh cảnh báo, truyền thông tin phù hợp"];
            dataStep.note="Các giải pháp có thể sẽ được thử nghiệm theo sự thống nhất của hai bên";
            dataStep.urlImage=ImageStep3;
            break;
      case 3:
            dataStep.title="Phục vụ và cải tiến liên tục";
            dataStep.content1="Sản phẩm, giải pháp được thiết kế và đưa vào sử dụng thử nghiệm và có được hiệu quả, hình thức hợp tác sẽ chuyển sang giai đoạn phục vụ.";
            dataStep.subTitle=null;
            dataStep.listContent=["Weatherplus sẽ huy động liên tục cung cấp thông tin và duy trì sản phẩm cho đối tác.","Nếu phát sinh vấn đề và cần phải tiếp tục cải tiến, hai bên sẽ tiếp tục trao đổi hoàn thiện để mối quan hệ hợp tác đạt giá trị cao nhất"];
            dataStep.note=null;
            dataStep.urlImage=ImageStep4;
        break;
      default:
        break;
    }
    return dataStep;
  }
  static getImageTypicalCustomerSolution (career) {
      const data = {
          detailData :[]
      }
      switch (career) {
          case "dien-gio":
              data.detailData=[
                  {
                      urlImage:PowerChina,
                      width:"114px"
                  },
                  {
                      urlImage:SigMa,
                      width:"100px"
                  },
                  {
                      urlImage:TrungNam,
                      width:"77px"
                  },
                  {
                      urlImage:SermSang,
                      width:"106px"
                  }
              ];
              break;
          case "khai-khoang":
              data.detailData=[
                  {
                      urlImage:ThanHaTu,
                      width:"105px"
                  },
                  {
                      urlImage:ThanVangDanh,
                      width:"120px"
                  },
              ];
              break;
          case "dau-khi":
              data.detailData=[
                  // {
                  //     urlImage:KajmaVietNam,
                  //     width:"70px"
                  // },
                  // {
                  //     urlImage:Vsip,
                  //     width:"90px"
                  // },
                  // {
                  //     urlImage:Cienco,
                  //     width:"80px"
                  // },
              ];
              break;
          default:
              break;
      }
      return data
  }
  static getDataMenuRight (value) {
      const data = {
          detailData: []
      }
      switch (value) {
          case "product":
              data.detailData=[
                  {
                      url:"/san-pham-giai-phap",
                      content:"Cảnh báo"
                  },
                  {
                      url:"/du-bao",
                      content:"Dự báo thời tiết, khí hậu"
                  },
                  {
                      url:"/weather-pro",
                      content:"Weather Pro - Bản tin chuyên gia theo yêu cầu"
                  },
                  {
                      url:"/api-thoi-tiet",
                      content:"API - Tích hợp dữ liệu theo yêu cầu"
                  },
                  {
                      url:"/metplus",
                      content:"Metplus - Nền tảng khuyến nghị hành động"
                  },
                  // {
                  //     url:"/data-suites",
                  //     content:"Data Suites - Dữ liệu quá khứ"
                  // }
              ];
              break;
          case "solution":
              data.detailData=[
                  {
                      url:"/thoi-tiet-theo-nganh/dien-gio",
                      content:"Xây dựng điện gió ngoài khơi"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/dau-khi",
                      content:"Thăm dò và khai thác dầu khí"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/khai-khoang",
                      content:"Khai thác than & khoáng sản"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/du-lich-su-kien",
                      content:"Du lịch & sự kiện"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/van-tai",
                      content:"Vận tải & taxi"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/dien-tu-dien-lanh",
                      content:"Điện tử điện lạnh"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/thuong-mai-ban-le",
                      content:"Thương mại &  bán lẻ"
                  },
                  {
                      url:"/thoi-tiet-theo-nganh/may-mac-thoi-trang",
                      content:"May mặc & thời trang"
                  }
              ];
              break;
          default:
              break;
      }
      return data
  }
  static getTitleAndBannerSolutionCareer (career) {
    const data = {
      detailData:{}
    }
    switch (career) {
      case "dien-gio":
        data.detailData={
          title:"Giải pháp dự báo cho xây dựng, bảo trì điện gió ngoài khơi",
          urlImage:DienGioBanner
        }
        break;
      case "khai-khoang":
        data.detailData={
          title:"Giải pháp dự báo cho ngành khai thác khoáng sản",
          urlImage:KhaiKhoangBanner
        }
        break;
      case "dau-khi":
        data.detailData={
            title:<span>Dự báo phục vụ thăm dò<br/> và khai thác dầu khí</span>,
          urlImage:DauKhiBanner
        }
        break;
      case "du-lich-su-kien":
        data.detailData={
          title:"Giải pháp thời tiết cho ngành Du lịch và Sự kiện",
          urlImage:DuLichBanner
        }
        break;
      case "van-tai":
        data.detailData={
          title:"Di chuyển an toàn với mọi điều kiện thời tiết",
          urlImage:VanTaiBanner
        }
        break;
      case "dien-tu-dien-lanh":
        data.detailData={
          title:"Kinh doanh tối ưu hơn với ngành điện tử, điện lạnh",
          urlImage:DienTuBanner
        }
        break;
      case "thuong-mai-ban-le":
        data.detailData={
          title:"Tăng doanh thu, tối ưu chuỗi cung ứng cho ngành bán lẻ",
          urlImage:ThuongMaiBanner
        }
        break;
      case "may-mac-thoi-trang":
        data.detailData={
          title:"Giải pháp thời tiết cho ngành may mặc & thời trang",
          urlImage:ThoiTrangBanner
        }
        break;
      default:
        break;
    }
    return data
  }

  static getBenefitForDetailCareer (career) {
    const data = {
      firstContent:{},
      bonusContent:{},
      benefitCareer:[]
    }
    switch (career) {
      case "may-mac-thoi-trang":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"titleMain"}>
                    <span>Thực tế trong ngành may mặc & thời trang là:</span>
                  </div>
                  <ul className={"textMain"}>
                    <li>Chưa tận dụng thông tin dự báo thời tiết để điều phối hàng hóa, ra các chủng loại hàng phù hợp cho các loại hình thời tiết để tối đa hóa doanh thu.</li>
                    <li>Chưa triển khai và sử dụng kỹ thuật marketing theo thời tiết (weather-based marketing) nhằm tăng hiệu quả của các chiến dịch marketing, còn rất nhiều tiềm năng có thể khai thác tối ưu hóa.</li>
                    <li>Chưa sử dụng thông tin dự báo dài hạn để lập kế hoạch sản xuất. Chưa sử dụng phân tích tương quan nhu cầu và thời tiết để dự báo nhu cầu, tối ưu bán hàng</li>
                    <li>Hiệu quả bán hàng ra sao nếu kế hoạch ra mắt sản phẩm áo khoác lại vào đúng một đợt thời tiết nắng ấm? Hoặc kế hoạch ra mắt mẫu sơ mi mới lại vào đúng một đợt rét kỷ lục?</li>
                    <li>Để tiếp cận khách hàng được tốt tại các showroom, việc bố trí các sản phẩm cần sắp xếp tối ưu ra sao dựa trên trạng thái thời tiết?</li>
                    <li>Làm sao để đưa ra các thông điệp truyền thông đúng lúc, đúng chỗ, đúng thời điểm, đúng tâm trạng, cảm giác của khách hàng để đạt hiệu quả bán hàng cao nhất?</li>
                  </ul>
                  <div className={"imageBonus"}>
                    <img src={ContentThoiTrang1} alt={"ContentThoiTrang1"}  width={!isMobile?"auto":"100%"}/>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div></div>
            )
          }
        };
        data.benefitCareer=[
          // {
          //   icon: BenefitCareerSpeaker,
          //   title: "Tối ưu marketing",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Thời tiết điều chỉnh các chiến dịch tiếp thị của bạn trong giờ, ngày hoặc tuần tiếp theo và điều chỉnh các quy trình của bạn cho phù hợp.</span>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon: BenefitCareerCalender,
          //   title: "Lên kế hoạch",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Phân tích dữ liệu thời tiết lịch sử cho mọi cửa hàng để tối ưu hóa doanh số bán hàng có liên quan đến thời tiết.</span>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon: BenefitCareerNagtiveCricle,
          //   title: "Giảm lãng phí",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Sử dụng thông tin chi tiết về thời tiết cụ thể của cửa hàng để giảm thiểu hàng hóa hư hỏng và/hoặc hết hạn sử dụng với mức tồn kho phù hợp với thời tiết và các hoạt động tiếp thị.</span>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon: BenefitCareerDevelop,
          //   title: "Tối đa hóa thời gian mở cửa hàng",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Giảm việc đóng cửa hàng không cần thiết do điều kiện thời tiết khắc nghiệt với điều kiện làm việc & cảnh báo thời tiết</span>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon: BenefitCareerProtect,
          //   title: "Tuân thủ Sức khỏe & An toàn",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Giảm nguy cơ xảy ra tai nạn và giữ an toàn cho nhân viên cũng như khách hàng của bạn. Nhớ lại dữ liệu thời tiết sau sự cố để báo cáo.</span>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon: BenefitCareerShopping,
          //   title: "Dự báo nhu cầu",
          //   content: () => {
          //     return (
          //           <div className={"textContent"}>
          //             <span>Dự đoán những thay đổi trong chuỗi cung ứng của bạn và hành động phù hợp trước khi xảy ra sự cố.</span>
          //           </div>
          //     )
          //   }
          // },
        ]
        break;
      case "dien-tu-dien-lanh":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"textMain"}>
                    <span>Doanh thu của sản phẩm làm mát, sưởi ấm phụ thuộc rất nhiều vào yếu tố thời tiết.</span>
                  </div>
                  <div className={"textMain"}>
                    <span>Tại thị trường Mỹ</span>
                  </div>
                  <ul className={"textMain"}>
                    <li>Nếu nhiệt độ trung bình tuần tăng lên 1 độ F (tương đương tăng 1.8 độ C) thì doanh thu của điều hòa tăng trung bình 24%</li>
                    <li>Nếu nhiệt độ trung bình tuần giảm xuống 1 độ F (tương đương giảm 1.8 độ C) thì doanh thu của máy sưởi tăng trung bình 15%</li>
                  </ul>
                  <div className={"textMain"}>
                    <span style={{fontStyle:"italic"}}>Nguồn: “Profit of one Degree” bởi Wxtrends.com</span>
                  </div>
                  <div className={"textMain"}>
                    <span>Tại thị trường Việt Nam:</span>
                  </div>
                    <div className={"textMain"}>
                        <span>Khi thời tiết có các đợt thay đổi bất thường, hiện tượng “cháy hàng” điện lạnh hoặc điện sưởi thường xuyên diễn ra</span>
                    </div>
                  <div className={"imageBonus"}><img src={ContentDienTu1} alt={"ContentDienTu1"} width={!isMobile?"auto":"100%"}/></div>
                </div>
            )
          },
        };
        data.bonusContent={
          content: () => {
            return(
                <div>
                  <div className={"titleBonusElectronic"}>
                    <span>Sẽ ra sao nếu các đơn vị sản xuất, kinh doanh điện tử gia dụng nắm bắt được thông tin thời tiết chuyên sâu và chính xác?</span>
                  </div>
                  <div className={"subTitleBonusElectronic"}>
                    <span>Lên kế hoạch nhập khẩu, sản xuất</span>
                  </div>
                  <ul className={"textBonus"} style={{paddingLeft: 25}}>
                    <li>Biến đổi khí hậu, thời tiết bất thường, điều gì xảy ra khi chúng ta chỉ nhập khẩu (hoặc sản xuất) theo kinh nghiệm mùa vụ? Nếu mùa hè diễn ra dài hơn và nóng hơn thì ta có đủ hàng để bán không? Nếu mùa hè mát và ngắn thì chi phí lưu kho hàng tồn sẽ tăng bao nhiêu?</li>
                    <li>Dựa vào dự báo thời tiết, khí hậu 3-6 tháng, dự báo thời điểm bắt đầu mùa hè, dự báo số lượng đợt nắng nóng, dự báo cường độ nắng nóng, thời điểm kết thúc mùa hè… có giúp tính toán dự báo được nhu cầu thị trường không?</li>
                  </ul>
                  <div className={"subTitleBonusElectronic"}>
                    <span>Điều phối hàng hóa tối ưu</span>
                  </div>
                  <ul className={"textBonus"} style={{paddingLeft: 25}}>
                    <li>Với hệ thống kho bãi, đại lý bán hàng trải rộng toàn quốc, việc điều phối hàng hóa giữa các vùng miền sao cho tối ưu để giảm chi phí vận chuyển và lưu kho?</li>
                    <li>Khi nắm được các thông tin thời tiết, khí hậu của từng vùng cụ thể thì thông tin này có giúp ích được gì cho việc điều tiết hàng hóa giữa các vùng miền? Việc dự báo được nhu cầu của các vùng dựa trên dự báo thời tiết có làm việc điều phối hàng hóa chủ động hơn không?</li>
                  </ul>
                  <div className={"subTitleBonusElectronic"}>
                    <span>Tăng hiệu quả các chiến dịch marketing</span>
                  </div>
                  <ul className={"textBonus"} style={{paddingLeft: 25}}>
                    <li>Đẩy mạnh chiến dịch marketing trong điều kiện thời tiết mát mẻ có hiệu quả? Khi nhu cầu thị trường tăng cao, có nên đẩy mạnh marketing hơn để tiếp cận nhiều khách hàng để chiếm thêm thị phần?</li>
                    <li>Khi biết trước các sự kiện thời tiết có thể khơi gợi nhu cầu của khách hàng và đưa ra các khuyễn mãi đúng lúc, đúng chỗ kích thích khách hàng đưa ra quyết định?</li>
                  </ul>
                </div>
            )
          }
        };
        data.benefitCareer=[]
            break;
      case "van-tai":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"textMain"}>
                    <span>Thời tiết là nhân tố chính trong tất cả các hình thức vận tải đường bộ, dù là tư nhân, công cộng hay vận chuyển hàng hóa, các yếu tố an toàn và kinh tế đều bị ảnh hưởng trực tiếp bởi thời tiết.</span>
                  </div>
                  <div className={"titleMain"}>
                    <span>Những thách thức về thời tiết của ngành vận tải & taxi</span>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div className={"imageBonus"}>
                  <img src={ContentVanTai1} alt={"ContentVanTai1"} width={!isMobile?"auto":"100%"}/>
                </div>
            )
          }
        };
        data.benefitCareer=[
          {
            icon:BenefitCareerRainSolution,
            title:"Mưa lớn, lũ lụt",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Ảnh hưởng tầm nhìn của người lái xe, giảm ma sát với mặt đường, giảm tốc độ giao thông, tắc nghẽn giao thông, tác động đến kế hoạch bảo trì/xây dựng</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerWind,
            title:"Sương mù, gió mạnh",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Tác động tầm nhìn người lái xe và làm giảm mức độ lưu thông.</span>
                      <br/>
                      <span>Gió quá mạnh trên đường cao tốc có thể gây lật xe.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerTemperature,
            title:"Nhiệt độ quá cao",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Gây mệt mỏi cho tài xế.</span>
                      <br/>
                      <span>Nhiệt độ quá cao gây tác động đến mặt đường thay đổi ma sát, ảnh hưởng hiệu suất của phương tiện.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerThunder,
            title:"Dông sét",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Ảnh hưởng đến an toàn của lái xe và phương tiện. Giảm tầm nhìn, giảm lưu lượng lưu thông</span>
                    </div>
              )
            }
          },
        ]
        break;
      case "du-lich-su-kien":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"textMain"}>
                    <span> Mỗi năm có hàng chục triệu du khách đến thăm Việt Nam, chưa kể vài chục triệu du khách nội địa. Tất cả những chuyến đi, những trải nghiệm của khách du lịch đều bị ảnh hưởng bởi thời tiết. Thời tiết thuận lợi sẽ làm nên những chuyến đi tuyệt vời và những sự kiện đáng nhớ.</span>
                  </div>
                    <div className={"textMain"}>
                        <span>Weatherplus cung cấp dịch vụ thời tiết cho các công ty lữ hành, công ty du thuyền, công ty tổ chức sự kiện và những điểm du lịch để công tác tổ chức tốt được làm tốt hơn, có phương án phòng trừ rủi ro nhằm đưa những điều tuyệt vời nhất đến với du khách.</span>
                    </div>
                  <div className={"titleMain"}>
                    <span>Những thách thức về thời tiết của ngành Du lịch & sự kiện</span>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div className={'imageBonus'}>
                  <img src={ContentSuKien1} alt={"ContentSuKien1"} width={!isMobile?"auto":"100%"}/>
                </div>
            )
          }
        };
        data.benefitCareer=[
          {
            icon:BenefitCareerWind,
            title:"Gió mạnh",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <ul>
                        <li>Gây nguy hiểm cho các chuyến tàu tham quan các vùng biển hoặc cáp treo</li>
                        <li>Không nắm được dự báo gió mạnh, sẽ dẫn đến tàu, thuyền ở các khu vực biển bị cấm, du khách có thể không thực hiện được chuyến đi, kế hoạch bị hủy bỏ, hoặc ra đảo nhưng bị kẹt lại</li>
                      </ul>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerRainSolution,
            title:"Mưa lớn, lũ lụt",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <ul>
                        <li>Gây gián đoạn hoặc hủy bỏ các chuyến tham quan du lịch</li>
                        <li>Có thể ảnh hưởng đến tính mạng của các đoàn khách nằm trong vùng mưa bão</li>
                      </ul>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerThunder,
            title:"Dông, sét",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Gây nguy hiểm cho các hoạt động trên cao như cáp treo.</span>
                      <br/>
                      <span>Cảnh báo dông sét trước giúp khách du lịch điều chỉnh lịch trình để tối ưu hơn, trải nghiệm tốt hơn.</span>
                      <br/>
                      <span>Các sự kiện, du lịch ngoài trời có thể bị hoãn hoặc không được như mong đợi với những cơn dông bất ngờ</span>
                    </div>
              )
            }
          },
        ]
        break;
      case "dau-khi":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"textMain"}>
                    <span>Đây là dịch vụ cung cấp thông tin thời tiết chi tiết và chính xác để hỗ trợ hoạt động của ngành công nghiệp dầu khí. Các thông tin bao gồm: bản tin dự báo thời tiết, cảnh báo về các điều kiện thời tiết khắc nghiệt có thể ảnh hưởng đến hoạt động thăm dò, khai thác, an toàn lao động trên biển và vận chuyển, phân phối dầu mỏ và khí đốt.</span>
                  </div>
                  <div className={"titleMain"}>
                    <span>Nội dung cung cấp</span>
                  </div>
                    <ul className={"textMain"}>
                        <li>Dự báo tại vị trí cụ thể trong Biển Đông (chi tiết từng kinh độ và vĩ độ).</li>
                        <li>Dự báo cho các cảng biển, quần đảo cụ thể và các điểm giao thông chính giữa các cửa biển.</li>
                        <li>Cảnh báo về tình hình thời tiết nguy hiểm trên biển (bão, áp thấp nhiệt đới, gió đông bắc, sóng cao, nước triều cường, sương mù, vv., tất cả các rủi ro hàng hải cần thiết).</li>
                        <li>Các cảnh báo/dự báo chi tiết cho các hoạt động đặc thù trong ngành công nghiệp dầu khí.</li>
                        <li>Dự báo, khuyến nghị đặc biệt cho tàu lớn và nhỏ, ví dụ: tàu chở dầu xuất khẩu.</li>
                        <li>Đề xuất đặc biệt cho vận chuyển hàng hóa, thay ca người qua trực thăng và vận chuyển chuyên gia đến và từ giàn khoan.</li>
                        <li>Tư vấn tuyến đường, dự báo thời tiết thời gian thực để hỗ trợ điều hướng tàu biển.</li>
                        <li>Cảnh báo/dự báo phục vụ lặn để hỗ trợ trong công việc sửa chữa và kết nối cáp.</li>
                        <li>Dự báo nhiệt độ cho các bể lọc dầu.</li>
                        <li>Dự báo dòng chảy biển và triều cường.</li>
                        <li>Bản tin cảnh báo/dự báo chuyên ngành cho các chuyến bay của trực thăng đi và từ các giàn khoan dầu và đất liền.</li>
                        <li>Nghiên cứu khí hậu Metocean.</li>
                        <li>Mô phỏng tác động của sóng và gió lên các loại tàu biển khác nhau (sẽ ra mắt sớm).</li>
                    </ul>
                    <div className={"imageBonus"}>
                        <img src={DauKhi1} alt={"ContentDauKhi1"}  width={!isMobile?"auto":"100%"}/>
                    </div>
                </div>
            )
          },
        };
          data.bonusContent={
              content:() => {
                  return(
                      <div></div>
                  )
              }
          };
        data.benefitCareer=[
          // {
          //   icon:BenefitCareerWind,
          //   title:"Gió mạnh",
          //   content: () => {
          //     return(
          //           <div className={"textContent"}>
          //             <ul>
          //               <li>Gây nguy hiểm khi vận hành cẩu cao, gây rung, va đập, thao tác thiếu chính xác</li>
          //               <li>Tăng rủi ro an toàn lao động khi làm việc ở các khu vực có địa hình hiểm trở như đồi, núi …</li>
          //             </ul>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon:BenefitCareerRainSolution,
          //   title:"Mưa lớn, lũ lụt",
          //   content: () => {
          //     return(
          //           <div className={"textContent"}>
          //             <ul>
          //               <li>Làm hỏng bê tông khi đang đổ mái, móng, dầm</li>
          //               <li>Gây gián đoạn công việc, chậm tiến độ</li>
          //               <li>Trơn trượt, nguy hiểm khi thi công ở trên độ cao</li>
          //               <li>Gây ngập úng công trường, hầm móng</li>
          //             </ul>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon:BenefitCareerThunder,
          //   title:"Dông, sét",
          //   content: () => {
          //     return(
          //           <div className={"textContent"}>
          //             <span>
          //               Những cơn mưa dông sét xuất hiện bất ngờ gây ra mưa, gió mạnh và đặc biệt là tia sét đánh vào cần cẩu  gây mất an toàn thi công nghiêm trọng.
          //             </span>
          //             <ul>
          //               <li>Thiệt hại về người và của</li>
          //               <li>Gián đoạn thi công</li>
          //               <li>Trơn trượt, nguy hiểm khi thi công ở trên độ cao</li>
          //               <li>Đảm bảo quy phạm định vận hành cẩu</li>
          //             </ul>
          //           </div>
          //     )
          //   }
          // },
          // {
          //   icon:BenefitCareerTemperature,
          //   title:"Nhiệt độ quá cao gây ảnh hưởng sức khỏe công nhân",
          //   content: () => {
          //     return(
          //        ""
          //     )
          //   }
          // },
        ]
        break;
      case "khai-khoang":
        data.firstContent = {
          content:() => {
            return(
                <div>
                  <div className={"titleMain"}>
                    <span>Những thách thức về thời tiết của ngành khai thác khoáng sản</span>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div>
                  <div className={"contentBonus"}>
                    <GridContainer spacing={!isMobile?5:12}>
                      <GridItem xs={!isMobile?6:12}>
                        <div className={"coverBonus"}>
                          <div className={"titleBonus"}>
                            <span>Hiện trạng</span>
                          </div>
                          <div className={"textBonus"}>
                            <span>Hiện tại, vẫn còn nhiều đơn vị khai thác than đang sử dụng thông tin dự báo thời tiết miễn phí trên internet, đài, tivi để điều hành khai thác tại hiện trường.</span>
                          </div>
                          <div className={"textBonus"}>
                            <span>Công tác đo mưa tại khai trường đang rất thủ công</span>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={!isMobile?6:12}>
                        <div className={"coverBonus"}>
                          <div className={"titleBonus"}>
                            <span>Bất cập</span>
                          </div>
                          <div className={"textBonus"}>
                            <span>Thông tin dự báo này thiếu chính xác, thường dự báo cho vùng, khu vực nên thường khó khăn để ra quyết định dẫn đến công tác điều hành khai thác có nhiều lãng phí và rủi ro</span>
                          </div>
                          <div className={"textBonus"}>
                            <span>Kết quả đo mưa thiếu độ tin cậy và khó khăn để tổng hợp, phân tích, đánh giá và báo cáo</span>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div className={"imageBonus"}>
                    <img src={ContentKhaiKhoang1} alt={"ContentKhaiKhoang1"} className={"imageContentBonus1"}  width={!isMobile?"auto":"100%"}/>
                  </div>
                </div>
            )
          }
        };
        data.benefitCareer=[
          {
            icon: BenefitCareerRainSolution,
            title:"Mưa lớn, lũ lụt",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <ul>
                        <li>Gây sạt lở đường vận chuyển, vùi lấp thiết bị, cản trở giao thông</li>
                        <li>Tập trung nước nhanh, ngập thiết bị điện</li>
                        <li>Gây gián đoạn ca sản xuất, tổn thất nhân công, xăng dầu</li>
                        <li>Tràn và rò rỉ xuống hầm lò, mưa nhiều ngày rủi ro hình thành túi nước</li>
                      </ul>
                    </div>
              )
            }
          },
          {
            icon: BenefitCareerThunder,
            title:"Dông, sét",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span> Khoan lỗ mìn gặp cơn mưa dông lớn sẽ trôi đất đá lấp lại lỗ khoan.</span>
                      <br/>
                      <span>Khi đặt mìn, nhồi mìn gặp trời dông sét, mưa lớn gây mất an toàn lao động.</span>
                      <br/>
                      <span>Khi xảy ra trời mưa dông, sét thì các thao tác về mìn đều phải tạm dừng</span>
                    </div>
              )
            }
          },
          {
            icon: BenefitCareerWind,
            title:"Gió mạnh",
            content: () => {
              return(
                    <div className={"textContent"}>
                     <ul>
                       <li>Gây nguy hiểm khi vận hành cẩu cao, gây rung, va đập, thao tác thiếu chính xác</li>
                       <li>Tăng rủi ro an toàn lao động khi làm việc ở các khu vực có địa hình hiểm trở như đồi, núi …</li>
                     </ul>
                    </div>
              )
            }
          },
        ]
        break;
      case "dien-gio":
        data.firstContent ={
          content:() => {
            return(
                <div>
                  <div className={"textMain"}>
                    <span>Ngoài những chỉ số về gió, các yếu tố thời tiết khác cũng ảnh hưởng đến quá trình khảo sát, thi công và vận hành điện gió, đặc biệt là điện gió ngoài khơi.</span>
                  </div>
                    <div className={"textMain"}>
                        <span>Trong giai đoạn xây dựng, biển động và gió lớn thường xuyên gây ra sự chậm trễ hoặc gián đoạn trong quá trình vận chuyển và lắp đặt. Không chỉ bị mất đi các chi phí cố định (phí thuê tàu, nhân công theo ngày), các chủ thầu xây dựng còn có thể bị phạt do chậm tiến độ.</span>
                    </div>
                  <div className={"titleMain"}>
                    <span>Những thách thức về thời tiết của ngành khai thác khoáng sản</span>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div className={"imageBonus"}>
                  <img src={ContentDienGio1} alt={"ContentDienGio1"} className={"imageContentBonus1"} width={!isMobile?"auto":"100%"}/>
                </div>
            )
          }
        };
        data.benefitCareer= [
          {
            icon: BenefitCareerWind,
            title:"Gió mạnh",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Gió là một trong những yếu tố quan trọng ảnh hưởng đến thi công, bảo trì điện gió ngoài khơi bởi vì các công việc phải triển khai trên các độ cao.</span>
                      <br/>
                      <span>Các cần cẩu với độ cao lên tới 150m gắn trên các Xà Lan thì gió mạnh sẽ làm rung và mất cân bằng gây đến sự nguy hiểm và thiếu chính xác trong thao tác.</span>
                    </div>
              )
            }
          },
          {
            icon: BenefitCareerBigWave,
            title:"Sóng lớn",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Sóng trên công trường được kết hợp bởi sóng gây ra bởi gió (sóng gió) và sóng truyền từ nơi khác đến (sóng lừng).</span>
                      <br/>
                      <span>Nếu không dự báo được sóng, biển động sẽ làm hệ thống xà lan, cần cẩu không có đủ độ ổn định để triển khai các công việc thi công cũng như bảo trì bảo dưỡng</span>
                    </div>
              )
            }
          },
          {
            icon: BenefitCareerThunder,
            title:"Dông, sét",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Những cơn mưa dông sét xuất hiện bất ngờ gây ra mưa, gió mạnh, sóng cao và đặc biệt là tia sét đánh vào cần cẩu cũng như các cánh quạt gây mất an toàn thi công nghiêm trọng.</span>
                      <br/>
                      <span>Nếu không dự báo được mưa dông sét sẽ làm gián đoạn và gây mất an toàn trong thi công và bảo dưỡng</span>
                    </div>
              )
            }
          },
          {
            icon: BenefitCareerWind,
            title:"Gió mạnh",
            content: () => {
              return(
                    <div className={"textContent"}>
                      <span>Gió là một trong những yếu tố quan trọng ảnh hưởng đến thi công, bảo trì điện gió ngoài khơi bởi vì các công việc phải triển khai trên các độ cao.</span>
                      <br/>
                      <span>Các cần cẩu với độ cao lên tới 150m gắn trên các Xà Lan thì gió mạnh sẽ làm rung và mất cân bằng gây đến sự nguy hiểm và thiếu chính xác trong thao tác.</span>
                    </div>
              )
            }
          },
        ]
        break;
      case "thuong-mai-ban-le":
        data.firstContent = {
          content:() => {
            return(
                <div>
                  <div className={"titleMain"}>
                    <span>Thời tiết trong thương mại & bán lẻ: rủi ro hay cơ hội?</span>
                  </div>
                  <div className={"textMain"}>
                    <span>Thời tiết đóng một vai trò có ảnh hưởng trong lĩnh vực bán lẻ, nó là yếu tố ảnh hưởng lớn thứ hai đến hành vi của người tiêu dùng. Vào những ngày mưa, ô có thể được tìm thấy ở phía trước cửa hàng. Găng tay, mũ và nến bán chạy khi trời lạnh và nước giải khát, kem và quạt bán chạy khi trời nóng. Chuỗi cung ứng bị gián đoạn trong các sự kiện thời tiết khắc nghiệt và thói quen của người mua sắm thay đổi. Các nhà bán lẻ chưa sử dụng thông tin chi tiết về thời tiết đang bỏ lỡ các cơ hội tăng doanh thu và hiệu quả.</span>
                  </div>
                </div>
            )
          },
        };
        data.bonusContent={
          content:() => {
            return(
                <div className={"imageBonus"}>
                  <img src={ContentThuongMai1} alt={"ContentThuongMai1"} width={!isMobile?"auto":"100%"}/>
                </div>
            )
          }
        };
        data.benefitCareer = [
          {
            icon:BenefitCareerSpeaker,
            title:"Tối ưu marketing",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Thời tiết điều chỉnh các chiến dịch tiếp thị của bạn trong giờ, ngày hoặc tuần tiếp theo và điều chỉnh các quy trình của bạn cho phù hợp.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerCalender,
            title:"Lên kế hoạch",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Phân tích dữ liệu thời tiết lịch sử cho mọi cửa hàng để tối ưu hóa doanh số bán hàng có liên quan đến thời tiết.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerNagtiveCricle,
            title:"Giảm lãng phí",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Sử dụng thông tin chi tiết về thời tiết cụ thể của cửa hàng để giảm thiểu hàng hóa hư hỏng và/hoặc hết hạn sử dụng với mức tồn kho phù hợp với thời tiết và các hoạt động tiếp thị.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerDevelop,
            title:"Tối đa hóa thời gian mở cửa hàng",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Giảm việc đóng cửa hàng không cần thiết do điều kiện thời tiết khắc nghiệt với điều kiện làm việc & cảnh báo thời tiết</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerProtect,
            title:"Tuân thủ Sức khỏe & An toàn",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Giảm nguy cơ xảy ra tai nạn và giữ an toàn cho nhân viên cũng như khách hàng của bạn. Nhớ lại dữ liệu thời tiết sau sự cố để báo cáo.</span>
                    </div>
              )
            }
          },
          {
            icon:BenefitCareerShopping,
            title:"Dự báo nhu cầu",
            content:() => {
              return(
                    <div className={"textContent"}>
                      <span>Dự đoán những thay đổi trong chuỗi cung ứng của bạn và hành động phù hợp trước khi xảy ra sự cố.</span>
                    </div>
              )
            }
          },
        ];
        break;
      default:
        break;
    }
    return data
  }
  static getContentStepSolutionForCareer(career) {
    const data = {
      listDataCareer:[],
    };

    switch (career) {
      case "may-mac-thoi-trang":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Dự báo thời tiết  3 - 7 ngày tới tối ưu hoạt động kinh doanh",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo chi tiết cho 3 ngày để tối ưu các hoạt động hàng ngày của chuỗi siêu thị, cửa hàng trên một địa bàn rộng.</li>
                      <li>Các yếu tố dự báo tập trung vào mưa, gió, nhiệt độ và theo yêu cầu.</li>
                      <li>Nhận định 5 ngày tiếp theo giúp nắm được xu hướng thời tiết hạn dài để lên kế hoạch điều phối nguồn lực</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon: StepSolution2,
            title: "Dự báo 30 ngày tối ưu sales, marketing, luân chuyển hàng hóa",
            content: () => {
              return (
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Với bản tin dự báo 30 ngày, độ chi tiết theo từng 5 ngày hoặc theo từng tuần, dự báo các yếu tố ảnh hưởng lớn đến nhu cầu điều hòa của người tiêu dùng là nhiệt độ, mưa, độ ẩm… trong đó quan trọng nhất là nhiệt độ, đưa ra thông tin tổng quan diễn biến thời tiết trong tháng giúp dự đoán nhu cầu thị trường tại địa phương, từ đó có kế hoạch cung cấp, luân chuyển hàng hóa.
                      </li>
                      <li>Với các chuỗi đại lý lớn tại nhiều địa phương, việc theo dõi dự báo 30 ngày tại các khu vực có thể giúp điều phối vận chuyển điều hòa từ khu vực nhu cầu ít sang khu vực nhu cầu nhiều.</li>
                      <li>Bản tin được cập nhật liên tục, gối đầu giúp thông tin được tin cậy nhất
                      </li>
                    </ul>
                    <div><img src={ContentThuongMai2} alt={"ContentThuongMai2"} width={"100%"}/></div>
                  </div>
              )
            }
          },
          {
            icon: StepSolution3,
            title: "Dự báo 3 - 6 tháng tối ưu kế hoạch nhập hàng, kho bãi",
            content: () => {
              return (
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo 3 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Thời điểm bắt đầu mùa hè, các đợt nắng nóng đầu tiên, dự báo số lượng các đợt nắng nóng</li>
                          <li>Dự báo độ dài của đợt nắng nóng</li>
                          <li>Các hiện tượng dị thường, cực đoan có thể xảy ra trong 10 ngày, 30 ngày</li>
                          <li>Thông tin phân tích, tư vấn chuyên gia khi cần ra quyết định</li>
                        </ul>
                      </li>
                      <li>Dự báo 6 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Xu hướng nhiệt độ từng tháng, các tháng tới nóng hơn hay lạnh hơn so với thông thường nhiều năm</li>
                          <li>Các hiện tượng thời tiết dị thường có khả năng xảy ra từng tháng</li>
                          <li>Xu hướng mùa hè năm nay dài hơn hay ngắn hơn</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Phân tích dữ liệu quá khứ, dự đoán nhu cầu, thị hiếu",
            content : () => {

              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>  Với chuỗi dữ liệu lịch sử lên tới 30 năm với nhiều yếu tố cùng với các chuyên gia phân tích dữ liệu, chúng tôi cung cấp giải pháp phân tích dữ liệu quá khứ với sự phối hợp giữa số liệu bán hàng và số liệu khí tượng để nắm bắt thị hiếu, nhu cầu, từ đó dự đoán cho tương lai</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {

              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>
                        Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091
                      </li>
                      <li>
                        Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết
                      </li>
                    </ul>
                  </div>
              )
            }
          }
        ]
        break;
      case "dien-tu-dien-lanh":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Dự báo 3 - 6 tháng lên kế hoạch sản xuất/ nhập khẩu hàng hóa",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo 3 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Thời điểm bắt đầu mùa hè, các đợt nắng nóng đầu tiên, dự báo số lượng các đợt nắng nóng</li>
                          <li>Dự báo độ dài của đợt nắng nóng</li>
                          <li>Các hiện tượng dị thường, cực đoan có thể xảy ra trong 10 ngày, 30 ngày</li>
                          <li>Thông tin phân tích, tư vấn chuyên gia khi cần ra quyết định</li>
                        </ul>
                      </li>
                      <li>Dự báo 6 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Xu hướng nhiệt độ từng tháng, các tháng tới nóng hơn hay lạnh hơn so với thông thường nhiều năm</li>
                          <li>Các hiện tượng thời tiết dị thường có khả năng xảy ra từng tháng</li>
                          <li>Xu hướng mùa hè năm nay dài hơn hay ngắn hơn</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon: StepSolution2,
            title: "Dự báo 30 ngày tối ưu sales, marketing, luân chuyển hàng hóa",
            content: () => {
              return (
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Với bản tin dự báo 30 ngày, độ chi tiết theo từng 5 ngày hoặc theo từng tuần, dự báo các yếu tố ảnh hưởng lớn đến nhu cầu điều hòa của người tiêu dùng là nhiệt độ, mưa, độ ẩm… trong đó quan trọng nhất là nhiệt độ, đưa ra thông tin tổng quan diễn biến thời tiết trong tháng giúp dự đoán nhu cầu thị trường tại địa phương, từ đó có kế hoạch cung cấp, luân chuyển hàng hóa.
                      </li>
                      <li>Với các chuỗi đại lý lớn tại nhiều địa phương, việc theo dõi dự báo 30 ngày tại các khu vực có thể giúp điều phối vận chuyển điều hòa từ khu vực nhu cầu ít sang khu vực nhu cầu nhiều.</li>
                      <li>Bản tin được cập nhật liên tục, gối đầu giúp thông tin được tin cậy nhất
                      </li>
                    </ul>
                    <div><img src={ContentThuongMai2} alt={"ContentThuongMai2"} width={"100%"}/></div>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ];
        break;
      case "van-tai":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Đánh giá lộ trình dựa trên thời tiết tổng quan",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Nhận định thời tiết theo khu vực để đánh giá các nguy cơ rủi ro có thể xảy ra khi di chuyển</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution2,
            title:"Dự báo thời tiết chi tiết theo tuyến đường di chuyển",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo thời tiết 3 ngày tới và cập nhật liên tục trên chặng đường di chuyển để nắm bắt kịp thời các hiện tượng thời tiết tại điểm đến.</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution3,
            title:"Cảnh báo mưa dông, sét theo tọa độ của lái xe",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Sử dụng công nghệ và dữ liệu radar, vệ tinh để phát hiện sớm các ổ mây dông hình thành trên biển, theo dõi sự di chuyển và đánh giá khả năng tác động tới tọa độ lái xe.</li>
                      <li>Hệ thống sẽ phát cảnh báo nếu có nguy hiểm tiếp cận tới hiện trường một khoảng cách nhất định.</li>
                      <li>Tin cảnh báo sẽ được phát qua email/zalo/notify trên ứng dụng di dộng</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến tuyến đường di chuyển.</li>
                      <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến tuyến đường di chuyển</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ];
        break;
      case "du-lich-su-kien":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Dự báo thời tiết theo các tour của khách",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Mỗi tour của khách hoặc đoàn khách sẽ được dự báo chi tiết theo lịch trình, từ đó giúp nhà quản lý, điều hành tour tối ưu trải nghiệm cho khách, điều chỉnh phù hợp các hoạt động ứng với các thông tin của thời tiết</li>
                      <li>Các sự kiện tổ chức trong tour được chuẩn bị kỹ hơn và có các giải pháp dự phòng rủi ro để đảm bảo trải nghiệm khách hàng là tốt nhất</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution2,
            title:"Dự báo sóng, gió, thời tiết cho các tuyến luồng tàu du lịch",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo sóng, gió chi tiết cho các tuyến luồng tàu du lịch nhằm đảm bảo an toàn và nâng cao trải nghiệm của du khách</li>
                      <li>Hình ảnh trực quan, dễ hiểu</li>
                    </ul>
                    <div>
                      <img src={ContentSuKien2} alt={"ContentSuKien2"} width={"100%"}/>
                    </div>
                  </div>
              )
            }
          },
          {
            icon:StepSolution3,
            title:"Cảnh báo mưa dông, sét cho các hoạt động ngoài trời",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Sử dụng công nghệ và dữ liệu radar, vệ tinh để phát hiện sớm các ổ mây dông hình thành trên biển, theo dõi sự di chuyển và đánh giá khả năng tác động tới điểm cần dự báo.</li>
                      <li>Hệ thống sẽ phát cảnh báo nếu có nguy hiểm tiếp cận tới hiện trường một khoảng cách nhất định.</li>
                      <li>Tin cảnh báo sẽ được phát qua email/zalo/notify trên ứng dụng di dộng</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến khu vực thi công.</li>
                      <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến khu vực thi công</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ]
        break;
      case "dau-khi":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Dự báo sóng và gió trên các độ cao",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo sóng gồm các thành phần: sóng gió (wind wave), sóng lừng (swell), sóng có nghĩa (sig wave), sóng cực đại (max wave) trong 5 - 10 ngày tới, độ chi tiết tới 30 phút một.</li>
                      <li>Dự báo gió tại những độ cao 10m, 50m, 100m, 120m, 150m</li>
                      <li>Tần suất cập nhật thông tin: 2 lần/ngày</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution3,
            title:"Cảnh báo mưa dông, sét",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Sử dụng công nghệ và dữ liệu radar, vệ tinh để phát hiện sớm các ổ mây dông hình thành trên biển, theo dõi sự di chuyển và đánh giá khả năng tác động tới điểm cần dự báo.</li>
                      <li>Hệ thống sẽ phát cảnh báo nếu có nguy hiểm tiếp cận tới hiện trường một khoảng cách nhất định.</li>
                      <li>Tin cảnh báo sẽ được phát qua email/zalo/notify trên ứng dụng di dộng</li>
                        <li>Phạm vi: trong vùng khả dụng của hệ thống radar</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết biển trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến khu vực thi công.</li>
                      <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến khu vực xây dựng, bảo trì</li>
                    </ul>
                  </div>
              )
            }
          },
          // {
          //   icon:StepSolution4,
          //   title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
          //   content : () => {
          //     return(
          //         <div>
          //           <ul className={"stepTextContent"}>
          //             <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến khu vực thi công.</li>
          //             <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến khu vực thi công</li>
          //           </ul>
          //         </div>
          //     )
          //   }
          // },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ];
        break;
      case "khai-khoang":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Quan trắc thời gian thực các yếu tố khí tượng (site monitoring)",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Các yếu tố quan trắc gồm: lượng mưa, tốc độ gió, hướng gió, nhiệt độ, độ ẩm</li>
                      <li>Lắp đặt tại công trường hoặc cách công trường không quá 500m</li>
                      <li>Cập nhật số liệu liên tục 10 phút/lần</li>
                      <li>Khi các yếu tố vượt quá ngưỡng cho phép sẽ gửi cảnh báo đến điện thoại di dộng của tổng chỉ huy công trường</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution2,
            title:"Dự báo thời tiết  3 - 7 ngày tới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo chi tiết cho 3 ngày theo ca chi tiết đến từng giờ bởi các chuyên gia dày dạn kinh nghiệm có độ tin cậy cao giúp chủ động các hoạt động khai thác.</li>
                      <li>Các yếu tố dự báo tập trung vào mưa, gió, nhiệt độ và theo yêu cầu.</li>
                      <li>Nhận định 5 ngày tiếp theo giúp nắm được xu hướng thời tiết hạn dài để lên kế hoạch điều phối nguồn lực</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution3,
            title:"Cảnh báo mưa dông, sét",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Sử dụng công nghệ và dữ liệu radar, vệ tinh để phát hiện sớm các ổ mây dông hình thành trên biển, theo dõi sự di chuyển và đánh giá khả năng tác động tới điểm cần dự báo.</li>
                      <li>Hệ thống sẽ phát cảnh báo nếu có nguy hiểm tiếp cận tới hiện trường một khoảng cách nhất định.</li>
                      <li>Tin cảnh báo sẽ được phát qua email/zalo/notify trên ứng dụng di dộng</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến khu vực thi công.</li>
                      <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến khu vực thi công</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ];
        break;
      case "dien-gio":
        data.listDataCareer=[
          {
            icon:StepSolution1,
            title:"Dự báo sóng và gió trên các độ cao",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo sóng gồm các thành phần: sóng gió (wind wave), sóng lừng (swell), sóng có nghĩa (sig wave), sóng cực đại (max wave) trong 5 - 10 ngày tới, độ chi tiết tới 30 phút một.</li>
                      <li>Dự báo gió tại những độ cao 10m, 50m, 100m, 120m, 150m</li>
                      <li>Tần suất cập nhật thông tin: 2 lần/ngày</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution2,
            title:"Cảnh báo mưa dông, sét",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Sử dụng công nghệ và dữ liệu radar, vệ tinh để phát hiện sớm các ổ mây dông hình thành trên biển, theo dõi sự di chuyển và đánh giá khả năng tác động tới điểm cần dự báo.</li>
                      <li>Hệ thống sẽ phát cảnh báo nếu có nguy hiểm tiếp cận tới hiện trường một khoảng cách nhất định.</li>
                      <li>Tin cảnh báo sẽ được phát qua email/zalo/notify trên ứng dụng di dộng</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Weatherplus luôn cung cấp cho người dùng nhận định xu thế diễn biến của thời tiết biển trong 10 ngày tới, xác định những hiện tượng thời tiết nguy hiểm có thể ảnh hưởng đến khu vực thi công.</li>
                      <li>Weatherplus triển khai một kịch bản theo dõi và cảnh báo, hỗ trợ thông tin liên tục khi các cơn bão, áp thấp nhiệt đới ảnh hưởng đến khu vực xây dựng, bảo trì</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091</li>
                      <li>Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết</li>
                    </ul>
                  </div>
              )
            }
          },
        ]
        break;
      case "thuong-mai-ban-le":
        data.listDataCareer=[
        {
            icon:StepSolution1,
            title:"Dự báo thời tiết  3 - 7 ngày tới tối ưu hoạt động kinh doanh",
            content : () => {
              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo chi tiết cho 3 ngày để tối ưu các hoạt động hàng ngày của chuỗi siêu thị, cửa hàng trên một địa bàn rộng.</li>
                      <li>Các yếu tố dự báo tập trung vào mưa, gió, nhiệt độ và theo yêu cầu.</li>
                      <li>Nhận định 5 ngày tiếp theo giúp nắm được xu hướng thời tiết hạn dài để lên kế hoạch điều phối nguồn lực</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon: StepSolution2,
            title: "Dự báo 30 ngày tối ưu sales, marketing, luân chuyển hàng hóa",
            content: () => {
              return (
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Với bản tin dự báo 30 ngày, độ chi tiết theo từng 5 ngày hoặc theo từng tuần, dự báo các yếu tố ảnh hưởng lớn đến nhu cầu điều hòa của người tiêu dùng là nhiệt độ, mưa, độ ẩm… trong đó quan trọng nhất là nhiệt độ, đưa ra thông tin tổng quan diễn biến thời tiết trong tháng giúp dự đoán nhu cầu thị trường tại địa phương, từ đó có kế hoạch cung cấp, luân chuyển hàng hóa.
                      </li>
                      <li>Với các chuỗi đại lý lớn tại nhiều địa phương, việc theo dõi dự báo 30 ngày tại các khu vực có thể giúp điều phối vận chuyển điều hòa từ khu vực nhu cầu ít sang khu vực nhu cầu nhiều.</li>
                      <li>Bản tin được cập nhật liên tục, gối đầu giúp thông tin được tin cậy nhất
                      </li>
                    </ul>
                    <div><img src={ContentThuongMai2} alt={"ContentThuongMai2"} width={"100%"}/></div>
                  </div>
              )
            }
          },
          {
            icon: StepSolution3,
            title: "Dự báo 3 - 6 tháng tối ưu kế hoạch nhập hàng, kho bãi",
            content: () => {
              return (
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>Dự báo 3 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Thời điểm bắt đầu mùa hè, các đợt nắng nóng đầu tiên, dự báo số lượng các đợt nắng nóng</li>
                          <li>Dự báo độ dài của đợt nắng nóng</li>
                          <li>Các hiện tượng dị thường, cực đoan có thể xảy ra trong 10 ngày, 30 ngày</li>
                          <li>Thông tin phân tích, tư vấn chuyên gia khi cần ra quyết định</li>
                        </ul>
                      </li>
                      <li>Dự báo 6 tháng cung cấp thông tin:
                        <ul style={{listStyleType:"lower-latin"}}>
                          <li>Xu hướng nhiệt độ từng tháng, các tháng tới nóng hơn hay lạnh hơn so với thông thường nhiều năm</li>
                          <li>Các hiện tượng thời tiết dị thường có khả năng xảy ra từng tháng</li>
                          <li>Xu hướng mùa hè năm nay dài hơn hay ngắn hơn</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution4,
            title:"Phân tích dữ liệu quá khứ, dự đoán nhu cầu, thị hiếu",
            content : () => {

              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>  Với chuỗi dữ liệu lịch sử lên tới 30 năm với nhiều yếu tố cùng với các chuyên gia phân tích dữ liệu, chúng tôi cung cấp giải pháp phân tích dữ liệu quá khứ với sự phối hợp giữa số liệu bán hàng và số liệu khí tượng để nắm bắt thị hiếu, nhu cầu, từ đó dự đoán cho tương lai</li>
                    </ul>
                  </div>
              )
            }
          },
          {
            icon:StepSolution5,
            title:"Chuyên gia hỗ trợ 24/7",
            content : () => {

              return(
                  <div>
                    <ul className={"stepTextContent"}>
                      <li>
                        Trong những trường hợp cần thêm thông tin và hỗ trợ về thời tiết, thiên tai, xin gọi đến Hotline 0822 091 091
                      </li>
                      <li>
                        Khi cần những báo cáo hoặc thống kê chuyên sâu về thời tiết
                      </li>
                    </ul>
                    <div>
                      <img src={ContentThuongMai3} alt={"ContentThuongMai3"} width={"100%"}/>
                    </div>
                  </div>
              )
            }
          }
          ]
        break;
      default:
        break;
    }
    return data;
  }
  static getEffectWeatherForCareer(career) {
    const data = {
      dataCareer: null,
    };
    switch (career) {
      case "Khai khoáng":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Làm hầm nóng khiến việc khai khoáng trở nên khó khăn – Ảnh hưởng đến sức khỏe và năng suất làm việc của người lao động",
          },
          {
            title: "Gió lớn",
            imgUrl: windStrong,
            description:
              "Tăng tác động của bụi/khí thải đến khu dân cư lân cận và gây mất an toàn lao động ở các khu vực địa hình hiểm trở",
          },
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Ngập hầm gây gián đoạn việc khai khoáng, khó khăn trong vận chuyển, bảo quản khoáng sản",
          },
          {
            title: "Thời tiết xấu",
            imgUrl: badWeather,
            description: "Gây nguy hiểm trong quá trình vận chuyển",
          },
          {
            title: "Sạt lở đất",
            imgUrl: Landslide,
            description:
              "Thiệt hại người và tài sản, gián đoạn việc sản xuất và cản trở vận chuyển khoáng sản",
          },
          {
            title: "Sét",
            imgUrl: Thunder,
            description: "Thiệt hại về người và của và gián đoạn sản xuất",
          },
          {
            title: "Sóng biển mạnh",
            imgUrl: wavesBig,
            description:
              "Quá trình khai thác sẽ gặp rất nhiều khó khăn, có thể bị lật thuyền",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description: "Môi trường, rửa trôi, thất thoát khoáng sản…",
          },
        ];
        break;
      case "Bán lẻ":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Khiến nhu cầu thị trường về sản phẩm chống nắng nóng hạ nhiệt, dịch vụ chăm sóc sức khỏe tăng cao - ảnh hưởng đến kế hoạch sản xuất kinh doanh",
          },
          {
            title: "Gió, gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây hư hỏng các thiết bị, biển bảng, hàng hóa. Gây khó khăn trong giao vận",
          },
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Ảnh hưởng không gian cửa hàng, giảm sức mua trực tiếp tại cửa hàng",
          },
          {
            title: "Độ ẩm cao",
            imgUrl: Humidity,
            description: "Gây ảnh hưởng đến bảo quản hàng hóa, thiết bị",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Gây biến động lớn về nhu cầu và sức mua-ảnh hưởng kế hoạch kinh doanh do không chủ động về phương án",
          },
          {
            title: "Mây",
            imgUrl: Clouds,
            description:
              "Gây ảnh hưởng các hoạt động truyền thông sự kiện ngoài trời",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ hàng hóa, thiết bị",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description:
              "Tồn kho do điều kiện thời tiết không thuận lợi để thực hiện các chương trình ưu đãi",
          },
        ];
        break;
      case "Thời trang":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Nắng nóng bất thường gây ảnh hưởng đến sức mua và kế hoạch bán hàng thu đông",
          },
          {
            title: "Gió, gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây hư hỏng các thiết bị, biển bảng, hàng hóa. Gây khó khăn trong giao vận",
          },
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Ảnh hưởng không gian cửa hàng, giảm sức mua trực tiếp tại cửa hàng",
          },
          {
            title: "Độ ẩm cao",
            imgUrl: Humidity,
            description:
              "Gây ảnh hưởng đến bảo quản nguyên liệu, phụ liệu, sản phẩm và thiết bị",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Gây biến động lớn về nhu cầu và sức mua-ảnh hưởng kế hoạch kinh doanh do không chủ động về phương án",
          },
          {
            title: "Mây",
            imgUrl: Clouds,
            description:
              "Gây ảnh hưởng các hoạt động truyền thông sự kiện ngoài trời",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ hàng hóa, thiết bị",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description: "Tồn kho trái vụ do thời tiết không thuận lợi",
          },
        ];
        break;
      case "Du lịch":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Gây biến động lớn về nhu cầu-quá tải hệ thống du lịch, nghỉ dưỡng - chi phí điều hành tăng cao, gián đoạn kinh doanh",
          },
          {
            title: "Rét đậm",
            imgUrl: temperatureLow,
            description:
              "Gây ảnh hưởng sức khỏe du khách, thậm chí sự kiện có thể bị hoãn",
          },
          {
            title: "Bão lũ, ngập úng",
            imgUrl: rainStrong,
            description:
              "Gây hư hỏng, xuống cấp nhiều di tích lịch sử văn hóa, tài nguyên du lịch, công trình dịch vụ du lịch - gián đoạn công việc kinh doanh.",
          },
          {
            title: "Sóng biển mạnh",
            imgUrl: wavesBig,
            description:
              "Gây nguy hiểm cho hoạt động du lịch biển, thậm chí sự kiện có thể bị hoãn",
          },
          {
            title: "Gió, gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây cản trở hoặc nguy hiểm quá trình tham quan và các hoạt động giải trí ngoài trời, quá trình di chuyển",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ vật dụng, thiết bị",
          },
          {
            title: "Trời âm u",
            imgUrl: Clouds,
            description:
              "Gây ảnh hưởng hoạt động khai thác tour ngắm cảnh trên cao",
          },
        ];
        break;
      case "Sự kiện":
        data.dataCareer = [
          {
            title: "Nắng nóng gay gắt",
            imgUrl: temperatureHigh,
            description:
              "Gây ảnh hưởng sức khỏe người tham gia sự kiện, tốn kém chi phí điều hành sự kiện",
          },
          {
            title: "Gió rất mạnh",
            imgUrl: windStrong,
            description:
              "Khiến bụi và các vật thể bay mất kiểm soát, gây cản trở hoặc nguy hiểm với con người, thiệt hại về máy móc tài sản",
          },
          {
            title: "Mưa rất to",
            imgUrl: rainStrong,
            description:
              "Gây cản trở quá trình tổ chức sự kiện, thậm chí có thể bị hủy, gây khó khăn bảo quản trang thiết bị ngoài trời ",
          },
          {
            title: "Trời âm u",
            imgUrl: Clouds,
            description:
              "Thiếu ánh sáng, gây ảnh hưởng đến các hoạt động ngoài trời",
          },
          {
            title: "Rét đậm",
            imgUrl: temperatureLow,
            description:
              "Gây ảnh hưởng sức khỏe người tham gia sự kiện, kế hoạch sự kiện",
          },
        ];
        break;
      case "Nông nghiệp":
        data.dataCareer = [
          {
            title: "Mưa lớn",
            imgUrl: rainStrong,
            description:
              "Gây ngập úng đất trồng, chuồng trại, gây thiệt hại về hoa màu, vật nuôi ",
          },
          {
            title: "Nắng nóng kéo dài",
            imgUrl: temperatureHigh,
            description:
              "Gây khô hạn, thiếu nước, thiệt hại về cây trồng, vật nuôi",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Ảnh hưởng đến sinh trưởng và phát triển của cây trồng, vật nuôi",
          },
          {
            title: "Gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây ảnh hưởng đến quá trình phun thuốc, thu hoạch cây trồng",
          },
          {
            title: "Độ ẩm cao",
            imgUrl: Humidity,
            description:
              "Gia tăng nguy cơ mắc sâu bệnh ở cây trồng và dịch bệnh ở vật nuôi",
          },
          {
            title: "Trời âm u",
            imgUrl: Clouds,
            description:
              "Ảnh hưởng đến sinh trưởng và phát triển của cây trồng, vật nuôi",
          },
        ];
        break;
      case "Xây dựng":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Gây ảnh hưởng sức khỏe công nhân, giảm hiệu suất công việc",
          },
          {
            title: "Gió lớn",
            imgUrl: windStrong,
            description:
              "Tăng tác động của bụi gây ra các biến chứng và hỏng hóc không đáng có đối với máy móc cũng như các nguy cơ đối với sức khỏe và sự an toàn lao động",
          },
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Làm giảm tầm nhìn của công nhân, người lái xe,  tạo bùn khó thi công, ngập lụt công trường, hư hỏng thiết bị hoặc vật liệu…",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Làm giảm hiệu suất của rất nhiều nguyên vật liệu như xi măng, thạch cao, sơn tường,.. gây chậm tiến độ công trình và thiệt hại kinh tế",
          },
          {
            title: "Sạt lở đất",
            imgUrl: Landslide,
            description:
              "Gây gián đoạn quá trình xây dựng, nguy hiểm với công nhân",
          },
          {
            title: "Mưa đá",
            imgUrl: hail,
            description:
              "Dễ làm vỡ kính và có thể làm móp tấm kim loại-thiệt hại kinh tế",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ, mất an toàn lao động",
          },
        ];
        break;
      case "Năng lượng":
        data.dataCareer = [
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description:
              "Làm tăng sản lượng  điện năng từ hệ thống điện mặt trời-giúp vận hành tối ưu",
          },
          {
            title: "Mưa",
            imgUrl: rainStrong,
            description:
              "Kiểm soát lượng nước trong hồ chứa để chủ động xây dựng các kế hoạch vận hành thủy điện tối ưu và hiệu quả",
          },
          {
            title: "Gió, sóng",
            imgUrl: windStrong,
            description:
              "Giúp doanh nghiệp điện gió chủ động trong kế hoạch sản xuất, vận hành, bảo dưỡng, sửa chữa thiết bị",
          },
          {
            title: "Trời âm u",
            imgUrl: Clouds,
            description:
              "Làm giảm sản lượng của hệ thống điện năng lượng mặt trời ",
          },
        ];
        break;
      case "Vận tải & Logistic":
        data.dataCareer = [
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Gây hạn chế tầm nhìn, gia tăng nguy cơ xảy ra tai nạn",
          },
          {
            title: "Sương mù",
            imgUrl: fog,
            description:
              "Giảm tầm nhìn, giảm điều kiện lái xe, giảm tốc độ và mất nhiều thời gian di chuyển, gia tăng nguy cơ xảy ra tai nạn",
          },
          {
            title: "Gió lớn",
            imgUrl: windStrong,
            description:
              "Làm bụi và các mảnh vỡ trên đường văng xa gây nguy hiểm và giảm tốc độ di chuyển",
          },
          {
            title: "Lốc xoáy",
            imgUrl: tornado,
            description:
              "Thiệt hại lớn đối với cơ sở hạ tầng và phương tiện, chậm trễ giao hàng, mất an toàn",
          },
          {
            title: "Nắng nóng",
            imgUrl: temperatureHigh,
            description: "Ảnh hưởng đến chất lượng bảo quản hàng hóa",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Cản trở di chuyển, ảnh hưởng sức khỏe của người lái và chất lượng máy móc thiết bị",
          },
          {
            title: "Mưa đá, sạt lở đất",
            imgUrl: hail,
            description:
              "Gây hư hại và mất an toàn giao thông, ảnh hưởng đến lịch trình giao vận",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description:
              "Thời tiết hanh khô dễ xảy ra hỏa hoạn. Sóng biển mạnh gây khó khăn cho tàu thuyền di chuyển",
          },
        ];
        break;
      case "Nhà hàng":
        data.dataCareer = [
          {
            title: "Nền nhiệt biến động",
            imgUrl: temperatureHigh,
            description:
              "Làm nhu cầu biến động khiến nhà hàng bị động trong việc nhập nguyên liệu, gây khó khăn trong bảo quản hàng hóa, ảnh hưởng kế hoạch kinh doanh",
          },
          {
            title: "Giá rét",
            imgUrl: temperatureLow,
            description:
              "Gây ảnh hưởng giá cả thực phẩm và các chi phí hoạt động khác tăng cao-ảnh hưởng kế hoạch kinh doanh",
          },
          {
            title: "Gió, gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây hư hỏng các thiết bị, biển bảng, hàng hóa. Gây khó khăn trong giao vận",
          },
          {
            title: "Mưa bão",
            imgUrl: rainStrong,
            description:
              "Ảnh hưởng không gian nhà hàng, nhu cầu tiêu dùng và giảm sức mua trực tiếp tại nhà hàng",
          },
        ];
        break;
      case "Điện tử-điện lạnh":
        data.dataCareer = [
          {
            title: "Nền nhiệt biến động",
            imgUrl: temperatureHigh,
            description:
              "Gây biến động lớn về nhu cầu và sức mua-ảnh hưởng kế hoạch kinh doanh do không chủ động về phương án",
          },
          {
            title: "Mưa bão",
            imgUrl: rainStrong,
            description:
              "Ảnh hưởng không gian cửa hàng, giảm sức mua trực tiếp tại cửa hàng",
          },
          {
            title: "Độ ẩm cao",
            imgUrl: Humidity,
            description: "Gây ảnh hưởng đến bảo quản hàng hóa, thiết bị",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ, chập thiết bị",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description:
              "Tồn kho do điều kiện thời tiết không thuận lợi để thực hiện các chương trình ưu đãi",
          },
        ];
        break;
      case "Thể thao giải trí":
        data.dataCareer = [
          {
            title: "Nền nhiệt biến động",
            imgUrl: temperatureHigh,
            description:
              "Gây ảnh hưởng sức khỏe người chơi và khán giả, thậm chí sự kiện có thể bị hoãn. Ảnh hưởng chất lượng cơ sở hạ tầng",
          },
          {
            title: "Độ ẩm thấp",
            imgUrl: humidityLow,
            description: "Tăng nguy cơ cháy nổ thiết bị",
          },
          {
            title: "Gió mạnh",
            imgUrl: windStrong,
            description:
              "Gây cản trở hoặc nguy hiểm quá trình thể thao giải trí ngoài trời",
          },
          {
            title: "Mưa bão",
            imgUrl: rainStrong,
            description:
              "Gây cản trở quá trình thể thao - giải trí ngoài trời, thậm chí có thể bị hủy.  Ảnh hưởng chất lượng cơ sở hạ tầng",
          },
          {
            title: "Trời âm u",
            imgUrl: Clouds,
            description:
              "Thiếu ánh sáng, gây ảnh hưởng đến các hoạt động thể thao-giải trí ngoài trời",
          },
        ];
        break;
      case "Y tế":
        data.dataCareer = [
          {
            title: "Thời tiết cực đoan",
            imgUrl: badWeather,
            description:
              "Gia tăng nguy cơ mắc các bệnh lý, dịch bệnh gây nguy hiểm sức khỏe con người, quá tải hệ thống y tế và thiếu hụt thuốc men ",
          },
          {
            title: "Mưa bão, lũ lụt",
            imgUrl: rainStrong,
            description:
              "Ngập lụt, mưa giông gây ảnh hưởng cơ sở vật chất, thiết bị của hệ thống y tế, gây khó khăn trong việc sơ tán và tiếp cận người bệnh",
          },
          {
            title: "Và nhiều nguy cơ khác",
            imgUrl: Nuclear,
            description:
              "Nhân viên y tế làm việc quá tải, có nguy cơ mất sức, lây nhiễm chéo từ bệnh nhân",
          },
        ];
        break;
      default:
        break;
    }
    return data;
  }
  static getListCareer(v) {
      const data = {
          title: "",
          content: [],
          listText: "",
          contentList: [],
          image: "",
          url: "",
          name: "",
          imageMobi: ""
      }
      switch (v) {
          case 'hangKhong':
              data.title = "HÀNG KHÔNG";
              data.title1 = "AVIATION";
              data.content = ['WeatherPlus cung cấp dịch vụ dự báo thời tiết chuyên sâu cho ngành hàng không tại Việt Nam, nhằm nâng cao an toàn bay và tối ưu hóa hiệu quả hoạt động. Với việc ứng dụng công nghệ dự báo hiện đại, WeatherPlus đảm bảo cung cấp thông tin thời tiết chính xác và kịp thời, hỗ trợ dự báo viên đưa ra quyết định nhanh chóng, đảm bảo hiệu quả công việc.'];
              data.content1 = ['WeatherPlus provides specialized weather forecasting services for the aviation industry in Vietnam, aimed at enhancing flight safety and optimizing operational efficiency. By utilizing advanced forecasting technology, WeatherPlus ensures accurate and timely weather information, aiding meteorologists in making swift decisions and maintaining work efficiency.'];
              data.listText = "Các dịch vụ của chúng tôi bao gồm:";
              data.listText1 = "Our services include:";
              data.contentList = [
                  "Bộ sản phẩm dự báo số trị.",
                  "Dự báo thời tiết chi tiết tại từng sân bay.",
                  "Sản phẩm dự báo tầm nhìn xa hạn ngắn.",
                  "Cảnh báo mưa dông, sét hạn cực ngắn chi tiết đến từng sân bay.",
              ];
              data.contentList1 = [
                  "Numerical weather prediction products.",
                  "Detailed airport-specific weather forecasts.",
                  "Visibility forecasts.",
                  "Detailed short-term nowcasting and lightning warnings at individual airports.",
              ];
              data.image = hangKhongDetail;
              data.url = "/thoi-tiet-theo-nganh/khai-khoang";
              data.name = "Hàng không";
              data.imageMobi = hangKhongDetail;
              break;
          case 'dauKhi':
              data.title = "THĂM DÒ VÀ KHAI THÁC DẦU KHÍ";
              data.title1 = "OIL & GAS EXTRACTION";
              data.content = ['WeatherPlus cung cấp các giải pháp dự báo thời tiết chuyên sâu cho ngành công nghiệp dầu khí, giúp nâng cao hiệu quả và an toàn trong quá trình thăm dò, khai thác và vận chuyển. Thông tin thời tiết tin cậy của chúng tôi mang lại lợi ích thiết thực cho hoạt động sản xuất, vận hành, đánh giá rủi ro, và quản lý đầu tư, từ đó tối ưu hóa quy trình và bảo vệ các khoản đầu tư trong ngành dầu khí.'];
              data.content1 = ['WeatherPlus provides specialized weather forecasting solutions for the oil and gas industry, enhancing efficiency and safety during exploration, extraction, and transportation processes. Our reliable weather information offers practical benefits for production operations, risk assessment, and investment management, optimizing processes and safeguarding investments in the oil and gas sector.'];
              data.listText = "Các dịch vụ bao gồm:";
              data.listText1 = "Our services include:";
              data.contentList = [
                  "Dự báo thời tiết và hải văn cho từng vị trí cụ thể trên Biển Đông",
                  "Cảnh báo thời tiết nguy hiểm: Bao gồm bão, áp thấp nhiệt đới, sóng cao, gió mạnh, triều cường, sương mù và các rủi ro hàng hải khác",
                  "Nghiên cứu khí hậu Metocean và mô phỏng tác động của sóng, gió lên tàu biển",
              ];
              data.contentList1 = [
                  "Weather and oceanographic forecasts for specific locations in the East Vietnam Sea.",
                  "Hazardous weather warnings: Including storms, tropical depressions, high waves, strong winds, storm surges, fog, and other maritime risks.",
                  "Metocean climate research and simulations of the impact of waves and winds on ships.",
              ];
              data.image = DauKhi1;
              data.url = "/thoi-tiet-theo-nganh/dau-khi";
              data.name = "Dầu Khí";
              data.imageMobi = DauKhi1;
              break;
          case 'khaiKhoang':
              data.title = "KHAI THÁC THAN VÀ KHOÁNG SẢN";
              data.title1 = "COAL AND MINERAL EXTRACTION";
              data.content = ['WeatherPlus tự hào mang đến giải pháp dự báo thời tiết chuyên nghiệp cho ngành khai thác than và khoáng sản, giúp nâng cao hiệu quả điều hành, giảm thiểu rủi ro từ thời tiết, bảo vệ an toàn lao động và thiết bị, đồng thời tối ưu hóa chi phí và nguồn lực. Hiện nay, nhiều đơn vị khai thác than vẫn sử dụng thông tin dự báo thời tiết miễn phí trên internet, đài, tivi để điều hành khai thác tại hiện trường. Công tác đo mưa tại khai trường vẫn rất thủ công và thông tin dự báo thường thiếu chính xác, khó khăn để ra quyết định, dẫn đến nhiều lãng phí và rủi ro. Kết quả đo mưa thiếu độ tin cậy, khó tổng hợp để phân tích, đánh giá và báo cáo.'];
              data.content1 = ['WeatherPlus is proud to offer professional weather forecasting solutions for the coal and mineral mining industry, enhancing operational efficiency, minimizing weather-related risks, ensuring the safety of workers and equipment, and optimizing costs and resources. Currently, many coal mining units still rely on free weather forecasts from the internet, radio, and television to manage field operations. Rain measurement at mining sites remains very manual, and the forecast information is often inaccurate, making decision-making difficult, leading to waste and risks. Rain measurement results lack reliability, challenging to complile to analyze, evaluate, and report.'];
              data.listText = "Các giải pháp:";
              data.listText1 = "Our solutions include:";
              data.contentList = [
                  "Quan trắc thời gian thực các yếu tố khí tượng tại khai trường (site monitoring).",
                  "Dự báo thời tiết 3-7 ngày tới.",
                  "Cảnh báo mưa dông, sét.",
                  "Dự báo, cảnh báo các hiện tượng thời tiết nguy hiểm: bão, áp thấp nhiệt đới.",
              ];
              data.contentList1 = [
                  "Real-time weather monitoring at extraction sites (site monitoring).",
                  "3-7 day weather forecasts.",
                  "Alerts for thunderstorms and lightning.",
                  "Forecasts and warnings for hazardous weather events: Storms, tropical depressions.",
              ];
              data.image = khaikhoangDetail;
              data.url = "/thoi-tiet-theo-nganh/khai-khoang";
              data.name = "Khai khoáng";
              data.imageMobi = khaikhoangDetail;
              break;
          case 'dienGio':
              data.title = "ĐIỆN GIÓ NGOÀI KHƠI";
              data.title1 = "OFFSHORE WIND POWER";
              data.content = ['WeatherPlus cung cấp giải pháp toàn diện cho các dự án điện gió ngoài khơi, bao gồm cả giai đoạn xây dựng và vận hành nhà máy.'];
              data.content1 = ['WeatherPlus provides comprehensive solutions for offshore wind power projects, covering both the construction and operation phases of the plant.'];
              data.endContent1 = "Với WeatherPlus, các dự án điện gió ngoài khơi được tối ưu hóa từ giai đoạn khảo sát, thi công đến vận hành, đảm bảo an toàn và hiệu quả kinh tế tối đa.";
              data.endContent1e = "With WeatherPlus, offshore wind power projects are optimized from the survey and construction phases to operation, ensuring maximum safety and economic efficiency.";
              data.listText = "Giai đoạn xây dựng:";
              data.listText1 = "Construction Phase:";
              data.contentList = [
                  "Cung cấp dự báo thời tiết và hải văn chi tiết lên đến 10 ngày, bao gồm đặc điểm sóng và gió ở các độ cao từ 10-150m.",
                  "Cảnh báo mưa dông, sét, và các hiện tượng thời tiết nguy hiểm như bão và áp thấp nhiệt đới.",
                  "Dự báo tin cậy, chính xác giúp các chủ thầu lập kế hoạch thi công an toàn và hiệu quả, giảm thiểu rủi ro chậm trễ và phát sinh chi phí.",
              ];
              data.contentList1 = [
                  "Detailed weather and oceanographic forecasts up to 10 days, including wave and wind characteristics at heights from 10-150 meters.",
                  "Alerts for thunderstorms, lightning, and hazardous weather events such as storms and tropical depressions.",
                  "Reliable and accurate forecasts help contractors plan construction safely and efficiently, reducing the risks of delays and additional costs.",
              ];
              data.listText2 = "Giai đoạn vận hành:";
              data.listText2e = "Operational Phase:";
              data.contentList2 = [
                  "Cung cấp dịch vụ dự báo công suất chi tiết, từ chu kỳ vận hành trong ngày đến dự báo một tuần tới.",
                  "Giúp nhà máy và chủ đầu tư điều chỉnh hoạt động phù hợp với thực tế, đáp ứng quy định của cơ quan quản lý và điều phối của nhà nước.",
              ];
              data.contentList2e = [
                     "Provides detailed power forecasting services, from daily operational cycles to one-week forecasts.",
                     "Assists plants and investors in adjusting operations according to actual conditions, complying with regulatory requirements and state coordination.",
              ];
              data.image = dienGioDetail;
              data.url = "/thoi-tiet-theo-nganh/dien-gio";
              data.name = "Điện gió";
              data.imageMobi = dienGioDetail;
              break;
          case 'thoiTrang':
              data.title = "MAY MẶC VÀ THỜI TRANG";
              data.title1 = "APPAREL AND FASHION";
              data.content = ['Sản phẩm của WeatherPlus giúp doanh nghiệp may mặc và thời trang tận dụng thông tin thời tiết để điều phối hàng hóa, tối ưu hóa chiến dịch marketing và lập kế hoạch sản xuất hiệu quả, giảm thiểu rủi ro và tăng doanh thu.'];
              data.content1 = ['WeatherPlus products help apparel and fashion businesses leverage weather information to coordinate inventory, optimize marketing campaigns, and plan production effectively, reducing risks and increasing revenue.'];
              data.listText = "Các giải pháp:";
              data.listText1 = "Solutions:";
              data.contentList = [
                  "Dự báo thời tiết 3-7 ngày.",
                  "Dự báo 30 ngày.",
                  "Dự báo 3-6 tháng.",
                  "Phân tích dữ liệu.",
              ];
              data.contentList1 = [
                  "3-7 day weather forecasts.",
                  "30-day weather forecasts.",
                  "3-6 month weather forecasts.",
                  "Data analysis.",
              ];
              data.image = thoiTrangDetail;
              data.url = "/thoi-tiet-theo-nganh/may-mac-thoi-trang";
              data.name = "May mặc";
              data.imageMobi = thoiTrangDetail;
              break;
          case 'duLich':
              data.title = "DU LỊCH VÀ SỰ KIỆN";
              data.title1 = "TOURISM AND EVENTS";
              data.content = ['Giải pháp của WeatherPlus giúp các doanh nghiệp trong ngành du lịch và sự kiện tận dụng thông tin thời tiết để lên kế hoạch, tổ chức sự kiện và điều phối hoạt động du lịch một cách hiệu quả, đảm bảo an toàn và tối đa hóa chi phí.'];
              data.content1 = ['WeatherPlus solutions help businesses in the tourism and events industry utilize weather information to plan, organize events, and coordinate tourism activities effectively, ensuring safety and maximizing cost efficiency.'];
              data.listText = "Các giải pháp:";
              data.listText1 = "Solutions:";
              data.contentList = [
                  "Dự báo thời tiết 3-7 ngày.",
                  "Dự báo 30 ngày.",
                  "Dự báo 3-6 tháng.",
              ];
              data.contentList1 = [
                  "3-7 day weather forecasts.",
                  "30-day weather forecasts.",
                  "3-6 month weather forecasts.",
              ];
              data.image = duLichDetail;
              data.url = "/thoi-tiet-theo-nganh/du-lich-su-kien";
              data.name = "Du lịch";
              data.imageMobi = duLichDetail;
              break;
          case 'duBao':
              data.title = "DỰ BÁO KHÍ HẬU";
              data.title1 = "CLIMATE FORECASTING";
              data.content = ['WeatherPlus cung cấp các bản tin dự báo khí hậu chuyên nghiệp giúp doanh nghiệp và cá nhân nắm bắt xu hướng thời tiết và lập kế hoạch dài hạn hiệu quả.'];
              data.content1 = ['WeatherPlus provides professional climate forecast bulletins, enabling businesses and individuals to grasp weather trends and efficiently plan for the long term.'];
              data.semiHeaderContent = "Các bản tin dự báo: Bản tin dự báo hạn 1 tháng / 3 tháng / 6 tháng / 1 năm, bao gồm các nội dung:";
              data.semiHeaderContent1 = "Forecast reports: Monthly / 3-month / 6-month / 1-year forecasts, including:";
              data.listText = "Tổng quan về khí hậu đã qua";
              data.listText1 = "Overview of past climate";
              data.listText2 = "Nhận định khí hậu các tháng tiếp theo:";
              data.listText2e = "Climate outlook for the upcoming months:";
              data.contentList2 = [
                  "Nhận định chi tiết theo từng tháng - Nhận định chung về tình hình thời tiết sắp tới: Bão, áp thấp nhiệt đới, không khí lạnh, nắng nóng...",
                  "Nhận định chung về mưa và nhiệt độ trong các tháng tới: so sánh với cùng kỳ năm ngoái, tổng lượng mưa trên toàn quốc và nhiệt độ trung bình trên các vùng miền.",
                  "Cung cấp dự báo ngắn hạn và cập nhật định kỳ, đáp ứng mọi nhu cầu sử dụng của khách hàng về các yếu tố: nhiệt, mưa, ẩm..."
              ];
              data.contentList2e = [
                    "Detailed monthly outlook - General forecast of upcoming weather events: Storms, tropical depressions, cold fronts, heatwaves...",
                    "General outlook on rainfall and temperature for the upcoming months: Comparisons with the same period last year, total national rainfall, and average temperatures by region.",
                    "Provides short-term forecasts and periodic updates, meeting all client needs for factors such as temperature, rainfall, humidity...",
              ];
              data.image = duBaoDetail;
              data.url = "/thoi-tiet-theo-nganh/dien-tu-dien-lanh";
              data.name = "Dự báo";
              data.imageMobi = duBaoDetail;
              break;
          case 'api':
              data.title = "API THỜI TIẾT, KHÍ HẬU";
              data.title1 = "WEATHER AND CLIMATE APIs";
              data.content = ['WeatherPlus cung cấp một hệ thống API đầy đủ tất cả các số liệu thời tiết, dễ dàng đưa dữ liệu thời tiết lên website, app… phù hợp với mọi nhu cầu thông tin thời tiết của doanh nghiệp.'];
              data.content1 = ['WeatherPlus provides a complete API system with all weather data, allowing easy integration of weather information into websites, apps...meeting all the weather information needs of businesses.'];
              data.listText = "Các sản phẩm bao gồm:";
              data.listText1 = "Products include:";
              data.contentList = [
                  "API thời tiết hiện tại.",
                  "API dự báo thời tiết 14 ngày.",
                  "API dự báo mưa 72 giờ.",
                  "API cảnh báo mưa dông, sét.",
                  "API dữ liệu quá khứ.",
              ];
              data.contentList1 = [
                  "Current weather API.",
                  "14-day weather forecast API.",
                  "72-hour rainfall forecast API.",
                  "Thunderstorm and lightning alert API.",
                  "Historical data API.",
              ];
              data.image = apiDetail;
              data.url = "/thoi-tiet-theo-nganh/thuong-mai-ban-le";
              data.name = "Api thời tiết";
              data.imageMobi = apiDetail;
              break;
          default:
              break;
      }
    return data;
  }
  static getPackageInfo(p) {
    const data = {
      price: null,
      priceOrigin: "",
      priceDisplay: "",
      packageName: "",
    };
    switch (p) {
      case 0:
        data.price = 0;
        data.priceOrigin = "Miễn phí";
        data.priceDisplay = "Miễn phí";
        data.packageName = "CỘNG ĐỒNG";
        data.expireDisplay = "1 tháng";
        data.pastRain = null;
        data.weatherForecast = "3 ngày";
        data.warningThunderstormLightning = "30 phút";
        data.marineWeather = null;

        data.insightNumber = 10;
        data.userNumber = 1;
        data.locationNumber = 2;
        data.notification = " qua email";
        data.API = "API: 500 lượt gọi/ngày";
        data.hourApi = "";
        break;
      case 1:
        data.price = 490000;
        data.priceOrigin = "490,000đ";
        data.priceDisplay = "490.000 vnđ/tháng";
        data.packageName = "METPLUS 1";
        data.expireDisplay = "1 tháng";

        data.weatherForecast = "5 ngày";
        data.pastRain = "12 giờ";
        data.warningThunderstormLightning = "1 giờ";
        data.marineWeather = null;
        data.insightNumber = 15;
        data.locationNumber = 4;
        data.userNumber = 2;
        data.notification = " qua email, zalo";
        data.API = "API: 2000 lượt gọi/ngày và 150 lượt gọi/giờ";
        data.hourApi = 150;
        break;
      case 2:
        data.price = 990000;
        data.priceOrigin = "990,000₫";
        data.priceDisplay = "990.000 vnđ/tháng";
        data.packageName = "METPLUS 2";
        data.expireDisplay = "1 tháng";

        data.weatherForecast = "7 ngày";
        data.pastRain = "24 giờ";
        data.warningThunderstormLightning = "2 giờ";
        data.marineWeather = "7 ngày";

        data.insightNumber = 20;
        data.locationNumber = 6;
        data.userNumber = 4;
        data.notification = " qua email, zalo";
        data.API = "API: 4000 lượt gọi/ngày và 400 lượt gọi/giờ";
        data.hourApi = 400;
        break;
      case 3:
        data.price = 2990000;
        data.priceOrigin = "2.990,000";
        data.priceDisplay = "2.990.000 vnđ/tháng";
        data.packageName = "METPLUS 3";
        data.expireDisplay = "1 tháng";

        data.weatherForecast = "10 ngày";
        data.pastRain = "72 giờ";
        data.warningThunderstormLightning = "3 giờ";
        data.marineWeather = "10 ngày";
        data.climateWeather = "30 ngày (1 khu vực)";

        data.insightNumber = 30;
        data.locationNumber = 10;
        data.userNumber = 6;
        data.notification = " qua email, zalo";
        data.API = "API: 8000 lượt gọi/ngày và 600 lượt gọi/ngày";
        data.hourApi = 600;
        break;

      case 4:
        data.price = 4990000;
        data.priceOrigin = "4.990,000";
        data.priceDisplay = "4.990.000 vnđ/tháng";
        data.packageName = "METPLUS 4";
        data.expireDisplay = "1 tháng";

        data.weatherForecast = "14 ngày";
        data.pastRain = "7 ngày";
        data.warningThunderstormLightning = "3 giờ";
        data.marineWeather = "14 ngày";
        data.climateWeather = "3 tháng (1 khu vực)";

        data.insightNumber = 40;
        data.locationNumber = 15;
        data.userNumber = 10;
        data.notification = " qua email, zalo";
        data.API = "API: 12000 lượt gọi/ngày và 800 lượt gọi/giờ";
        data.hourApi = 800;
        break;

      case 5:
        //data.price = 4990000;
        data.priceOrigin = "Theo yêu cầu";
        data.priceDisplay = "Theo yêu cầu";
        data.packageName = "METPLUS 5";
        data.expireDisplay = "1 tháng";

        data.weatherForecast = "tất cả các hạn dự báo";
        data.pastRain = "30 ngày";
        data.warningThunderstormLightning = "toàn quốc";
        data.marineWeather = "16 ngày";
        data.climateWeather =
          "chi tiết theo yêu cầu,hỗ trợ trực tiếp của chuyên gia khí tượng";

        data.insightNumber = "theo yêu cầu";
        data.locationNumber = "không hạn chế";
        data.userNumber = "theo yêu cầu";
        data.notification = " qua email, zalo";

        data.dataVip = "Nhiều lớp dữ liệu cao cấp theo yêu cầu";
        data.supportProfes = "Hỗ trợ trực tiếp từ chuyên gia khí tượng";
        data.service = "Dịch vụ được cấp theo yêu cầu";
        data.API = "API: Tích hợp theo yêu cầu";
        data.hourApi = "";
        break;

      default:
        break;
    }

    return data;
  }

  static makeLink(name) {
    return `/thoi-tiet-theo-nganh/${slugify(name.toLowerCase())}`;
  }

  static getCategories(data) {
    const arr = data.categories.map((category) => ({
      ...category,
      label: category.name,
      to: this.makeLink(category.name),
    }));
    return [
      {
        id: "",
        to: "/thoi-tiet-theo-nganh",
        label: "Giới thiệu",
        name: "Giới thiệu",
        isActive: true,
      },
      ...arr,
    ];
  }

    static getListProject(data) {
        const dataProject = {
            yearData: null,
            nameLocation:null,
            namePartner: null,
            nameProject: null,
            lat: null,
            lon: null,
            urlLogo: null,
            resultEvaluation: null,
            sourceFileData:null,
            dataElement:null,
            noteProject:null,
            description:null,
            add:null
        };
        switch (data) {
            case "dau_khi":
                dataProject.nameLocation = ["","Block 05.1a"]
                dataProject.yearData = ["2023","2024"];
                dataProject.namePartner=["","PVEP - POC"];
                dataProject.nameProject=["Đảo Phú Qúy","Mỏ Đại Hùng"];
                dataProject.lat=["10.5°N","8.5°N"];
                dataProject.lon = ["108.9°E"," 108.6°E"];
                // dataProject.urlLogo = "";
                dataProject.add = [[],[{urlFile:reportDaiHungSeptember, nameFile:"September_2024_DaiHung_Block05.1a.pdf"},{urlFile:reportDaiHungAugust, nameFile:"August_2024_DaiHung_Block05.1a.pdf"}, {urlFile:reportDaiHungJuly, nameFile:"July_2024_DaiHung_Block05.1a.pdf"},{urlFile:reportDaiHungComprehensive, nameFile:"Comprehensive_2024_DaiHung_Block05.1a.pdf"}]];
                dataProject.resultEvaluation = [
                    [[null,null,null,null,null,null,96,95,92,null, null, null],[null,null,null,null,null,null,96,95,92,null, null, null]],
                    [[null,null,null,null,null,null,94,96,92,null, null, null],[null,null,null,null,null,null,94,96,92,null, null, null]]
                ];
                dataProject.description = [null,null,null,null,null,null,<span>
                  Theo bản tin dự báo dài hạn của Weather Plus trong tháng 7/2023, Việt Nam sẽ đón nhận khoảng 1-2 đợt Áp thấp nhiệt đới/ Bão hoạt động vào giữa tháng trên khu vực Biển Đông và có nguy cơ tác động đến các khu vực Bắc Bộ và phía Bắc miền Trung.
                  <br/>
                  Trên thực tế vào ngày 15/07/2023 Áp thấp nhiệt đới trên Biển Đông đã mạnh lên thành bão và trở thành cơn Bão số 1 có tên quốc tế là Talim, biển động khiến cho tàu thuyền ra vào khu vực Phú Quý phải hủy chuyến.
              </span>,
                    <span>
                      Trong tháng 8 năm 2023, dự báo khu vực Biển Đông sẽ tiếp tục đón 2-3 cơn bão, áp thấp nhiệt đới.<br/>
                        Trên thực tế Việt Nam đón nhận 2 cơn bão đó là:
                        <ul className={"ul-description-weather-report"}>
                            <li>
                                Bão Doksuri, cơn bão số 2 trong năm 2023, đã hình thành vào ngày 26/7/20231. Bão Doksuri đã đi vào khu vực Đông Bắc của Bắc Biển Đông có khả năng mạnh lên cấp 15-16, tức cấp siêu bão.<br/> Tuy nhiên, bão Doksuri không ảnh hưởng nhiều tới nước ta.
                            </li>
                            <li>Bão Saola. cơn bão số 3 trong năm 2023, đã hình thành vào ngày 30/8/20231. Bão Saola đã đi vào khu vực Đông Bắc của Bắc Biển Đông, biển động nhiều ngày. </li>
                        </ul>
                      Mặc dù bão xuát hiện trên Biển Đông nhưng với dự báo của Weather Plus khu vực Phú Quý ít bị ảnh hưởng, thâm chí không bị ảnh hưởng bởi 2 cơn bão nói trên, nên tàu thuyền không bị hủy chuyến nào trong tháng này. Cụ thể đối với chất lượng dự báo gió và sóng như sau:
                      <ul className={"ul-description-weather-report"}>
                          <li>Tốc độ gió 10m: 93%</li>
                          <li>Sóng lừng: 99%</li>
                          <li>Sóng Sig: 95%</li>
                          <li>Sóng Max: 95%</li>
                      </ul>
                  </span>,
                    <span>
                      <span className={"textDescriptionDetail"}>
                      Trong tháng 9 năm 2023, bão và áp thấp nhiệt đới tiếp tục ảnh hưởng nhiều đến vùng biển Việt Nam trong đó dự báo có khả năng xuất hiện từ 1-2 cơn/ Áp thấp nhiệt đới trên khu vực Biển Đông (bao gồm cả cơn bão số 3 - bão SAOLA) và có thể ảnh hưởng đến khu vực Trung Bộ.
                      </span>
                          <br/>
                      <span className={"textDescriptionDetail"}>Bên cạnh đó những đợt gió mùa đầu tiên cũng được dự báo tràn về vào cuối tháng 9, biển động.</span>
                      <br/>
                      <span className={"textDescriptionDetail"}>Do vậy nhìn chung tháng 9 biển động khá nhiều đặc biệt vào đầu tháng (ảnh hưởng của bão số 3 SAOLA) và cuối tháng
                      (Do gió mùa đông bắc và áp thấp nhiệt đới) nhưng tỉ lệ chính xác về dữ liệu của Weather Plus vẫn đạt mức tin tưởng cao - Ở ngưỡng rất tốt:</span>
                    <ul className={"ul-description-weather-report"}>
                        <li>Hướng gió: 90%</li>
                        <li>Tốc độ gió (10m): 90%</li>
                        <li>Tốc độ gió giật (10m) 90%</li>
                        <li>Nhiệt độ: 90%</li>
                        <li>Áp suất không khí: 96%</li>
                        <li>Sóng lừng: 97%</li>
                        <li>Sóng Sig: 91%</li>
                        <li>Sóng Max: 91%</li>
                    </ul>
                  </span>,null, null, null]
                dataProject.sourceFileData=[vtvFileDataVerify,null,null];
                dataProject.noteProject=["(Lân cận khu vực khai thác dầu khí)"]
                dataProject.dataElement= [
                    [
                        {
                            urlIcon:iconTempReport,
                            title:"Nhiệt độ",
                            keyActive:"temp",
                            unit:"°C",
                            description:"",
                            typeCompares:[
                                {type:"Nhiệt độ",indexFileForecast:'V17', indexFileReal:'W17',}],
                        },
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 10m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'M17', indexFileReal:'O17',},
                                {type:"Knot",indexFileForecast:'L17', indexFileReal:'N17'},
                            ]
                        },
                        {
                            urlIcon:iconDirWind,
                            title:"Hướng gió 10m",
                            keyActive:"windDir",
                            unit:"°",
                            description:"",
                            typeCompares: [
                                {type:"Hướng gió", indexFileForecast:'H17', indexFileReal:'J17',},
                            ]
                        },
                        {
                            urlIcon:iconWindGale,
                            title:"Gió giật 10m",
                            keyActive:"windGale",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'R17', indexFileReal:'T17',},
                                {type:"Knot",indexFileForecast:'Q17', indexFileReal:'S17'},
                            ]
                        },
                        {
                            urlIcon:iconPresure,
                            title:"Áp suất không khí",
                            keyActive:"presure",
                            unit:"hPa",
                            description:"",
                            typeCompares: [
                                {type:"Áp suất", indexFileForecast:'Y17', indexFileReal:'Z17',},
                            ]
                        },
                        {
                            urlIcon:iconWaveSwell,
                            title:"Sóng lừng",
                            keyActive:"waveGust",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AC17', indexFileReal:'AE17'},
                                {type:"Metter", indexFileForecast:'AB17', indexFileReal:'AD17',},
                            ]
                        },
                        {
                            urlIcon:iconWaveSig,
                            title:"Sóng SIG",
                            keyActive:"waveSig",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AH17', indexFileReal:'AJ17'},
                                {type:"Metter", indexFileForecast:'AG17', indexFileReal:'AI17'},
                            ]
                        },
                        {
                            urlIcon:iconWaveMax,
                            title:"Sóng MAX",
                            keyActive:"waveMax",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AM17', indexFileReal:'AO17'},
                                {type:"Metter", indexFileForecast:'AL17', indexFileReal:'AN17',},
                            ]
                        },
                    ],
                    [
                        {
                            urlIcon:iconTempReport,
                            title:"Nhiệt độ",
                            keyActive:"temp",
                            unit:"°C",
                            description:"",
                            typeCompares:[
                                {type:"Nhiệt độ",indexFileForecast:'V17', indexFileReal:'W17',}],
                        },
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 10m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'M17', indexFileReal:'O17',},
                                {type:"Knot",indexFileForecast:'L17', indexFileReal:'N17'},
                            ]
                        },
                        {
                            urlIcon:iconDirWind,
                            title:"Hướng gió 10m",
                            keyActive:"windDir",
                            unit:"°",
                            description:"",
                            typeCompares: [
                                {type:"Hướng gió", indexFileForecast:'H17', indexFileReal:'J17',},
                            ]
                        },
                        {
                            urlIcon:iconWindGale,
                            title:"Gió giật 10m",
                            keyActive:"windGale",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'R17', indexFileReal:'T17',},
                                {type:"Knot",indexFileForecast:'Q17', indexFileReal:'S17'},
                            ]
                        },
                        {
                            urlIcon:iconPresure,
                            title:"Áp suất không khí",
                            keyActive:"presure",
                            unit:"hPa",
                            description:"",
                            typeCompares: [
                                {type:"Áp suất", indexFileForecast:'Y17', indexFileReal:'Z17',},
                            ]
                        },
                        {
                            urlIcon:iconWaveSwell,
                            title:"Sóng lừng",
                            keyActive:"waveGust",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AC17', indexFileReal:'AE17'},
                                {type:"Metter", indexFileForecast:'AB17', indexFileReal:'AD17',},
                            ]
                        },
                        {
                            urlIcon:iconWaveSig,
                            title:"Sóng SIG",
                            keyActive:"waveSig",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AH17', indexFileReal:'AJ17'},
                                {type:"Metter", indexFileForecast:'AG17', indexFileReal:'AI17'},
                            ]
                        },
                        {
                            urlIcon:iconWaveMax,
                            title:"Sóng MAX",
                            keyActive:"waveMax",
                            unit:"m",
                            description:"",
                            typeCompares: [
                                {type:"Cấp sóng",indexFileForecast:'AM17', indexFileReal:'AO17'},
                                {type:"Metter", indexFileForecast:'AL17', indexFileReal:'AN17',},
                            ]
                        },
                    ]
                ]
                break;
            case "dien_gio":
                dataProject.nameLocation = ["","","",""]
                dataProject.yearData = ["2023","2023","2023","2023"];
                dataProject.namePartner= ["","","",""];
                dataProject.nameProject=["WTG01", "WTG02", "WTG06","WTG12"];
                dataProject.lat= ["","","",""];
                dataProject.lon = ["","","",""];
                // dataProject.urlLogo = LogoPartnerReport;
                dataProject.resultEvaluation = [
                    [[null,null,null,null,null,null,98,99,null,null, null, null],[null,null,null,null,null,null,86,88,null,null, null, null]],
                    [[null,null,null,null,null,null,99,99,null,null, null, null],[null,null,null,null,null,null,84,87,null,null, null, null]],
                    [[null,null,null,null,null,null,98,98,null,null, null, null],[null,null,null,null,null,null,83,83,null,null, null, null]],
                    [[null,null,null,null,null,null,98,98,null,null, null, null],[null,null,null,null,null,null,81,82,null,null, null, null]],
                ];
                dataProject.description = [null,null,null,null,null,null,null,null,null,null, null, null]
                dataProject.sourceFileData=[windElectricDataVerify,windElectricDataVerify,windElectricDataVerify,windElectricDataVerify];
                dataProject.dataElement = [
                    [
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 100m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'H17', indexFileReal:'J17',},
                                {type:"Knot",indexFileForecast:'G17', indexFileReal:'I17'},
                            ]
                        },
                    ],
                    [
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 100m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'N17', indexFileReal:'P17',},
                                {type:"Knot",indexFileForecast:'M17', indexFileReal:'O17'},
                            ]
                        },
                    ],
                    [
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 100m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'T17', indexFileReal:'V17',},
                                {type:"Knot",indexFileForecast:'S17', indexFileReal:'U17'},
                            ]
                        },
                    ],
                    [
                        {
                            urlIcon:iconWindSpeed,
                            title:"Tốc độ gió 100m",
                            keyActive:"windSpeed",
                            unit:"knot",
                            description:"",
                            typeCompares: [
                                {type:"Cấp gió", indexFileForecast:'Z17', indexFileReal:'AB17',},
                                {type:"Knot",indexFileForecast:'Y17', indexFileReal:'AA17'},
                            ]
                        },
                    ],
                ]
                break;
            default:
                break;
        }
        return dataProject;
    };
    static getColorResultVerify(data) {
        const dataResult = {
            color:"transparent",
            background:null
        };

        if (data === null) {
            dataResult.color = "rgb(210,210,210)";
        }
          else if (data >= 0 && data <= 30) {
            dataResult.color = "rgba(112, 48, 160)";
        } else if (data > 30 && data < 51) {
            dataResult.color = "rgba(255, 0, 0)";
        } else if (data > 50 && data < 71) {
            dataResult.color = "rgba(255, 192, 0)";
        } else if (data > 70 && data < 81) {
            dataResult.color = "rgba(226, 239, 218)";
        } else if (data > 80 && data < 91) {
            dataResult.color = "rgba(169, 208, 142)";
        } else if (data > 90 && data <= 100) {
            dataResult.color = "rgba(155, 194, 230)";
        }
        return dataResult;
    }
}

export default Utils;
