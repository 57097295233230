import {makeStyles} from "@material-ui/core/styles";
import AppContext from "app/app_context";
import styles from "assets/jss/weatherplus/layouts/authStyle.js";
import Footer from "components/Footer/Footer.js";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {matchRoutes} from "react-router-config";
import {useLocation} from "react-router-dom";
import i18n from "../../i18n";

import HeaderAppBar from "../../components/Header/HeaderAppBar";

const useStyles = makeStyles(styles);

const AuthLayout = (props) => {
    const appContext = useContext(AppContext);
    const {routes} = appContext;
    const location = useLocation();
    const {pathname} = location;
    const matched = matchRoutes(routes, pathname)[0];
    let layout = {
        appBar: true,
        footer: true,
    };
    if (matched && matched.route.settings) {
        const routeSettings = matched.route.settings;
        if (routeSettings.layout) {
            layout = routeSettings.layout;
        }
    }
    const classes = useStyles();
    const wrapper = useRef();
    const language = useSelector(({settings}) => settings.language);

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language.toLowerCase());
        }
    }, [language]);

    return (
        <div>
            {layout.appBar && <HeaderAppBar/>}
            <div className={classes.wrapper} ref={wrapper}>
                <div className={classes.fullPage}>
                    {props.children}
                </div>
            </div>
            {layout.footer && <Footer/>}
        </div>
    );
};

AuthLayout.displayName = "AuthLayout";

AuthLayout.propTypes = {children: PropTypes.node.isRequired};

export default AuthLayout;
