// ##############################
// // // Variables - Styles that are used on more than one components
// #############################

const drawerWidth = 260;

const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f",
];
const whiteColor = "#FFF";
const blackColor = "#000";

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const containerFluid = {
  paddingRight: "10px",
  paddingLeft: "10px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
};
const containerFluidDHeader = {
  marginRight: "auto",
  marginLeft: "auto",
};
const container = {
  ...containerFluid,
  "@media (min-width: 1280px)": {
    maxWidth: "1200px",
  },
};
const containerNew = {
  "@media (min-width: 1280px)": {
    maxWidth: "1200px",
  },
  minHeight: "50px",
  alignItems: "center",
  justifyContent: "space-between",
  // display: "flex",
  // flexWrap: "nowrap",
}
const containerHeaderDesktop = {
  ...containerFluidDHeader,
  height:"100%"
};
const defaultFont = {
  fontFamily: "\"Nunito\", \"Roboto\", \"Open Sans\", \"Mulish\", \"Quicksand\", \"Helvetica\", \"Arial\", sans-serif",
  fontWeight: "500",
  lineHeight: "1.5em",
};

const title = {
  color: grayColor[1],
  textDecoration: "none",
  fontWeight: "700",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: `"Nunito", "Times New Roman", serif`,
};

const main = {
  position: "relative",
  zIndex: "3",
};

const mlAuto = {
  marginLeft: "auto",
};

const buttonGroup = {
  display: "flex",
  justifyContent: "space-between",
  margin: "24px 0px",
};

const buttonGroupRight = {
  display: "flex",
  justifyContent: "flex-end",
  margin: "24px 0px",
};

export {
  //variables
  drawerWidth,
  transition,
  container,
    containerNew,
  containerFluid,
  containerHeaderDesktop,
  defaultFont,
  grayColor,
  whiteColor,
  blackColor,
  title,
  main,
  mlAuto,
  buttonGroup,
  buttonGroupRight,
};
