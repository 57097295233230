import axios from "axios";

import { wpDomainApi } from "app/configs/wp.config";

const version = "v1";

const wpApi = axios.create({
  baseURL: `${wpDomainApi}/api/${version}`,
  headers: { "X-Requested-With": "XMLHttpRequest" },
  withCredentials: true,
});

export const getNowCasting = async () => {
  const res = await wpApi.get("/weather/map/nowcasting");

  return res.data;
};

export const getNowCasting10Minutes = async ({ lat, lng }) => {
  const res = await wpApi.get("/weather/map/nowcasting-10minutes", {
    params: {
      lat,
      lng,
    },
  });

  return res.data;
};

export const getWeatherOnMap = async (bound, zoom) => {
  const res = await wpApi.post("/weather/map", { bound, zoom });

  return res.data;
};

export const getWeatherHistory = async (locations) => {
  const res = await wpApi.post("/weather/history", { locations });

  return res.data;
};

export const getCurrentWeather = async (location) => {
  const res = await wpApi.get("/weather/now", { params: { ...location } });

  return res.data;
};

export const getCurrentAQI = async (location) => {
  const res = await wpApi.get("/weather/now/aqi", { params: { ...location } });

  return res.data;
};

export const getCurrentUVD = async (location) => {
  const res = await wpApi.get("/weather/now/uvd", { params: { ...location } });

  return res.data;
};

export const getTodayWeather = async (location) => {
  const res = await wpApi.get("/weather/today", { params: { ...location } });

  return res.data;
};

export const getNextHoursWeather = async (location) => {
  const res = await wpApi.get("/weather/hour/24", { params: { ...location } });

  return res.data;
};

export const getNextDaysWeather = async (location) => {
  const res = await wpApi.get("/weather/day/15", { params: { ...location } });

  return res.data;
};

export const get90DaysWeather = async (location) => {
  const res = await wpApi.get("/weather/day/90", { params: { ...location } });

  return res.data;
};

export const getNewsCategories = async (language) => {
  const res = await wpApi.get("/post/category", { params: { language: "VI" } });

  return res.data;
};

export const getRecentNews = async (language) => {
  const res = await wpApi.get("/post/recent", { params: { language: "VI" } });

  return res.data;
};
export const getStormNews = async (language) => {
  const params = { url:"tin-bao",language: "VI"};
  const res = await wpApi.get("/post",{params});

  return res.data
}

export const getNews = async (url, language, page) => {
  const params = { url, language: "VI" };
  if (page) params.page = page;

  const res = await wpApi.get("/post", { params });

  return res.data;
};

export const getMostViewNews = async (language,page) =>{
  const params = {url:"khi-hau-viet-nam",language: "VI"};
  const res = await wpApi.get("/post",{params})

  return res.data;
}

export const listNews = async (tagId, page, limit, language) => {
  const res = await wpApi.get("/post/list", {
    params: { tagId, page, limit, language: "VI" },
  });

  return res.data;
};

export const getSmartGenData = async ({ lat, lng }) => {
  const res = await wpApi.get("/weather/smartgen", {
    params: { lat, lng },
  });

  return res.data;
};

export const getSolutionsData = async () => {
  const res = await wpApi.get("/solution");

  return res.data;
};
export const getSolutionsDataDetail = async ({id}) => {
  const res = await wpApi.get("/solution/detail",{params:{id}});

  return res.data
}
export default {
  getWeatherOnMap,
  getWeatherHistory,
  getCurrentWeather,
  getCurrentAQI,
  getCurrentUVD,
  getTodayWeather,
  getNextHoursWeather,
  getNextDaysWeather,
  get90DaysWeather,
  getNewsCategories,
  getRecentNews,
  getNews,
  listNews,
  getSmartGenData,
  getSolutionsData,
  getSolutionsDataDetail,
  getNowCasting10Minutes,
  getStormNews,
  getMostViewNews
};
