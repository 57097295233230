import React from "react";
import i18next from "i18next";

import vi from "./i18n/vi";
import en from "./i18n/en";
import authRoles from "app/auth/auth_roles";

i18next.addResourceBundle("vi", "weather", vi);
i18next.addResourceBundle("en", "weather", en);

const subDomain = "/(thoi-tiet-nha-nong|agri-weather)";

const homeConfig = {

  auth: authRoles.onlyGuest,
  routes: [
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/(hien-tai|now)",
      exact: true,
      component: React.lazy(() => import("./CurrentAgriWeatherPage")),
    },
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/(theo-gio|hourly)",
      exact: true,
      component: React.lazy(() => import("./HourlyAgriWeatherPage")),
    },
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/(ngay-mai|tomorrow)",
      exact: true,
      component: React.lazy(() => import("./TomorrowAgriWeatherPage")),
    },
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/(90-ngay|90-days)",
      exact: true,
      component: React.lazy(() => import("./MonthlyAgriWeatherPage")),
    },
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/:days-ngay-toi",
      exact: true,
      component: React.lazy(() => import("./DailyAgriWeatherPage")),
    },
    {
      path: subDomain + "/(thoi-tiet|weather)-:location/next-:days-days",
      exact: true,
      component: React.lazy(() => import("./DailyAgriWeatherPage")),
    },
  ],
};

export default homeConfig;
