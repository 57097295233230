import {grayColor, mlAuto} from "assets/jss/weatherplus.js";

const headerLinksStyle = (theme) => ({
    list: {
        [theme.breakpoints.up("md")]: {
            WebkitBoxAlign: "center",
            MsFlexAlign: "center",
            marginLeft: "13vw",
            justifyContent: "space-around",
            WebkitBoxOrient: "horizontal",
            WebkitBoxDirection: "normal",
            MsFlexDirection: "row",
            flexDirection: "row",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
        marginTop: "0px",
        display: "flex",
        paddingLeft: "0",
        marginBottom: "0",
        listStyle: "none",
        padding: "0",
    },
    listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        margin: "0",
        padding: "0",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            display: "block",
            justifyContent: "unset",
            alignItems: "unset",
            height: "unset",
            "&:hover,&:focus,&:active": {
                borderBottom: "unset"
            },
            "& ul": {
                maxHeight: "400px",
                overflow: "scroll",
            },
            width: "100%",
            "&:not(:last-child)": {
                "&:after": {
                    width: "calc(100% - 20px)",
                    content: '""',
                    display: "block",
                    height: "1px",
                    marginLeft: "15px",
                    backgroundColor: grayColor[14],
                },
            },
        },
    },
    listItemMobileOverride: {
        "&:not(:last-child):after": {
            width: 0, // Override width to hide the line
            content: '""',
            display: "none", // Ensure it doesn't display
        },
    },
    listItemText: {
        padding: "0 !important",
    },
    navLink: {
        fontFamily: "Quicksand",
        color: "#78768F",
        position: "relative",
        padding: "13px 14px",
        fontSize: "18px",
        fontWeight: "700",
        textTransform: "initial",
        lineHeight: 1.2,
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        "&:hover": {
            color: "#503B8F",
            [theme.breakpoints.down("sm")]: {
                color: "#78768F",
            },
        },
        "&:focus": {
            color: "#503B8F",
        },
        "& .fab,& .far,& .fal,& .fas,& .material-icons": {
            position: "relative",
            top: "2px",
            marginTop: "-4px",
            marginRight: "4px",
            marginBottom: "0px",
            fontSize: "1.25rem",
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            fontFamily: "Quicksand",
            color: "#727272",
            letterSpacing: "0.5px",
            "& > span:first-child": {
                justifyContent: "flex-start",
            },
        },
        "& svg": {
            marginRight: "80px",
            width: "24px",
            height: "24px",
        },
    },
    navLinkActive: {
        fontFamily: "Quicksand",
        color: "#503B8F !important",
        position: "relative",
        padding: "13px 14px",
        fontWeight: "700",
        fontSize: "18px",
        textTransform: "initial",
        lineHeight: 1.2,
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        [theme.breakpoints.down("sm")]: {
            fontFamily: "Quicksand",
            letterSpacing: "0.5px",
        },
    },
    collapse: {
        [theme.breakpoints.up("md")]: {
            // display: "flex !important",
            MsFlexPreferredSize: "auto",
            // flexBasis: "auto",
        },
        WebkitBoxFlex: "1",
        MsFlexPositive: "1",
        flexGrow: "1",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    mlAuto,
    brandImage: {
        height: 45,
        weight: 175,
        // marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            height: 35,
            marginLeft: 15,
            marginRight: 40,
            paddingLeft: 0,
        },
    },
    megaMenu: {
        marginTop: 65,
    },
    containerMenu: {
        display: "flex",
        justifyContent: "center"
    },
    contentCommonMenu: {
        maxWidth: 1355,
        width: "100%",
        borderTop: "1px solid #D7D7D7"
    },
    titleMainMenu: {
        fontFamily: "Quicksand",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: 1.4,
        color: "#503B8F",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            fontFamily: "Quicksand",
        },
    },
    subTitleMainMenu: {
        borderLeft: "1px solid rgba(145, 145, 145, 0.5)",
    },
    noteLink: {
        background: "#E9EAEC",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "69px"
    },
    containerNoteLink: {
        maxWidth: 1355,
        width: "100%",
    },
    forecastMenu: {
        "& .MuiPaper-root": {
            top: "68px !important",
            left: "0px !important",
            // borderRadius: "8px",
            width: "100%",
            maxWidth: "100%",
            background: "rgba(255, 255, 255, 0.95)",
            backdropFilter: "blur(50px)",
            boxShadow: "none",
        },
        "& .MuiMenu-list": {
            padding: "0px"
        },
        // "& a": {
        //     color: "inherit",
        //     "&:hover,&:focus": {
        //         color: "#87B23F",
        //     },
        // },
        // "& .MuiList-root": {
        //     "& .MuiMenuItem-root": {
        //         color: "inherit",
        //     },
        // },
    },
    productMenu: {
        "& .MuiPaper-root": {
            top: "68px !important",
            left: "0px !important",
            // borderRadius: "8px",
            width: "100%",
            maxWidth: "100%",
            background: "rgba(255, 255, 255, 0.95)",
            backdropFilter: "blur(50px)",
            boxShadow: "none"
        },
        "& .MuiMenu-list": {
            padding: "0px"
        },
        "& a": {
            color: "inherit",
            "&:hover,&:focus": {
                color: theme.palette.primary.mainAlt,
            },
        },
        "& .MuiList-root": {
            "& .MuiMenuItem-root": {
                color: "inherit",
            },
        },
    },
    weatherCareerMenu: {
        // overflow: "auto",
        "& .MuiPaper-root": {
            top: "81px !important",
            left: "43% !important",
            borderRadius: "8px",
        },
        "& a": {
            color: "inherit",
            "&:hover,&:focus": {
                color: "#503B8F",
                // color: theme.palette.primary.mainAlt,
            },
        },
        "& .MuiList-root": {
            "& .MuiMenuItem-root": {
                color: "inherit",
            },
        },
    },
    noneDisplay: {
        display: "none",
    },
    boldMenu: {
        fontWeight: "900",
        borderBottom: "1px solid #d0c7c7 !important",
        borderRadius: "0",
        fontSize: "1.1rem !important",
    },
    subMegaMenu: {
        fontFamily: "Quicksand",
        color: "#78768F",
        display: "block",
        textAlign: "left",
        padding: "5px 130px 5px 5px",
        // marginLeft: "15px",
        marginRight: "20px",
        width: "260px !important",
        textTransform: "none",

        fontWeight: "700",
        fontSize: "18px",
        lineHeight: 1.2,

        [theme.breakpoints.down("sm")]: {
            padding: "0px 10px",
            margin: "0",
            width: "auto",
            fontFamily: "Quicksand",
            color: "#78768F",
        },
        "&:hover, &:active": {
            color: "#87B23F !important",
        },
    },
    subMegaMenuImportant: {
        fontFamily: "Quicksand",
        display: "block",
        textAlign: "left",
        padding: "5px 130px 5px 5px",
        // marginLeft: "15px",
        marginRight: "20px",
        width: "260px !important",
        textTransform: "none",

        fontWeight: "700",
        fontSize: "18px",
        lineHeight: 1.2,
        // color: "#64AF02 !important",
        color: "#87B23F !important",
        [theme.breakpoints.down("sm")]: {
            padding: "0px 10px",
            margin: "0",
            width: "auto",
            fontFamily: "Quicksand",
        },
    },
    textImportant: {
        fontWeight: "550 !important",
        textDecorationLine: "underline"
    },
    iconMenu: {
        marginLeft: "0px !important",
    },
    "& .MuiButton-endIcon": {
        marginLeft: "0px",
    },
    iconMenuMobile: {
        // height:"100%",
        padding: "15px 0px !important",
        "&:hover": {
            "& svg": {
                color: "#503B8F",
                // color: theme.palette.primary.mainAlt,
            },
        },
        "& svg": {
            color: "inherit",
        },
    },
    detailMenuMobile: {
        // overflow: "auto",
        color: "#78768F",
        "& a": {
            color: "inherit",
            "&:hover": {
                color: "#503B8F",
                // color: theme.palette.primary.mainAlt,
            },
        },
        "& .MuiTypography-root": {
            fontFamily: "Quicksand",
            fontWeight: "700",
            color: "inherit",
        },
    },
    iconArrowDown: {
        marginBottom: "4px",
    },
    coverSocial: {
        background: "#E3FBC2"
    },
    socialIcon: {
        width: 31,
        height: 28,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        color: "#FFFFFF",
        marginRight: 3,
        transition: "all 300ms ease-out",
        "&:focus": {
            color: theme.palette.primary.contrastText,
            backgroundColor: "transparent",
        },
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
        },
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
    frameSocial: {
        padding: "4px 10px 2px 10px"
    },
    containerSocial: {
        padding: "13px 10px"
    },
    dropdownIcon: {
        height: "24px", width: "24px", marginLeft: "1px",
    },
    popOverRoot: {
        pointerEvents: "none",
    },
});

export default headerLinksStyle;
