import React from "react";
import {makeStyles, useMediaQuery} from "@material-ui/core";

import {Email, Place, Smartphone} from "@material-ui/icons";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import {container} from "assets/jss/weatherplus.js";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

import {useSelector} from "react-redux";

import {ReactComponent as FacebookIcon} from "assets/img/facebook.svg";
import {ReactComponent as YoutubeIcon} from "assets/img/youtube.svg";
import {ReactComponent as EnvelopeIcon} from "assets/img/envelope.svg";
import {Helmet} from "react-helmet";
import Layer1 from "assets/img/Layer_1.png";
import Layer2 from "assets/img/Layer2.png";
import BCT from "assets/logo/logoSaleNoti.png";
import Logo3 from "assets/logo/WP_logo_no-slogan_on-violet.png";
import {Languages} from "../../app/values/settings";

const useStyles = makeStyles((theme) => ({
    root: {
        background: `#503B8F url('${Layer1}') no-repeat right center`,
        padding: "50px 0px 0px 0px",
        color: "rgba(255, 255, 255, 0.87)",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "24px",
            background: `#503B8F url('${Layer2}') no-repeat right bottom`,
        },
    },
    container: {
        ...container,
        maxWidth: "1600px !important",
    },
    border: {
        [theme.breakpoints.up("lg")]: {
            padding: "0px 40px",
        },
        [theme.breakpoints.down("md")]: {
            margin: "56px 0px",
            padding: "56px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "36px 0px",
            padding: "0",
        },

    },
    logo: {
        marginBottom: 24,
        height: 60,
    },
    text: {
        color: "#FFFFFF",
        fontSize: 16,
        textAlign: "justify",
        textJustify: "inter-word",
        lineHeight: 2,
        letterSpacing: 0,
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            textAlign: "start",
        },
    },
    text2: {
        color: "#E6DCF9",
        fontSize: 16,
        textAlign: "justify",
        textJustify: "inter-word",
        lineHeight: 2,
        letterSpacing: 0,
        fontWeight: 400
    },
    heading: {
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 700,
        paddingBottom: "10px",
        color: "#87B23F"
    },
    phone: {
        fontSize: 32,
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: 0,
        color: theme.palette.primary.light,
    },
    info: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 16,
    },
    infoProfile: {
        display: "flex",
        // alignItems: "flex-start",
        marginBottom: 16,
        alignItems: "center"
    },
    icon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.light,
        marginRight: 5,
        marginTop: 2,
    },
    gridContainer: {
        marginRight: "-20px",
        marginLeft: "-20px",
        "& a:hover": {
            color: "#ffffff",
            textDecoration: "none"
        }
    },
    gridItem: {
        paddingRight: "5rem",
        paddingLeft: "3rem",
        marginRight: "2rem",
        [theme.breakpoints.down("sm")]: {
            paddingRight: "2rem",
            paddingLeft: "2rem",
            marginRight: "0",
        },
    },
    gap16: {
        marginBottom: 16,
    },
    gap8: {
        marginBottom: 8,
    },
    gap24: {
        marginBottom: 8,
    },
    button: {
        fontSize: 16,
        textTransform: "none",
        borderRadius: 2,
        padding: "12px 24px 13px 24px !important",
        fontWeight: 600,
    },
    noneShadow: {
        boxShadow: "none !important",
    },
    socialIcon: {
        width: 36,
        height: 36,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        boxShadow: "0 0 18px 0 rgba(0,0,0,.3) !important",
        color: theme.palette.primary.contrastText,
        marginRight: 15,
        marginBottom: 4,
        borderRadius: 4,
        transition: "all 300ms ease-out",
        border: "1px solid #ffffff",
        "&:focus": {
            color: theme.palette.primary.contrastText,
            backgroundColor: "transparent",
        },
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
        },
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
    downloadProfileIcon: {
        width: 52,
        height: 42,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        boxShadow: "0 0 18px 0 rgba(0,0,0,.3) !important",
        color: theme.palette.primary.contrastText,
        marginRight: 15,
        marginBottom: 4,
        transition: "all 300ms ease-out",
        "&:focus": {
            color: theme.palette.primary.contrastText,
            backgroundColor: "transparent",
        },
        "&:hover": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.light,
        },
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
    downloadIcon: {
        paddingLeft: 6
    },
    dmca: {
        margin: "10px 5px 0px 0px",
        // display: "flex",
    },
    clearBoth: {
        clear: "both",
    },
    brandImage: {
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "68%",
        },
    },
}));

const Footer = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    const {t} = useTranslation("common");
    const language = useSelector(({settings}) => settings.language);
    return (
        <footer className={classes.root}>
            <Helmet>
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
            </Helmet>
            <div className={classes.container}>
                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} lg={5} className={classes.gridItem}>
                        <div style={{marginBottom: 10}}>
                            <img
                                className={classes.brandImage}
                                src={Logo3}
                                alt="Weatherplus"
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.gridContainer} style={{marginBottom: 40}}>
                    <GridItem xs={12} lg={5} className={classes.gridItem}>
                        <Typography className={classes.text}>
                            {t("Company name")}
                        </Typography>
                        <Typography className={classes.text} style={{marginBottom: 30}}>
                            {t("Business registration")}{isMobile ? " " : <br/>}{t("Confirmed by")}
                        </Typography>
                        <div className={classes.info}>
                            <Place className={classes.icon}/>
                            <Typography className={classes.text}>{t("Address company1")}{isMobile ? " " : <br/>}{t("Address company2")}</Typography>
                        </div>
                        <div className={classes.info}>
                            <Smartphone className={classes.icon}/>
                            <Typography className={classes.text}>
                                (+84) 24 37 955 069
                            </Typography>
                        </div>
                        <div className={classes.info}>
                            <Email className={classes.icon}/>
                            <Typography className={classes.text}>
                                kinhdoanh@weatherplus.vn
                            </Typography>
                        </div>
                    </GridItem>
                    <GridItem xs={12} lg={3} className={classes.gridItem}>
                        <div className={classes.border}>
                            <div>
                                <Typography className={classes.heading}>
                                    {t("About")}
                                </Typography>
                            </div>

                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("About us2")}
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    component={"a"}
                                    href={(language === Languages.vi ? "/ve-chung-toi/gioi-thieu-cong-ty" : "/about-us/weatherplus") + "#d"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Leadership")}
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/ve-chung-toi/doi-ngu-chuyen-gia" : "/about-us/experts"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Experts")}
                                </Typography>
                            </div>

                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/ve-chung-toi/doi-tac" : "/about-us/partners"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Partners")}
                                </Typography>
                            </div>

                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/ve-chung-toi/lien-he" : "/about-us/contact"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Contact")}
                                </Typography>
                            </div>
                        </div>
                    </GridItem>
                    <GridItem xs={12} lg={3} className={classes.gridItem} style={{marginLeft: isMobile ? "0" : "2rem"}}>
                        <div>
                            <div>
                                <Typography className={classes.heading}>
                                    {t("Products")}
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/giai-phap/giai-phap-cho-doanh-nghiep" : "/solutions/solutions-for-businesses"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Products for businesses")}
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/giai-phap/giai-phap-nong-nghiep" : "/solutions/solutions-for-agriculture"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Products for agriculture")}
                                </Typography>
                            </div>
                            <div>
                                <Typography
                                    component={"a"}
                                    href={language === Languages.vi ? "/giai-phap/giai-phap-thuy-dien" : "/solutions/solutions-for-hydropower"}
                                    className={`${classes.text} ${classes.gap24}`}
                                >
                                    {t("Products for hydropower")}
                                </Typography>
                            </div>
                            {!isMobile &&
                                <a href={"http://online.gov.vn/Home/WebDetails/84850?AspxAutoDetectCookieSupport=1"}><img
                                    src={BCT} alt="BCT" style={{width: 180, marginTop: 10}}/></a>}
                        </div>
                    </GridItem>
                </GridContainer>
                {isMobile && <GridContainer>
                    <GridItem xs={12} className={classes.gridItem} style={{paddingLeft: "20px", marginBottom: "30px"}}>
                        <div>
                            <span className={classes.text} style={{fontSize: 18, fontWeight: "bold"}}>Follow us</span>
                            <Typography>
                                <a
                                    href="https://www.youtube.com/channel/UC4qb3ZpsPfcnAJGZx7AjZFw"
                                    className={classes.socialIcon}
                                >
                                    <YoutubeIcon/>
                                </a>
                                <a
                                    href="https://www.facebook.com/weatherplus"
                                    className={classes.socialIcon}
                                >
                                    <FacebookIcon/>
                                </a>
                                <a
                                    href="mailto:kinhdoanh@weatherplus.vn"
                                    className={classes.socialIcon}
                                >
                                    <EnvelopeIcon/>
                                </a>
                                <div><a
                                    href={"http://online.gov.vn/Home/WebDetails/84850?AspxAutoDetectCookieSupport=1"}><img
                                    src={BCT} alt="BCT" style={{width: 160, marginTop: 20}}/></a></div>
                            </Typography>
                        </div>
                    </GridItem>
                </GridContainer>}

                <GridContainer className={classes.gridContainer}>
                    <GridItem xs={12} lg={2} style={{paddingLeft: "3%", marginRight: 0}}>
                        <Typography className={`${classes.text2} ${classes.gap24}`}
                                    style={isMobile ? {paddingLeft: "1.25rem", marginBottom: "0"} : {}}>
                            ©WeatherPlus 2024
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} lg={2} style={{marginLeft: "-2%"}}>
                        <Typography
                            component={"a"}
                            href={language === Languages.vi ? "/dieu-khoan-su-dung" : "/terms-of-use"}
                            className={`${classes.text2} ${classes.gap24}`}
                            style={{paddingLeft: isMobile ? "2rem" : 0}}
                        >
                            {t("Terms of Use")}
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} lg={2}
                              style={{
                                  margin: 0,
                                  marginLeft: isMobile ? "-2%" : (language === Languages.vi ? "-5%" : "-8%"),
                                  marginBottom: isMobile ? "2%" : 0,
                              }}>
                        <Typography
                            component={"a"}
                            href={language === Languages.vi ? "/chinh-sach-bao-mat" : "/privacy-policy"}
                            className={`${classes.text2} ${classes.gap24}`}
                            style={{paddingLeft: isMobile ? "2rem" : 0}}
                        >
                            {t("Privacy Policy")}
                        </Typography>
                    </GridItem>

                </GridContainer>
                <div className={classes.clearFix}/>
            </div>
        </footer>
    );
};

export default Footer;
