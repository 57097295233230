import happyrainConfig from "./pages/happyrain/happyrain.config";
import homeConfig from "./pages/home/home.config";
import mapConfig from "./pages/map/map.config";
import newsConfig from "./pages/news/news.config";
import productConfig from "./pages/product/product.config";
import solutionConfig from "./pages/solution/solution.config";
import weatherConfig from "./pages/weather/weather.config";
import newHomeConfig from "./pages/newhome/newHomeConfig";
import aboutConfig from "./pages/abouts/abouts.config";
import reportConfig from "./pages/report/report.config";
import recruitmentConfig from "./pages/abouts/recruitment.config";
import agriWeatherConfig from "./pages/agriWeather/agriWeather.config";
import otherConfig from "./pages/other/other.config";
import errorConfig2 from "./pages/solution.config";

const appConfigs = [
  otherConfig,
  recruitmentConfig,
  aboutConfig,
  happyrainConfig,
  homeConfig,
  mapConfig,
  reportConfig,
  productConfig,
  solutionConfig,
  weatherConfig,
  newHomeConfig,
  agriWeatherConfig,
  errorConfig2,
  newsConfig,
];

export default appConfigs;
