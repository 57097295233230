const locale = {
  "Latest news": "Latest news",
  "Most view news":"Most viewed",
  "Storm news":"Hurricane news",
  "See more": "See more",
  "See more news home":"See more",
  "Post date": "Post date",
  "Featured news":"Featured news",
  "mobile news1":"Weather news",
  "mobile news2":"Featured news",
  "Weather news": "Weather news",
  "Company news": "Company news",
  "Industry news": "Industry news",
  "WeatherPlus's life": "WeatherPlus's life",
  "Agriculture news": "Agriculture news",
  "Hydropower news": "Hydropower news",
  "Vietnam climate": "Vietnam's climate",
  "Weather for life": "Weather for life",
  Categories: "Categories",
  News: "News",
  Tags: "Tags",
  "Weather for businesses": "Weather for businesses",
  "Weather WP": "WeatherPlus News",
};

export default locale;
