import React from "react";
import authRoles from "app/auth/auth_roles";

const errorConfig2 = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/(khong-tim-thay|not-found)",
      exact: true,
      component: React.lazy(() => import("./error")),
    },
  ],
};

export default errorConfig2;
