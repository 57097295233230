import React from "react";
import authRoles from "app/auth/auth_roles";

const recruitmentConfig = {
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/(tuyen-dung|recruitment)",
            exact: true,
            component: React.lazy(() => import("./Recruitment")),
        },
    ],
};

export default recruitmentConfig;